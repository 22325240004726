<template>
  <div class="PublisherNameFolder">
    <!-- Space Name -->
    <publisher-logo
      v-if="!hideLogo && !hidePublisher"
      :publisher="destinationSpaceOnly ? post.folder.space : post.publisher"
      :size="size"
    />

    <div class="PublisherNameFolder--name-folder" :class="size">
      <template v-if="!hidePublisher">
        <!-- Space Name -->
        <publisher-name
          :publisher="destinationSpaceOnly ? post.folder.space : post.publisher"
          :size="size"
        />

        <!-- When a space (the publisher) post in another space -->
        <template
          v-if="
            !hideFolder &&
              !destinationSpaceOnly &&
              post.publisher.slug != $locationFolder.space.slug
          "
        >
          dans
          <publisher-name :publisher="$locationFolder.space" :size="size" />
        </template>
        <template v-if="!hideFolder">&gt;</template>
      </template>
      <!-- Folder -->
      <template v-if="!hideFolder">
        <a
          :href="
            `${$relattUrl}/${$locationFolder.space.slug}/${$locationFolder.slug}`
          "
          target="_blank"
          >{{ $locationFolder.name }}</a
        >
      </template>
    </div>
  </div>
</template>

<script>
import PublisherLogo from "@/components/Author/Emb_PublisherLogo"
import PublisherName from "@/components/Author/Emb_PublisherName"
/**
 * Generic component that render publisher based on post object
 */
export default {
  name: "PublisherNameFolder",
  components: {
    PublisherName,
    PublisherLogo
  },
  props: {
    /**
     * Get current post to display author
     */
    post: {
      type: Object,
      required: true
    },
    /**
     * Option to hide folder
     * */
    hideFolder: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Option to hide Logo
     * */
    hideLogo: {
      type: Boolean,
      default: true,
      required: false
    },
    /**
     * Option to hide Publisher
     * */
    hidePublisher: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Option to only display destination space and folder
     * */
    destinationSpaceOnly: {
      type: Boolean,
      default: false,
      required: false
    },

    /**
     * Name size variant
     * */
    size: {
      type: String,
      default: "default",
      required: false
    }
  },
  computed: {
    $locationFolder() {
      return this.destinationSpaceOnly
        ? this.post.folder
        : this.post.originalFolder
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/sass/_variables";
#app_relatt_embed {
  .PublisherNameFolder {
    display: inline;
    line-height: 17px;
    .PublisherNameFolder--name-folder {
      display: inline-flex;
      a {
        font-weight: normal;
        display: inline;
        color: #666;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &:hover {
          text-decoration: underline;
        }
      }

      .PublisherNameFolder--name-location {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.large {
        a {
          font-size: 20px;
        }
      }
      &.default {
        a {
          font-size: 16px;
        }
      }

      &.small,
      &.x-small {
        a {
          font-size: 14px;
          color: $gray-700;
          margin: 0 2px;
        }
      }
    }
  }

  /**
  * RESPONSIVE
  * ------------------------------------------
  */

  /**
  * Mobile
  * - - - - - - - - - - - - - - - - - - - - - -
  */
  .mobile {
    .PublisherNameFolder {
      .PublisherNameFolder--name-folder {
        &.default {
          a {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
