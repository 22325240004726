<template>
  <div class="Author">
    <a
      :href="`${$relattUrl}/person/${$author.slug}`"
      target="_blank"
      class="flex-row flex-center"
    >
      <responsive-img
        v-if="size !== 'sm'"
        :src="$author.image"
        :alt="$author.name"
        :entitySlugId="$author.slug"
        :responsive_class="'avatar-xs ' + $author.image_class"
      ></responsive-img>
      {{ $author.name }}
    </a>
  </div>
</template>

<script>
import ResponsiveImg from "@/components/Img/Emb_ResponsiveImg"
/**
 * Generic component that render author(s) from a post
 */
export default {
  name: "AvatarName",
  components: {
    ResponsiveImg
  },
  props: {
    /**
     * Get current post to display author
     */
    avatarNameList: {
      type: Array,
      required: true
    },
    size: { default: "md" }
  },
  computed: {
    /**
     * Get current post author
     * */
    $author: function() {
      /**
       * If as an author we return the first one (author can base space.
       * */
      if (this.avatarNameList.length) {
        return this.avatarNameList[0]
      }
      return false
    },

    linkData: function() {
      if (this.$author.route_var_name)
        return {
          name: this.$author.route_name,
          params: { [this.$author.route_var_name]: this.$author.slug }
        }
      else return "http://" + this.$author.slug
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/sass/_variables";
#app_relatt_embed {
  .Author {
    display: inline-block;
    align-items: center;
    margin-left: 7px;

    a {
      color: $gray-600;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
