<template>
  <div class="folder_selected_tags">
    <div>
      <div class="folder_selected_tags_list">
        <!-- Tag -->
        <div
          class="folder_selected_tags_item"
          v-for="tag in selectedTags"
          :key="tag.ttuid"
        >
          <!-- Name -->
          <span class="folder_selected_tags_item_name">
            {{ tag.name }}
          </span>

          <!-- X button -->
          <button
            class="folder_selected_tags_item_icon"
            @click="cancelFilteringTag(tag.filter_id, tag.ttuid)"
          >
            <i class="icon-x"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FolderSelectedTags",
  props: {
    // Tags selected with filters
    selectedTags: {
      type: Array,
      default: []
    },
    // Handle X to remove a filtering tag
    cancelFilteringTag: {
      type: Function
    }
  }
};
</script>
<style lang="scss">
#app_relatt_embed {
  .folder_selected_tags {
    .folder_selected_tags_list {
      display: flex;
      flex-wrap: wrap;

      .folder_selected_tags_item {
        padding: 8px 49px 8px 16px;
        border: 1px solid #e9ecef;
        border-radius: 16px;
        background: #f6fdfc;
        position: relative;
        margin-right: 8px;

        .folder_selected_tags_item_icon {
          position: absolute;
          right: 6px;
          color: #495057;
          border: none;
          background: transparent;
        }
      }
    }
  }
}
</style>
