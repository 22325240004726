function getMarkersFromPosts(
  posts,
  { returnType, map_marker_color_source, tagColorMap }
) {
  returnType = returnType ? returnType : "markers"
  let markers = []
  posts.forEach(item => {
    let post = item

    let customFieldContents = post.customFieldContents
    if (!customFieldContents.length && post.sourceOriginal) {
      customFieldContents = post.sourceOriginal.customFieldContents
    }

    if (customFieldContents.length) {
      let spatial_data = customFieldContents.find(
        item => item.type_hint === "spatial"
      )

      if (spatial_data) {
        let coordinates = [
          spatial_data.value_spatial.y,
          spatial_data.value_spatial.x
        ]
        if (returnType == "points") markers.push(coordinates)
        else if (returnType == "markers") {
          let pinColor = ""

          let publisher = post.sourceOriginal
            ? post.sourceOriginal.publisher
            : post.publisher

          if (map_marker_color_source == "FILTER") {
            /**
             * Based on number of tag filter, we provide pin color
             */
            let postTags = _postTags(item.tags)
            if (postTags.length == 1) pinColor = tagColorMap[postTags[0]]
            if (postTags.length > 1) pinColor = tagColorMap["dark_pin"] // We need to apply a black filter.
            if (postTags.length == 0) pinColor = tagColorMap["empty_pin"] // We need to apply a empty pin color.
          } else {
            pinColor = publisher.color
          }

          markers.push({
            coordinates,
            post,
            publisher,
            showTile: false,
            color: pinColor
          })
        }
      }
    }
  })

  return markers
}

const _postTags = tags => {
  return tags
    .map(tag => {
      if (tag.installedFilter) return tag.ttuid
    })
    .filter(item => item)
}

module.exports = getMarkersFromPosts
