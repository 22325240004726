<template>
  <!-- Space Name -->
  <a :href="`${$relattUrl}/${publisher.slug}/about`" target="_blank">
    {{ publisher.name }}
  </a>
</template>

<script>
/**
 * Generic component that render publisher based on post object
 */
export default {
  name: "PublisherName",
  props: {
    /**
     * Get current publisher to display
     */
    publisher: {
      type: Object,
      required: true
    },
    /**
     * Name size variant
     * */
    size: {
      type: String,
      default: "default",
      required: false
    }
  },
  computed: {}
}
</script>
<style lang="scss">
@import "@/assets/sass/_variables";
#app_relatt_embed {
  .PublisherName {
    font-weight: normal;
    color: #666;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
