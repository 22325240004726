<template>
  <generic-pagination
    @loadMore="$_loadMorePosts"
    :endReached="endReached"
    :is-loading="loading"
    :empty-list="!posts.length"
    :is-grid="isGrid"
    :all-contents="allContents"
    :class="$mq"
    :loadBtnText="isNetwork ? 'Charger plus' : 'Charger plus de contenu...'"
  >
    <!-- Post list -->
    <template v-if="posts && !isNetwork">
      <post-tile
        v-for="post in posts"
        :key="post.ttuid"
        :postTtuid="shouldSinglePostFetch ? post.ttuid : null"
        :post="shouldSinglePostFetch ? null : post"
        :currentFolderTtuid="currentFolderTtuid"
        :isFeaturedInCurrentFolder="
          currentFolderFeaturedPostsTtuid.includes(post.ttuid)
        "
        :homeTtuid="homeTtuid"
        :isFeaturedInHomeFolder="homeFeaturedPostsTtuid.includes(post.ttuid)"
        :size="isGrid ? 'small' : size"
        :isGrid="isGrid"
        :minimalSocialButtonVariant="isGrid"
        :showNotification="showNotification && !isGrid"
        :showIsAlsoRelatedBy="showIsAlsoRelatedBy"
        :variant="folderView == 'GRID' ? 'imageTop' : 'imageRight'"
        @refreshList="refreshPostList"
      ></post-tile>
    </template>

    <!-- Post list -->
    <template v-if="posts && isNetwork">
      <div
        :class="
          spaceComponent == 'CARD' || spaceComponent == 'LOGO_ONLY'
            ? `card-displayer ${$mq}`
            : ''
        "
      >
        <component
          :is="
            spaceComponent == 'CARD'
              ? $mq !== 'mobile'
                ? 'SpaceCard'
                : 'SpaceTile'
              : spaceComponent == 'LOGO_ONLY'
              ? 'SpaceLogoOnly'
              : 'SpaceTile'
          "
          v-for="post in posts"
          :key="
            post.sourceOriginal
              ? post.sourceOriginal.publisher.ttuid
              : post.publisher.ttuid
          "
          :moreSpaceFields="moreSpaceFields"
          :space="
            post.sourceOriginal
              ? post.sourceOriginal.publisher
              : post.publisher
          "
          :show-logo="true && spaceComponent != 'SAMPLE_TILE'"
        />
      </div>
    </template>
  </generic-pagination>
</template>

<script>
// ----------------------
// IMPORTS

import infiniteScroll from "vue-infinite-scroll"
import PostTile from "@/components/Post/Emb_PostTile"
import SpaceTile from "@/components/Space/SpaceTile"
import SpaceCard from "@/components/Space/SpaceCard"
import SpaceLogoOnly from "@/components/Space/SpaceLogoOnly"
import GenericPagination from "@/components/Other/Emb_GenericPagination"

// ----------------------

/**
 * Generic component that render a list post and support infinite scroll.
 */
export default {
  name: "PostList",
  /**
   * This is how v-infinite-scroll support is injected into the component.
   */
  directives: { infiniteScroll },
  components: {
    PostTile,
    SpaceTile,
    SpaceCard,
    SpaceLogoOnly,
    GenericPagination
  },
  props: {
    /**
     * List of posts to display
     */
    posts: {
      type: Array
    },
    /**
     * The Folder Ttuid where post are featured
     */
    currentFolderTtuid: {
      type: String,
      default: "",
      required: false
    },
    /**
     * The list of features posts in current Folder
     */
    currentFolderFeaturedPostsTtuid: {
      type: Array,
      default: () => [],
      required: false
    },
    /**
     * The home folder Ttuid
     * */
    homeTtuid: {
      type: String,
      default: "",
      required: false
    },
    /**
     * The list of features posts in current Folder
     */
    homeFeaturedPostsTtuid: {
      type: Array,
      default: () => [],
      required: false
    },
    /**
     * Should tile show notifications
     */
    showNotification: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Should tile show isAlsoRelatedBy
     */
    showIsAlsoRelatedBy: {
      type: Boolean,
      default: false,
      required: false
    },

    /**
     * Control if each post need to fetch its own data individually
     */
    shouldSinglePostFetch: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Display posts as grid
     */
    isGrid: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Tiles size
     */
    size: {
      type: String,
      default: "small",
      required: false
    },
    /**
     * Given that data loading logic and pagination stuff are handled in parent scale,
     * this props indicates that infinite scroll should be disabled or not.
     */
    endReached: {},
    /**
     * Whether list is loaded or not.
     */
    loading: {
      type: Number,
      default: 0
    },
    isNetwork: {
      type: Boolean,
      default: false
    },
    moreSpaceFields: {
      type: Array
    },
    spaceComponent: {
      type: String,
      default: "CARD"
    },
    folderView: {
      type: String,
      default: "LIST"
    },
    allContents: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    /**
     * Infinite scroll endReach call back, that emits onEndReach to the parent component.
     */
    $_loadMorePosts() {
      this.$emit("onEndReach")
    },
    /**
     * Refresh parent query
     */
    refreshPostList() {
      this.$emit("postListNeedToRefresh")
    }
  }
}
</script>
<style lang="scss"></style>
