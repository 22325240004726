<template>
  <div class="lds-css ng-scope">
    <div class="lds-eclipse">
      <div></div>
    </div>
    <span>{{ userMessage }}</span>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    userMessage: {
      type: String,
      default: ""
    }
  }
}
</script>

<style scoped lang="scss">
#app_relatt_embed {
  @keyframes lds-eclipse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes lds-eclipse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .lds-css {
    width: 100%;
    align-items: center;
    text-align: center;
    span {
      display: block;
      font-size: 15px;
      padding-top:20px;
    }
  }
  .lds-eclipse {
    position: relative;
    margin: 0 auto;
  }
  .lds-eclipse div {
    position: absolute;
    -webkit-animation: lds-eclipse 1.5s linear infinite;
    animation: lds-eclipse 1.5s linear infinite;
    width: 80px;
    height: 80px;
    top: 20px;
    left: 20px;
    border-radius: 50%;
    box-shadow: 0 6px 0 0 #50e3c2;
    -webkit-transform-origin: 40px 42px;
    transform-origin: 40px 42px;
  }
  .lds-eclipse {
    width: 100px !important;
    height: 100px !important;
    -webkit-transform: translate(-50px, -50px) scale(1) translate(50px, 50px);
    transform: translate(-50px, -50px) scale(1) translate(50px, 50px);
  }
}
</style>
