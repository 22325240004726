<template>
  <div class="TagList__tags" v-if="tags.length">
    <i class="icon-tag"></i>
    <!-- Optional title -->
    <template v-if="listTitle">
      <b>{{ listTitle }}</b
      >: &nbsp;
    </template>
    <!-- Display tags -->
    <div>
      <span v-for="(tag, index) in tags" :key="index">{{ tag.name }}</span>
    </div>
  </div>
</template>

<script>
/**
 * Generic component that render a list of tags
 */
export default {
  name: "TagList",
  props: {
    /**
     * List of tags to display
     */
    tags: {
      type: Array,
      required: true
    },
    /**
     * Optional title
     */
    listTitle: {
      type: String,
      default: "",
      required: false
    }
  }
};
</script>
<style lang="scss">
#app_relatt_embed {
  .TagList__tags {
    display: inline-flex;
    color: #d5d5d5;
    font-size: 12px;
    padding-left: 10px;
    b {
      font-weight: 500;
      color: #bbb;
      white-space: nowrap;
    }
    > i {
      color: #d5d5d5;
      margin-right: 3px;
      font-size: 14px;
      line-height:29px;
    }
    span {
      //background-color: #f8f8f8;
      border: 1px solid #f4f4f4;
      padding: 5px 10px;
      border-radius: 12px;
      color: #bbb;
      float: left;
      line-height: 1;
      margin: 2px 2px;
    }
  }
}
</style>
