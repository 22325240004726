<template>
  <div class="PostTileRelatedSpace">
    <slot name="contentTypeLabel-fluxMode" />

    <div class="PostTileRelatedSpace--jumbotron">
      <a
        v-if="spaceData.cover"
        target="_blank"
        :href="`${$relattUrl}/${spaceData.slug}`"
        class="PostTile--leftImage"
      >
        <responsive-img
          :src="spaceData.cover"
          :alt="spaceData.name"
          :entitySlugId="spaceData.ttuid"
          size="cover"
        />
      </a>
      <div
        class="PostTile--content__cover"
        :style="'background-color:' + spaceData.color + 'cc'"
      >
        <div>
          <div class="PostTile--content__header">
            <card-identity-space
              :space="spaceData"
              :show-slug="false"
              :show-about="true"
              :card-size="size === 'default' ? 'md' : 'sm'"
            />
          </div>
          <a
            :href="`${$relattUrl}/${spaceData.slug}`"
            target="_blank"
            class="PostTile--leftImage"
          >
            <div class="PostTile--content__texts__title">
              <div>
                <h5>
                  {{ spaceData.short_description }}
                </h5>
                {{ spaceStats.postsCount }} contenu{{
                  spaceStats.postsPlural
                }}
                référencé{{ spaceStats.postsPlural }} -
                {{ spaceStats.followersCount }} personne{{
                  spaceStats.followersPlural
                }}
                abonnée{{ spaceStats.followersPlural }}
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="PostTile--content__texts">
      <div>
        <p v-if="post.excerpt">{{ post.excerpt }}</p>

        <slot />
        <slot name="contentTypeLabel-gridMode" />
        <slot name="signature-gridMode" />
        <slot name="signature-fluxMode" />
        <slot name="footer-gridMode" />
      </div>
    </div>
    <slot name="footer-fluxMode" />
    <br />
    <a
      class="PostTile--relatt-content-link"
      :href="`${$relattUrl}/${spaceData.slug}`"
      target="_blank"
      ><span style="margin-left: -20px">Voir le contenu sur Relatt</span></a
    >
  </div>
</template>

<script>
/**
 * Represents single item post list.
 */
import GET_SPACE_STATISTICS from "@/graphql/Space/Emb_getSpaceStatistics.graphql"
import ResponsiveImg from "@/components/Img/Emb_ResponsiveImg"
import CardIdentitySpace from "@/components/Space/Emb_CardIdentitySpace"

export default {
  name: "PostTilePublication",
  components: {
    ResponsiveImg,
    CardIdentitySpace
  },
  props: {
    /**
     * Current post to render.
     */
    post: {},
    size: {},
    /**
     * Display space preview instead of default content type tile
     */
    displaySpaceInsteadOfContent: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      /**
       * Loading statistics
       */
      space_stats_data_loading: 0,
      spaceStats: {}
    }
  },
  computed: {
    /**
     * Get space data depend on option
     * */
    spaceData() {
      return this.displaySpaceInsteadOfContent
        ? this.post.publisher
        : this.post.sourceSpace
    }
  },
  apollo: {
    /**
     * Get posts counts & users counts
     */
    Space: {
      query: GET_SPACE_STATISTICS,
      loadingKey: "space_stats_data_loading",
      variables() {
        return {
          spaceSlug: this.spaceData.slug
        }
      },
      update(data) {
        this.spaceStats = data.getSpacePreview
        this.spaceStats.postsPlural = this.spaceStats.postsCount > 1 ? "s" : ""
        this.spaceStats.followersPlural =
          this.spaceStats.followersCount > 1 ? "s" : ""
      },
      skip() {
        return !this.post
      },
      error: error =>
        console.log(" ---->  PostTile > GET_SPACE_STATISTICS > ", error)
    }
  }
}
</script>

<style lang="scss">
#app_relatt_embed {
  .PostTileRelatedSpace {
    //height: 320px;

    .PostTile--content__texts {
      padding-bottom: 10px;
    }
    .PostTileRelatedSpace--jumbotron {
      margin: 0 15px 10px 0;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      flex-direction: row;

      .PostTile--leftImage {
        flex-grow: 0;
        margin-right: 3px;
        .responsive_image_container {
          width: 150px;
          min-height: 200px;
          height: 100%;
        }
      }

      .PostTile--content__cover {
        padding: 15px 5px 10px 15px;
        flex-grow: 1;
        // > div {
        //   margin-bottom: 10px;
        // }

        .PostTile--content__header {
          display: flex;
          margin: 0px 0 0 0;
          align-items: flex-start;
          align-content: space-between;

          .card-identity {
            flex: 1;
            > .responsive_image_container {
              background-color: #fff !important;
            }
            h4 {
              font-size: 20px;
              margin: 0 0 2px 0;
              color: #fff;
              text-overflow: ellipsis;
              display: -webkit-box;
              overflow: hidden;
              -webkit-line-clamp: 2; /* after 3 line show ... */
              -webkit-box-orient: vertical;
            }
            a {
              color: #fff;

              &:hover {
                text-decoration: underline;
              }
            }
            p {
              color: #fff;
            }
          }
        }
        .PostTile--content__texts__title {
          display: flex;
          padding: 0 0 5px 0;
          margin: 0;
          flex: 1;
          align-items: center;
          > div {
            h5 {
              color: #fff;
              font-size: 13px;
              line-height: 1;
              font-weight: 400;
              margin: 8px 0;
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 3; /* after 3 line show ... */
              -webkit-box-orient: vertical;
            }
            color: #fff;
            align-items: center;
            font-size: 13px;
            font-weight: 400;
          }
        }
      }
      button {
        margin: 5px 0 0 0;
      }
    }

    .PostTile--relatt-content-link {
      color: #9b9b9b;
      text-decoration: none;
      font-size: 14px;
      padding-left: 19px;
    }
  }
  .small {
    .PostTileRelatedSpace {
      .PostTileRelatedSpace--jumbotron {
        margin-right: 0;
        .PostTile--leftImage {
          .responsive_image_container {
          }
        }

        .PostTile--content__cover {
          padding: 15px 5px 0px 15px;
          .PostTile--content__header {
            display: flex;
            flex-direction: column;
            .card-identity {
              > .responsive_image_container {
              }
              h4 {
                font-size: 16px;
                line-height: 1;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* after 3 line show ... */
              }
              p {
              }
            }
          }
          .PostTile--content__texts__title {
            > div {
              h5 {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
  }

  .medium,
  .small {
    &.imageTop,
    &.noImage {
      /* Case of Space Related display in grid */
      .PostTileRelatedSpace {
        .PostTileRelatedSpace--jumbotron {
          margin-right: 0;
          .PostTile--content__cover {
            width: 60%;

            .PostTile--content__header {
              overflow: hidden;
              display: block;
              .card-identity {
                align-items: normal;
                width: 100%;
                flex: unset;
                h4 {
                  text-overflow: ellipsis;
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 1;
                }
              }

              button {
                margin-top: 5px;
                //display: none !important;
              }
            }
          }
        }
      }
    }
  }

  .grid-2 {
    .medium,
    .small {
      &.imageTop,
      &.noImage {
        /* Case of Space Related display in grid */
        .PostTileRelatedSpace {
          .PostTileRelatedSpace--jumbotron {
            .PostTile--leftImage {
              .responsive_image_container {
                width: 210px;
                min-height: 270px;
              }
            }
            .PostTile--content__cover {
              padding: 20px 5px 5px 15px;
              .PostTile--content__header {
                .card-identity {
                  h4 {
                    font-size: 22px;
                  }
                }
              }
              .PostTile--content__texts__title {
                > div {
                  h5 {
                    font-size: 18px;
                    -webkit-line-clamp: 5; /* after 3 line show ... */
                    line-height: 1.2;
                    margin-bottom: 10px;
                  }
                }
              }
            }
            button {
              margin: 10px 0 0 0;
            }
          }
        }
      }
    }
  }

  /**
  * Mobile
  * - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .mobile {
    .PostTileRelatedSpace {
      width: 100%;
      .PostTileRelatedSpace--jumbotron {
        .PostTile--content__cover {
          width: 60%;
          padding: 10px 10px 0 10px;

          .PostTile--content__header {
            flex-direction: column;
            .card-identity {
              overflow: hidden;
              margin: 0 0 10px 0;
              align-items: normal;

              .detail {
                padding: 0;
                margin-left: 10px;
              }
            }
          }
          .PostTile--content__texts__title {
            > div {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
