var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("featured-tag"),_c('div',{staticClass:"PostTile--displaying-mode"},[_c('div',{staticClass:"PostTile--content__texts"},[_c('div',[_vm._t("contentTypeLabel-fluxMode"),(!_vm.post.preview || _vm.isGrid || _vm.post.preview.hasAugmentedTitle)?_c('a',{staticClass:"PostTile--content__texts__title",attrs:{"href":_vm.post.installedContentType.content_type_key == 'LINK' || _vm.post.isLink
              ? _vm.post.preview.link
              : (_vm.$relattUrl + "/" + (_vm.post.folder.space.slug) + "/" + (_vm.post.folder.slug) + "/" + _vm.postLink),"target":"_blank"}},[_vm._v(_vm._s(_vm.post.title))]):_vm._e(),(_vm.displayLinkPreview)?_c('link-preview',{class:{ has_augmented_version: _vm.post.preview.hasAugmentedTitle },attrs:{"publisherTtuid":_vm.post.preview.image_cached_ext
              ? 'links'
              : _vm.post.originalFolder.space.ttuid,"title":_vm.post.preview.title,"image":_vm.post.preview.image,"description":_vm.post.preview.description,"icon":_vm.post.preview.icon,"display_link":_vm.post.preview.display_link,"link":_vm.post.preview.link,"preview":_vm.post.preview}}):_vm._e(),(!_vm.isGrid)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.post.excerpt)}}):_vm._e(),(_vm.displayAttachementList)?_c('post-attachment-list',{attrs:{"current-space":_vm.post.publisher,"attachments":_vm.post.attachments,"condensed":true,"hidden-list":false}}):_vm._e(),_vm._t("customFields"),_vm._t("default"),_vm._t("contentTypeLabel-gridMode"),(_vm.displayAttachementListInGrid)?_c('post-attachment-list',{attrs:{"current-space":_vm.post.publisher,"attachments":_vm.post.attachments,"condensed":true,"hidden-list":false,"isGrid":_vm.isGrid}}):_vm._e(),_vm._t("signature-gridMode"),_vm._t("signature-fluxMode"),_c('a',{staticClass:"PostTile--relatt-content-link",attrs:{"target":"_blank","href":_vm.post.installedContentType.content_type_key == 'LINK'
              ? (_vm.$relattUrl + "/" + (_vm.post.folder.space.slug) + "/post/" + _vm.postLink)
              : (_vm.$relattUrl + "/" + (_vm.post.folder.space.slug) + "/" + (_vm.post.folder.slug) + "/" + _vm.postLink)}},[_c('span',[_vm._v("Voir le contenu sur Relatt")])]),_vm._t("footer-gridMode")],2)]),(
        (_vm.post.attachmentsCount == 1 || _vm.post.isLink || _vm.post.image) &&
          ((_vm.isGrid && (_vm.size !== 'large' || _vm.post.preview)) ||
            (_vm.post.image && !_vm.post.preview))
      )?_c('a',{staticClass:"PostTile--content__image",attrs:{"target":"_blank","href":_vm.post.installedContentType.content_type_key == 'LINK' || _vm.post.isLink
          ? _vm.post.preview
            ? _vm.post.preview.link
            : (_vm.$relattUrl + "/" + (_vm.post.folder.space.slug) + "/post/" + _vm.postLink)
          : (_vm.$relattUrl + "/" + (_vm.post.folder.space.slug) + "/" + (_vm.post.folder.slug) + "/" + _vm.postLink)}},[(
          (_vm.post.image && !_vm.post.preview) ||
            (_vm.isGrid && _vm.post.preview && _vm.post.preview.image)
        )?_c('responsive-img',{attrs:{"src":_vm.post.image ? _vm.post.image : _vm.post.preview ? _vm.post.preview.image : '',"alt":_vm.post.title,"size":"L","entitySlugId":_vm.post.installedContentType.content_type_key == 'LINK' ||
          (_vm.post.preview && _vm.post.preview.image_cached_ext)
            ? 'links'
            : _vm.post.originalFolder.space.ttuid}}):(_vm.isGrid && _vm.size !== 'large')?_c('div',{staticClass:"contentTypeInsteadOfImage",class:_vm.size},[_c('content-type-icon-label',{attrs:{"contentType":_vm.post.installedContentType}})],1):_vm._e()],1):_vm._e()]),_vm._t("footer-fluxMode")],2)}
var staticRenderFns = []

export { render, staticRenderFns }