<template>
  <div class="Space-Card">
    <a :href="`${$relattUrl}/${space.slug}/about`" target="_blank">
      <div class="Space-Card-Cover" :style="coverContainerStyle"></div>
      <div>
        <responsive-img
          class="Space-Card-Logo"
          :src="space.logo"
          :alt="space.name"
          :entitySlugId="space.ttuid"
        ></responsive-img>
      </div>
      <div class="Space-Card-Detail">
        <p class="Space-Card-Title">{{ space.name }}</p>
        <p class="Space-Card-Description" :style="spaceDescriptionStyle">
          {{ space.short_description }}
        </p>
      </div>
    </a>
  </div>
</template>

<script>
import ResponsiveImg from "@/components/Img/Emb_ResponsiveImg"
export default {
  name: "SpaceCard",
  components: {
    ResponsiveImg
  },
  props: {
    /**
     * Current space
     */
    space: {
      type: Object,
      required: false
    },
    /**
     * Style of space title
     */
    spaceTitleStyle: {
      type: Object,
      default: () => ({}),
      required: false
    },
    /**
     * Style of space description
     */
    spaceDescriptionStyle: {
      type: Object,
      required: false,
      default: () => ({
        color: "#adb5bd"
      })
    }
  },
  computed: {
    // Define background style for the tile
    coverContainerStyle() {
      let style = {}

      let src
      if (this.space.cover) {
        let imageName = this.space.cover.slice(
          0,
          this.space.cover.lastIndexOf(".")
        )
        src = `${process.env.VUE_APP_ASSETS_URL}/images/${this.space.ttuid}/${imageName}_cover.webp`
        style.backgroundImage = "url(" + src + ")"
      } else {
        style.backgroundColor = this.space.color
          ? this.space.color
          : "rgb(80, 227, 194, 0.5)"
      }

      return style
    }
  }
}
</script>

<style lang="scss">
#app_relatt_embed {
  .Space-Card {
    width: 225px;
    height: 435px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 3px gray;
    border: 1px solid white;

    .Space-Card-Cover {
      width: 224px;
      height: 300px;
      border-radius: 10px 10px 0 0;
      position: absolute;
      background-size: cover;
      background-color: rgba(0, 0, 0, 0);
    }
    .Space-Card-Logo {
      width: 75px;
      height: 75px;
      position: absolute;
      margin: 240px 75px -20px;
      border: 1px solid white;
      background-color: white;
    }

    .Space-Card-Detail {
      width: 221.8px;
      padding-top: 300px;

      .Space-Card-Title {
        text-align: center;
        margin-top: 23px;
        margin-bottom: 6px;
        font-size: 20px;
        line-height: 1;
      }
      .Space-Card-Description {
        text-align: center;
        margin-bottom: 0;
        padding: 0 10px;
        height: 0px;
        overflow: hidden;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
</style>
