/* *
 * Process exising flattened tags and return them sorted by `free tags` or `filters tags`
 */
export default function unFlatPostTags(tags, filtersWithDefaultValue = null) {
  let filters_tags = {}
  let free_tags = []
  if (filtersWithDefaultValue)
    if (filtersWithDefaultValue)
      /* *
       * Set default value for filter when needed
       */
      filtersWithDefaultValue.forEach((filter) => {
        filters_tags[filter.InstalledFilter.ttuid] = {
          tags: [],
          filter,
        }
      })

  /* *
   * Process exising tags
   */
  if (tags)
    tags.forEach((tag) => {
      if (!tag.installedFilter) {
        free_tags.push({ name: tag.name, ttuid: tag.ttuid })
      } else {
        if (!filters_tags[tag.installedFilter.ttuid]) {
          filters_tags[tag.installedFilter.ttuid] = {
            tags: [],
            filter: tag.installedFilter,
          }
        }

        /* *
         * Format filters to be reconized by filters select component
         */
        let { color, name, order, ttuid, __typename } = tag

        filters_tags[tag.installedFilter.ttuid].tags.push({
          color,
          name,
          order,
          ttuid,
          __typename,
        })
      }
    })

  return {
    free_tags,
    filters_tags,
  }
}
