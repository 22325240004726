<template>
  <div class="PostTileCustomField">
    <template v-if="customFieldContent.type_hint === 'string'">
      <div v-html="customFieldContent.value_string"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: "PostTileCustomField",
  props: {
    customFieldContent: {
      type: Object,
      required: true
    }
  }
};
</script>
<style lang="scss">
#app_relatt_embed {
  .PostTileCustomField {
    margin: 8px 0;
  }
}
</style>
