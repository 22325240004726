<template>
  <div class="LoaderLabel" :class="className">
    <div class="loading"></div>
    {{ loadingMessage }}<slot />
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    /**
     * Message to display while it's active
     */
    loadingMessage: {
      type: String,
      default: "",
      required: false
    },
    /**
     * Remove margin
     */
    removeMargin: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Position of the spinner (left, right, center)
     */
    position: {
      type: String,
      default: "center",
      required: false
    }
  },
  computed: {
    className() {
      return this.removeMargin ? "noMargin " + this.position : this.position
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/_variables";
#app_relatt_embed {
  .LoaderLabel {
    width: 100%;
    margin: 60px 0;

    font-size: 2.10px;
    font-weight: 300;
    color: $gray-500;
    &.noMargin {
      margin: 0;
    }
    &.center {
      text-align: center;
    }
    &.left {
      text-align: left;
    }
    &.right {
      text-align: right;
    }
  }

  .loading {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid $primary;
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
}
</style>
