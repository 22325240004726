<template>
  <div>
    <slot name="featured-tag"></slot>
    <div class="PostTile--displaying-mode">
      <div class="PostTile--content__texts">
        <div>
          <slot name="contentTypeLabel-fluxMode"></slot>

          <a
            :href="
              post.installedContentType.content_type_key == 'LINK' || post.isLink
                ? post.preview.link
                : `${$relattUrl}/${post.folder.space.slug}/${post.folder.slug}/${postLink}`
            "
            target="_blank"
            class="PostTile--content__texts__title"
            v-if="!post.preview || isGrid || post.preview.hasAugmentedTitle"
            >{{ post.title }}</a
          >

          <link-preview
            v-if="displayLinkPreview"
            :publisherTtuid="
              post.preview.image_cached_ext
                ? 'links'
                : post.originalFolder.space.ttuid
            "
            :title="post.preview.title"
            :image="post.preview.image"
            :description="post.preview.description"
            :icon="post.preview.icon"
            :display_link="post.preview.display_link"
            :link="post.preview.link"
            :class="{ has_augmented_version: post.preview.hasAugmentedTitle }"
            :preview="post.preview"
          />

          <p v-html="post.excerpt" v-if="!isGrid"></p>

          <post-attachment-list
            v-if="displayAttachementList"
            :current-space="post.publisher"
            :attachments="post.attachments"
            :condensed="true"
            :hidden-list="false"
          />

          <slot name="customFields"></slot>

          <slot />

          <slot name="contentTypeLabel-gridMode"></slot>

          <post-attachment-list
            v-if="displayAttachementListInGrid"
            :current-space="post.publisher"
            :attachments="post.attachments"
            :condensed="true"
            :hidden-list="false"
            :isGrid="isGrid"
          />

          <slot name="signature-gridMode"></slot>
          <slot name="signature-fluxMode"></slot>
          <a
            class="PostTile--relatt-content-link"
            target="_blank"
            :href="
              post.installedContentType.content_type_key == 'LINK'
                ? `${$relattUrl}/${post.folder.space.slug}/post/${postLink}`
                : `${$relattUrl}/${post.folder.space.slug}/${post.folder.slug}/${postLink}`
            "
            ><span>Voir le contenu sur Relatt</span></a
          >
          <slot name="footer-gridMode" />
        </div>
      </div>

      <a
        target="_blank"
        :href="
          post.installedContentType.content_type_key == 'LINK' || post.isLink
            ? post.preview
              ? post.preview.link
              : `${$relattUrl}/${post.folder.space.slug}/post/${postLink}`
            : `${$relattUrl}/${post.folder.space.slug}/${post.folder.slug}/${postLink}`
        "
        class="PostTile--content__image"
        v-if="
          (post.attachmentsCount == 1 || post.isLink || post.image) &&
            ((isGrid && (size !== 'large' || post.preview)) ||
              (post.image && !post.preview))
        "
      >
        <responsive-img
          :src="
            post.image ? post.image : post.preview ? post.preview.image : ''
          "
          :alt="post.title"
          size="L"
          :entitySlugId="
            post.installedContentType.content_type_key == 'LINK' ||
            (post.preview && post.preview.image_cached_ext)
              ? 'links'
              : post.originalFolder.space.ttuid
          "
          v-if="
            (post.image && !post.preview) ||
              (isGrid && post.preview && post.preview.image)
          "
        ></responsive-img>
        <div
          v-else-if="isGrid && size !== 'large'"
          class="contentTypeInsteadOfImage"
          :class="size"
        >
          <content-type-icon-label :contentType="post.installedContentType">
          </content-type-icon-label>
        </div>
      </a>
    </div>
    <slot name="footer-fluxMode" />
  </div>
</template>

<script>
/**
 * Represents single item post list.
 */
import { textTruncate } from "@/helpers/app"
import ResponsiveImg from "@/components/Img/Emb_ResponsiveImg"
import LinkPreview from "@/components/Other/Emb_LinkPreview"
import ContentTypeIconLabel from "@/components/Other/Emb_ContentTypeIconLabel"
import PostAttachmentList from "@/components/Post/Emb_PostAttachmentList"

export default {
  name: "PostTileDefault",
  components: {
    ResponsiveImg,
    LinkPreview,
    ContentTypeIconLabel,
    PostAttachmentList
  },
  props: {
    /**
     * Current post to render.
     */
    post: {},
    /**
     * Is the layout in grid mode
     * */
    isGrid: { default: false },
    /**
     * Tile size (small, medium or large)
     * */
    size: {
      type: String,
      default: "medium",
      required: false
    }
  },
  computed: {
    displayLinkPreview() {
      return (
        this.post.preview &&
        (this.isLargeMode || this.post.isLink) &&
        (this.post.attachmentsCount == 1 || this.post.isLink) &&
        !this.isGrid
      )
    },
    displayAttachementList() {
      return (
        this.isLargeMode &&
        (this.post.attachmentsCount > 1 ||
          (this.post.attachmentsCount >= 1 && this.post.isLink))
      )
    },
    displayAttachementListInGrid() {
      return (
        this.isGridMode &&
        !this.isGridCompressed &&
        this.post.attachmentsCount > 0
      )
    },
    isGridMode() {
      return this.isGrid && this.size == "medium"
    },
    isLargeMode() {
      return !this.isGridMode || this.size === "large"
    },
    postLink() {
      return this.post.doorPostSlug ? this.post.doorPostSlug : this.post.slug
    }
  },

  methods: {
    /**
     * Wrapper function for textTruncate from helpers.js
     * */
    text_truncate: function(str, end, ending, strip_tags = false) {
      return textTruncate(str, end, ending, strip_tags)
    }
  }
}
</script>

<style lang="scss">
#app_relatt_embed {
  .PostTile--displaying-mode {
    .PostTile--content__image {
    }
  }
  .show-only-true-image {
    .PostTile--content__image {
      width: auto !important;
      border: 0;
      .ContentTypeIconLabel {
        display: none !important;
      }
    }
  }
  .PostTile--relatt-content-link {
    color: #9b9b9b;
    text-decoration: none;
    font-size: 14px;
  }
}
</style>
