<template>
  <div class="PostTileRelatedFolder">
    <slot name="contentTypeLabel-fluxMode" />

    <div class="PostTileRelatedSpace--jumbotron">
      <div class="PostTile--content__texts">
        <a
          :href="
            `${$relattUrl}/${post.sourceFolder.space.slug}/${post.sourceFolder.slug}`
          "
          target="_blank"
          class="PostTile--content__texts__title"
          :style="'background-color:' + post.sourceFolder.space.color"
        >
          <responsive-img
            :src="post.sourceFolder.space.logo"
            class="PostTile--content__texts__title__logo"
            :alt="post.sourceFolder.space.name"
            :entitySlugId="post.sourceFolder.space.ttuid"
          />
          <div>
            <h5>
              {{ post.sourceFolder.space.name }} >&nbsp;
              <span>
                {{ post.sourceFolder.name }}
              </span>
            </h5>
            {{ folderStats.postsCount }} contenu{{
              folderStats.postsPlural
            }}
            référencé{{ folderStats.postsPlural }}
          </div>
        </a>
        <p>{{ post.excerpt }}</p>
        <slot />
        <slot name="contentTypeLabel-gridMode" />
        <slot name="signature-gridMode" />
        <slot name="signature-fluxMode" />
        <slot name="footer-gridMode" />
      </div>
    </div>
    <slot name="footer-fluxMode" />
  </div>
</template>

<script>
/**
 * Represents single item post list.
 */
import GET_FOLDER_POSTS_COUNT from "@/graphql/Folder/Emb_getFolderPostCount.graphql"
import ResponsiveImg from "@/components/Img/Emb_ResponsiveImg"

export default {
  name: "PostTileRelatedFolder",
  components: {
    ResponsiveImg
  },
  props: {
    /**
     * Current post to render.
     */
    post: {}
  },
  data() {
    return {
      /**
       * Loading statistics
       */
      folder_stats_data_loading: 0,
      folderStats: {}
    }
  },
  apollo: {
    /**
     * Get posts counts & users counts
     */
    Space: {
      query: GET_FOLDER_POSTS_COUNT,
      loadingKey: "folder_stats_data_loading",
      variables() {
        return {
          spaceSlug: this.post.sourceFolder.space.slug,
          folderSlug: this.post.sourceFolder.slug
        }
      },
      update(data) {
        if (data.getFolderPostCount) {
          this.folderStats.postsCount = data.getFolderPostCount
        }
        this.folderStats.postsPlural =
          this.folderStats.postsCount > 1 ? "s" : ""
      },
      skip() {
        return !this.post
      },
      error: error =>
        console.log(" ---->  PostTile > GET_FOLDER_POSTS_COUNT > ", error)
    }
  }
}
</script>

<style scoped lang="scss">
.PostTileRelatedFolder {
  .PostTileRelatedSpace--jumbotron {
    margin-right: 15px;
    .PostTile--content__texts {
      display: block;
      .PostTile--content__texts__title {
        display: flex;
        padding: 2rem;
        border-radius: 1rem;
        //align-items: center;
        text-align: left;
        h5 {
          padding: 0;
          margin: 0.3rem 0 0.3rem 0;
          text-align: left;
        }
        &:hover {
          span {
            text-decoration: underline;
          }
        }
        .PostTile--content__texts__title__logo {
          width: 6rem;
          height: 6rem;
          margin-right: 1.6rem;
          flex-shrink: 0;
        }
        > div {
          h5 {
            color: #fff;
            font-size: 2.4rem;
            font-weight: 400;
          }
          color: #fff;
          align-items: center;
          font-size: 1.7rem;
          font-weight: 400;
        }
      }
    }
  }
}

/**
 * RESPONSIVE
 * --------------------------------------------
 */

/**
 * Mobile
 * - - - - - - - - - - - - - - - - - - - - - - -
 */
.mobile {
  .PostTileRelatedFolder {
    .PostTileRelatedSpace--jumbotron {
      .PostTile--content__texts {
        .PostTile--content__texts__title {
          padding: 1rem;
          margin: 0;
          .PostTile--content__texts__title__logo {
            width: 4rem;
            height: 4rem;
            margin-right: 1rem;
          }

          div {
            font-size: 1.2rem;

            h5 {
              font-size: 1.5rem;
              margin: 0 0 0.5rem 0;
            }
          }
        }
      }
    }
  }
}
</style>
