<template>
  <div
    class="MediaDisplay"
    :class="[
      isVideo ? 'videoLoader' : '',
      isVideo && imageClass ? imageClass : '',
    ]"
  >
    <template v-if="!postContentTypeImage && (image || isVideo)">
      <!-- Right preview video -->
      <template v-if="isVideo">
        <lazy-component
          v-if="!displayVideo"
          @show="() => (this.displayVideo = true)"
        />
        <iframe
          v-if="displayVideo"
          :width="imageFormat === 'x-small' ? 50 : 400"
          :height="imageFormat === 'x-small' ? 50 : 200"
          title=""
          :src="embedUrl"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </template>
      <!-- Right preview image -->

      <responsive-img
        v-else-if="image"
        :src="image"
        :size="imageFormat"
        :variant="variant"
        :entitySlugId="loadFromTemp ? 'linkDataTmp' : entitySlugId"
        :contain="image_fit_contain"
        :responsive_class="imageClass ? imageClass : 'LinkPreview__image'"
        :style="bgStyle"
        :visibility="visibility"
        :background="image_background"
        :displayImg="true"
        :on-click="showImgBox"
      />
      <div v-if="hasAvailableImages">
        <button
          type="button"
          class="media_display_slide"
          @click.prevent="nextAvailableImage"
        >
          <i class="icon-arrow-right" />
        </button>
      </div>
    </template>

    <div
      v-else
      class="contentTypeInsteadOfImage"
      :class="[
        size === 'x-small' ? 'x-small' : 'small',
        imageClass ? imageClass : 'LinkPreview__image',
      ]"
    >
      <content-type-icon-label
        :content-type="installedContentType"
        :icon-exception="iconException"
        :size="size"
      />
    </div>
  </div>
</template>

<script>
import { getPath } from "@/helpers/app"
import ContentTypeIconLabel from "@/components/Other/Emb_ContentTypeIconLabel"
import ResponsiveImg from "@/components/Img/Emb_ResponsiveImg"

export default {
  name: "GenericMediaDisplay",
  components: {
    ResponsiveImg,
    ContentTypeIconLabel,
  },
  props: {
    /**
     * Folder where image is stored
     */
    entitySlugId: {
      type: String,
      required: false,
      default: "",
    },
    /**
     * The visibility of current space
     */
    visibility: {
      type: String,
    },

    /**
     * The name of image
     */
    image: {
      type: String,
    },
    image_fit_contain: {
      type: String,
    },
    /**
     * The icon
     */
    icon: {
      type: String,
    },

    /**
     * The link of image or video
     */
    link: {
      type: String,
    },
    /**
     * test if is file
     */
    isFile: {
      type: Boolean,
      default: false,
    },
    /**
     * Installed content type
     */
    installedContentType: {
      type: Object,
    },
    /**
     *  Tile size (small, medium or large)
     */
    size: {
      type: String,
      default: "default",
    },
    /**
     * Tile variant (imageRight, imageLeft or imageTop)
     * */
    variant: {
      type: String,
      default: "imageRight",
      required: false,
    },

    /**
     * must the component load image form cache
     */
    loadFromTemp: {
      type: Boolean,
      default: false,
    },
    /**
     * css class of img
     */
    imageClass: {
      type: String,
      default: "",
    },
    hasAvailableImages: {
      type: Boolean,
      default: false,
    },
    /**
     * background image coolor
     */
    image_background: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      /**
       * the link of video
       */
      embedUrl: "",
      displayVideo: false,
      postContentTypeImage: false,
      images: [],
      index: null,
    }
  },
  computed: {
    bgStyle() {
      if (this.color) {
        return { backgroundColor: this.color }
      }
    },
    iconException() {
      /* For files with a specified file type*/
      if (this.icon && (this.isFile || !this.installedContentType.icon))
        return this.icon
      else return null
    },
    imageFormat() {
      if (this.size === "x-small") return "S"
      if (this.size === "small") return "L"
      if (this.size === "medium") return "M"
      return "L"
    },
    /**
     * Checking if the given url is for video content or not
     */
    isVideo() {
      let url = this.link
      let regEx =
        /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com)|dailymotion.com)\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/

      if (!url || !url.match(regEx) || url.includes("channel")) return false

      //Build the embeded url based on diferent platform
      if (RegExp.$3.indexOf("youtu") > -1) {
        this.embedUrl = "https://www.youtube.com/embed/" + RegExp.$6
      } else if (RegExp.$3.indexOf("vimeo") > -1) {
        this.embedUrl = "https://player.vimeo.com/video/" + RegExp.$6
      } else if (RegExp.$3.indexOf("dailymotion") > -1) {
        this.embedUrl = "https://www.dailymotion.com/embed/video/" + RegExp.$6
      }
      return true
    },
  },
  mounted() {
    if (this.image) {
      let res = getPath({
        src_temp: this.image,
        src_init: this.image,
        entity_slug_id: this.entitySlugId,
        size: "original",
        visibility: this.visibility,
        webpIsSupported: this.$webpIsSupported,
      })
      this.images.push(res.src)
    }
  },
  methods: {
    nextAvailableImage() {
      this.$emit("nextAvailableImage")
    },
    showImgBox() {
      this.index = 0
    },
  },
}
</script>
<style lang="scss">
@import "@/assets/sass/_variables";
.MediaDisplay {
  height: 100%;
  &.videoLoader {
    background: 50% 50% no-repeat;
    background-size: cover;
    background-image: url("/static/loading-image.gif");
  }
  iframe {
    width: 100%;
    height: 100%;
  }

  .responsive_image_container {
    .responsive_image {
      background-color: $brown-bg;
    }
  }
  .LinkPreview__image:hover {
    cursor: pointer;
  }
}
/**
 * RESPONSIVE
 * --------------------------------------------
 */
/**
  * Mobile
  * - - - - - - - - - - - - - - - - - - - - - - - - - - */
.mobile {
  .MediaDisplay {
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.media_display_slide {
  position: absolute;
  right: 0.7rem;
  top: 0.7rem;
  font-size: 1.3rem;
  height: 3rem;
  transition: all 0.5s ease;
  color: #fff;
  border: none;
  line-height: 1;
  padding: 10px;
  outline: none;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.7);
  i {
    margin: 0;
  }
}
.media_display_slide:hover {
  color: #001f3f;
  background: rgba(255, 253, 253, 0.7);
}

.vgs__container {
  background-color: transparent !important;
  margin-top: 100px !important;
  position: relative !important;
}

.vgs__container__img {
  margin: 0 !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
</style>
