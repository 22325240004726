<template>
  <div
    class="CustomFieldItem"
    :class="{ fullWidth: hasTextarea || hasGeolocation }"
    v-if="typeHint !== 'spatial' && (displayEmptyFields || value != '-')"
  >
    <dt v-if="machineName != 'event_registration_link'">
      <i v-if="icon" :class="`icon-${icon}`" />
      {{ displayName }}
    </dt>

    <dd v-if="hasThisLink && machineName != 'event_registration_link'">
      <a :href="hasThisLink" target="_blank" v-html="cleanLink" />
    </dd>
    <dd v-else-if="hasThisLink && machineName == 'event_registration_link'">
      <a :href="hasThisLink" target="_blank"
        ><mark>S'enregistrer pour participer</mark></a
      >
    </dd>
    <dd v-else v-html="value" :class="{ 'has-textarea': hasTextarea }"></dd>
  </div>
</template>

<script>
import MomentSupportMixin from "@/components/Mixins/Emb_MomentSupportMixin"
export default {
  name: "CustomFieldContentItem",
  mixins: [MomentSupportMixin],
  props: {
    /**
     * Fields object to render
     * */
    customFieldContent: {
      type: Object,
      required: true,
    },
    /* *
     * Should display empty content
     */
    displayEmptyFields: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    displayName() {
      return this.customFieldContent.customFieldDefinition.display_name
    },
    machineName() {
      return this.customFieldContent.customFieldDefinition.field_name
    },
    typeHint() {
      return this.customFieldContent.type_hint
    },
    cleanLink() {
      return this.value.replace("https://", "").replace("http://", "")
    },
    hasTextarea() {
      return (
        this.customFieldContent.customFieldDefinition.field_input === "textarea"
      )
    },
    hasGeolocation() {
      return (
        this.customFieldContent.customFieldDefinition.field_input ===
        "geolocation"
      )
    },

    value() {
      let v =
        this.typeHint === "datetime"
          ? this.formatDate(this.customFieldContent.value_datetime)
          : this.customFieldContent[`value_${this.typeHint}`]
      return v ? v : "-"
    },
    hasThisLink() {
      if (
        this.machineName === "website" ||
        this.machineName === "website_link" ||
        this.machineName === "social_links" ||
        this.machineName === "event_link" ||
        this.machineName === "event_registration_link" ||
        this.machineName.endsWith("_url")
      ) {
        return this.value.includes("https://") || this.value.includes("http://")
          ? this.value
          : "http://" + this.value
      } else if (
        this.machineName === "email_address" ||
        this.machineName === "association_email"
      )
        return "mailto:" + this.value

      return false
    },
    icon() {
      if (
        this.machineName === "phone_number" ||
        this.machineName === "association_phone"
      )
        return "phone"
      else if (this.machineName.endsWith("_url")) {
        if (this.machineName.startsWith("facebook")) return "facebook"
        if (this.machineName.startsWith("linkedin")) return "linkedin"
        if (this.machineName.startsWith("instagram")) return "instagram"
        return "globe"
      } else if (
        this.machineName === "website" ||
        this.machineName === "website_link" ||
        this.machineName === "social_links"
      )
        return "globe"
      else if (this.machineName === "schedule") return "clock"
      else if (
        this.machineName === "email_address" ||
        this.machineName === "association_email"
      )
        return "send"
      else return null
    },
  },
}
</script>
<style lang="scss">
@import "@/assets/sass/_variables";
.CustomFieldItem {
}
</style>
