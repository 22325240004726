<template>
  <div class="TileNotification">
    <div
      class="TileNotification__column"
      v-if="
        $firstItemInContributorship &&
        $firstItemInContributorship.type !== 'RELATT'
      "
    >
      <!-- <i class="icon-rss"></i> -->
      <a
        :href="`${$relattUrl}/person/${$firstItemInContributorship.slug}`"
        target="_blank"
        class="flex-row flex-center"
      >
        <responsive-img
          :src="$firstItemInContributorship.image"
          :alt="$firstItemInContributorship.name"
          :absolutePath="false"
          :responsive_class="`avatar-xs ${$firstItemInContributorship.image_class}`"
        ></responsive-img>

        {{ $firstItemInContributorship.name }}
      </a>
    </div>
    <div
      class="TileNotification__column"
      v-if="
        $firstItemInContributorship &&
        $firstItemInContributorship.type !== 'RELATT'
      "
    >
      <template v-if="contributorship.length === 2">
        <template v-if="contributorship[0].slug !== contributorship[1].slug">
          et
          <a
            :href="`${$relattUrl}/person/${contributorship[1].slug}`"
            target="_blank"
            class="flex-row flex-center"
          >
            {{ contributorship[1].name }}
          </a>
          ont
        </template>
        <template v-else> a </template>
      </template>

      <template v-if="contributorship.length > 2">
        et {{ contributorship.length - 1 }} auteurs ont
      </template>

      <template v-if="contributorship.length === 1"> a</template>
      {{ isRelatedPost ? "relaté" + ifPluralForm : "publié" + ifPluralForm }}
      ceci
    </div>

    <!-- -->
    <div
      v-if="$firstItemInContributorship.type === 'RELATT'"
      class="TileNotification__column"
    >
      Ceci a été relaté
    </div>

    <div v-if="!$firstItemInContributorship" class="TileNotification__column">
      <template v-if="isRelatedPost">relaté</template>
      <template v-else>publié</template>
    </div>

    <!-- Destination Space > Folder -->
    <div v-if="folder" class="TileNotification__column">
      dans&nbsp;
      <template
        v-if="
          showSpaceInLink ||
          !slugCurrentSpace ||
          slugCurrentSpace != folder.space.slug
        "
      >
        <a
          :href="`${$relattUrl}/${folder.space.slug}`"
          target="_blank"
          class="flex-row flex-center"
        >
          <responsive-img
            :src="folder.space.logo"
            :alt="folder.space.name"
            :absolutePath="false"
            :responsive_class="
              size === 'large' ? `avatar-sm space` : `avatar-xs space`
            "
          ></responsive-img>
          {{ folder.space.name }}
        </a>
        &nbsp;&gt;&nbsp;
      </template>

      <a
        :href="`${$relattUrl}/${folder.space.slug}/${folder.slug}`"
        target="_blank"
      >
        {{ folder.name }}
      </a>
    </div>

    <!-- Bot author (for related posts) -->
    <div
      class="TileNotification__column"
      v-if="$firstItemInContributorship.type === 'RELATT'"
    >
      <!-- <i class="icon-rss"></i> -->
      par&nbsp;
      <!-- <i class="icon-tt text-primary"></i> -->
      <responsive-img
        :src="$firstItemInContributorship.image"
        :alt="$firstItemInContributorship.name"
        :absolutePath="false"
        :responsive_class="`avatar-xs space`"
      ></responsive-img
      >&nbsp;{{ $firstItemInContributorship.name }}
      <a href="#" class="flex-row flex-center"> </a>
    </div>
    <div class="TileNotification__column" v-if="date">
      &nbsp;- {{ formatDate(date) }}
    </div>
  </div>
</template>

<script>
import MomentSupportMixin from "@/components/Mixins/Emb_MomentSupportMixin"
import ResponsiveImg from "@/components/Img/Emb_ResponsiveImg"

export default {
  name: "TileNotification",
  mixins: [MomentSupportMixin],
  components: {
    ResponsiveImg,
  },
  props: {
    contributorship: {
      type: Array,
      required: false,
    },
    date: {
      type: String,
      required: false,
    },
    isRelatedPost: {
      type: Boolean,
      default: false,
      required: true,
    },
    folder: {
      type: Object,
      default: null,
      required: false,
    },
    /**
     * Force to display space before folder
     */
    showSpaceInLink: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * Notification variant
     */
    size: {
      type: String,
      required: false,
      default: "default",
    },
  },
  computed: {
    $firstItemInContributorship: function () {
      if (this.contributorship && this.contributorship.length) {
        return this.contributorship[0]
      }
      return false
    },
    ifPluralForm() {
      return this.contributorship.length > 1 ? "s" : ""
    },
    slugCurrentSpace() {
      /*return this.$route.params.slug_space
        ? this.$route.params.slug_space
        : false;*/

      return "My Space"
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/sass/_variables";

#app_relatt_embed {
  .TileNotification {
    border-top: 1px solid #fbfbfb;
    border-bottom: 1px solid #fbfbfb;
    padding: 10px;
    color: $gray-600;
    align-items: center;
    display: flex;

    .TileNotification__column {
      margin-right: 5px;
      display: inherit;
      align-items: center;
      .space {
        margin-top: -4px;
      }
    }
    a {
      color: $gray-700;

      &:hover {
        text-decoration: underline;
      }

      .responsive_image_container {
        margin: 0 10px 0 0;
      }
    }

    .post-signature__logo {
      width: 27.5px;
      height: 27.5px;
    }
  }
}
</style>
