<template>
  <!-- Space icon -->
  <a :href="`${$relattUrl}/${publisher.slug}`" target="_blank">
    <responsive-img
      class="PublisherLogo--image"
      :class="'avatar-' + size"
      :src="publisher.logo"
      :entitySlugId="publisher.ttuid"
      :alt="publisher.name"
    />
  </a>
</template>

<script>
import ResponsiveImg from "@/components/Img/Emb_ResponsiveImg"
/**
 * Generic component that render publisher logo
 */
export default {
  name: "PublisherLogo",
  components: {
    ResponsiveImg
  },
  props: {
    /**
     * Get current publisher to display logo
     */
    publisher: {
      type: Object,
      required: true
    },
    /**
     * Logo size
     * */
    size: {
      type: String,
      default: "default",
      required: false
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/sass/_variables";
#app_relatt_embed {
  .PublisherLogo {
    display: inline-block;
    .responsive_image_container {
      .responsive_image {
        background-size: contain;
        background-color: #fff;
      }
    }
    &.large {
      .PublisherLogo--image {
        height: 50px;
        width: 50px;
        margin-right: 10px;
      }
    }
    &.default {
      .PublisherLogo--image {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }
    }
    &.small {
      .PublisherLogo--image {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
    &.x-small {
      .PublisherLogo--image {
        width: 17px;
        height: 17px;
        margin-right: 5px;
      }
    }
  }
}
</style>
