<template>
  <div>
    <a :href="`${$relattUrl}/${space.slug}/about`" target="_blank">
      <div class="Space-Title-Logo-Only-Container" :class="$mq">
        <responsive-img
          class="Space-Title-Logo-Only"
          :src="space.logo"
          size="S"
          :alt="space.name"
          :entitySlugId="space.ttuid"
        ></responsive-img>
      </div>
    </a>
  </div>
</template>

<script>
import ResponsiveImg from "@/components/Img/Emb_ResponsiveImg"
export default {
  name: "SpaceLogoOnly",
  components: {
    ResponsiveImg
  },
  props: {
    /**
     * Current space
     */
    space: {
      type: Object,
      required: false
    }
  }
}
</script>

<style lang="scss">
#app_relatt_embed {
  .Space-Title-Logo-Only-Container {
    width: 350px;
    height: 250px;
    position: relative;
    .Space-Title-Logo-Only {
      width: 250px;
      height: 250px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .Space-Title-Logo-Only-Container:hover {
    background-color: ghostwhite;
  }

  .Space-Title-Logo-Only-Container.mobile {
    width: 100px;
    height: 100px;
    position: relative;
    .Space-Title-Logo-Only {
      width: 100px;
      height: 100px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
