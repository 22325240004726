<template>
  <div>
    <article
      v-if="!post_data_loading"
      class="PostTile"
      :style="contentTypeCssBorderStyle"
      :class="[{ gridMode: isGrid }, size, variant]"
    >
      <!-- Notification -->
      <post-tile-notification
        v-if="false"
        :contributorship="processedPost.contributorship"
        :date="processedPost.created_at.formatted"
        :isRelatedPost="processedPost.isRelatedPost"
        :folder="processedPost.folder"
      ></post-tile-notification>
      <div class="PostTile--bottom-border">
        <!-- Tile -->
        <component
          :is="'PostTile' + PostTileVariant"
          :post="processedPost"
          :isGrid="isGrid"
          :size="size"
          class="PostTile--content"
        >
          <!-- Featured tag -->
          <div
            class="PostTile--content__tag-is-featured"
            v-if="isFeaturedInCurrentFolder && !isInAfeaturedGrid"
            slot="featured-tag"
          >
            Mis en avant
          </div>
          <!-- Content Type -->
          <template v-slot:[slotNameForContentTypeLabel]>
            <content-type-icon-label
              :contentType="processedPost.installedContentType"
            >
              <!-- Special notification for featured tiles -->
              <div v-if="false" slot="after">
                <post-tile-notification
                  :contributorship="null"
                  :date="null"
                  :isRelatedPost="processedPost.isRelatedPost"
                  :folder="processedPost.folder"
                ></post-tile-notification>
              </div>
            </content-type-icon-label>
          </template>

          <!-- Custom Fields -->
          <template v-if="showCustomFields" v-slot:customFields>
            <template
              v-if="postContentType !== 'FILE' && postContentType !== 'LINK'"
            >
              <custom-field-content-list
                v-if="processedPost.customFieldContents.length"
                :custom-field-contents="featuredCustomFieldContents"
                :displayEmptyFields="false"
                :displayAddressTitle="false"
                :addressInOneLine="true"
              />
            </template>

            <custom-field-content-list
              v-if="processedPost.augmentedCustomFieldContents.length"
              :custom-field-contents="featuredAugmentedCustomFieldContents"
              :displayEmptyFields="false"
            />
          </template>

          <!-- Custom Fields -->
          <template
            v-if="
              (processedPost.customFieldContents.length ||
                processedPost.augmentedCustomFieldContents.length) &&
              (!isGrid || size === 'large')
            "
            v-slot:customFields
          >
            <template
              v-if="postContentType !== 'FILE' && postContentType !== 'LINK'"
            >
              <custom-field-content-list
                v-if="processedPost.customFieldContents.length"
                :custom-field-contents="featuredCustomFieldContents"
                :displayEmptyFields="false"
                :displayAddressTitle="false"
                :addressInOneLine="true"
              />
            </template>

            <custom-field-content-list
              v-if="processedPost.augmentedCustomFieldContents.length"
              :custom-field-contents="featuredAugmentedCustomFieldContents"
              :displayEmptyFields="false"
            />
          </template>

          <!-- Publisher -->
          <template v-slot:[slotNameForSignature] v-if="!hideSignature">
            <publisher-with-post-detail
              :post="processedPost"
              :size="isGrid && size !== 'large' ? 'x-small' : size"
              class="PostTile--signature"
              slot="signature"
              :hideTags="isGrid"
              :hideFolder="isGrid"
            ></publisher-with-post-detail>
          </template>
        </component>
      </div>
    </article>
  </div>
</template>

<script>
import ContentTypeIconLabel from "@/components/Other/Emb_ContentTypeIconLabel"
import TileNotification from "@/components/Post/Emb_TileNotification"
import PublisherWithPostDetail from "@/components/Author/Emb_PublisherWithPostDetail"
import processPost from "@/helpers/post/Emb_processPost"
import PostTileDefault from "@/components/Post/Emb_PostTileDefault"
import CustomFieldsList from "@/components/Other/Emb_CustomFieldsList"
import PostTileRelateSpace from "@/components/Post/Emb_PostTileRelateSpace"
import PostTileRelateFolder from "@/components/Post/Emb_PostTileRelateFolder"
import CustomFieldContentList from "@/components/CustomFieldContent/Emb_CustomFieldContentList"

export default {
  name: "PostTile",
  components: {
    TileNotification,
    ContentTypeIconLabel,
    PublisherWithPostDetail,
    PostTileDefault,
    CustomFieldsList,
    PostTileRelateSpace,
    PostTileRelateFolder,
    CustomFieldContentList
  },
  props: {
    /**
     * The current post
     * */
    postTtuid: {
      type: String,
      default: "",
      required: false
    },
    post: {
      type: Object,
      default: () => {},
      required: false
    },
    currenUser: {
      type: Object,
      default: () => {},
      required: false
    },
    /**
     * Informe if `post` props is already processed or should be
     * */
    isAlreadyProcessed: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * The Folder Ttuid where posts are featured
     * */
    currentFolderTtuid: {
      type: String,
      default: "",
      required: false
    },
    /**
     * Is the post featured in current Folder
     * */
    isFeaturedInCurrentFolder: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * The home folder Ttuid
     * */
    homeTtuid: {
      type: String,
      default: "",
      required: false
    },
    /**
     * Is the post featured on space home page
     * */
    isFeaturedInHomeFolder: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Is current post displayed in a featured box
     * */
    isInAfeaturedGrid: {
      type: Boolean,
      required: false,
      default: false
    },
    /** ----------------
     * Tile layout
     * */
    /**
     * Is the layout in `Grid` mode or `Flux` mode
     * */
    isGrid: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Tile size (small, medium or large)
     * */
    size: {
      type: String,
      default: "small",
      required: false
    },
    /**
     * Tile variant (imageRight, imageLeft or imageTop)
     * */
    variant: {
      type: String,
      default: "imageRight",
      required: false
    },

    /** ----------------
     * Tile displaying option
     * */
    /**
     * Should show a top notification
     * */
    showNotification: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Show is Also Related By notification
     * */
    showIsAlsoRelatedBy: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Hide the tile signature
     * */
    hideSignature: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Hide the footer with socials interactions
     * */
    hideFooter: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Show minimal set of Social buttons
     * */
    minimalSocialButtonVariant: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      post_data_loading: 0,

      /***
       *Whether the tile should display also non featured custom field content.
       */
      shouldDisplayNonFeaturedCustomFieldContents: false
    }
  },
  computed: {
    showCustomFields() {
      return (
        (this.processedPost.customFieldContents.length ||
          this.processedPost.augmentedCustomFieldContents.length) &&
        (!this.isGrid || this.size === "large")
      )
    },
    /**
     * Options for the 3 dots dropdown menu.
     * */

    optionsDropdownMenu() {
      return []
    },

    /**
     * Return post content type
     * */
    postContentType() {
      return this.processedPost.installedContentType?.content_type_key
    },
    /**
     * Define postTile Variant to load in template depend on Content Type
     * */
    PostTileVariant() {
      if (!this.processedPost) return false
      else if (
        this.postContentType === "RELATT_SPACE" ||
        this.displaySpaceInsteadOfContent
      ) {
        return "RelateSpace"
      }
      if (this.postContentType === "LINK") {
        return "Default"
      } else if (this.processedPost.isFile) {
        return "Default"
      } else if (this.postContentType === "RELATT_FOLDER") {
        return "RelateFolder"
      } else {
        return "Default"
      }
    },
    /**
     * Define slot names depend on displaying mode
     * */

    slotNameForContentTypeLabel() {
      return this.isGrid
        ? "contentTypeLabel-gridMode"
        : "contentTypeLabel-fluxMode"
    },
    slotNameForSignature() {
      return this.isGrid && this.size != "large"
        ? "signature-gridMode"
        : "signature-fluxMode"
    },

    /**
     * Display featured custom fields
     */
    featuredCustomFieldContents() {
      return this.processedPost.customFieldContents.filter(item => {
        return (
          this.shouldDisplayNonFeaturedCustomFieldContents ||
          item.customFieldDefinition.is_default_feature
        )
      })
    },
    /**
     * Set CSS depend on ContentType
     * */
    contentTypeCssKeyForColor() {
      return this.processedPost.installedContentType
        ? "content-types-" +
            this.processedPost.installedContentType.content_type_key.toLowerCase()
        : ""
    },
    /**
     * Set CSS depend on ContentType
     * */
    contentTypeCssBorderStyle() {
      return this.processedPost.installedContentType
        ? { "--ct-color": this.processedPost.installedContentType.color }
        : {}
    },

    processedPost() {
      return processPost(this.post)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/_variables";

#app_relatt_embed {
  .PostTile--content__tag-is-featured {
    background-color: var(--space-color);
  }
  /**
 * Left hover color
 */
  .PostTile {
    &:hover {
      border-left-color: var(--ct-color) !important;
    }
  }

  .TileNotification {
    border-top: 1px solid #fbfbfb;
    border-bottom: 1px solid #fbfbfb;
    padding: 10px;
    color: $gray-600;
    align-items: center;
    display: flex;

    .TileNotification__column {
      margin-right: 5px;
      display: inherit;
      align-items: center;
      .space {
        margin-top: -4px;
      }
    }
    a {
      color: $gray-700;

      &:hover {
        text-decoration: underline;
      }

      .responsive_image_container {
        margin: 0 10px 0 0;
      }
    }

    .post-signature__logo {
      width: 27.5px;
      height: 27.5px;
    }
  }

  /**
        *  Content type icon & text
        * - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .ContentTypeIconLabel {
    color: $primary;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 15px;
    margin-bottom: 12px;

    > div {
      padding: 3px 0 0 3px;
    }
    .ContentTypeIconLabel--label {
      text-transform: uppercase;
      font-size: 14px;
    }

    > i {
      margin-right: 3px;
      font-size: 22px;
      color: var(--ct-color);
    }
    &.large {
      font-size: 20px;
      > i {
        font-size: 27px;
      }
    }
    &:hover {
      border-left-color: var(--ct-color);
    }
  }

  // Special type to replace image with content type label
  .contentTypeInsteadOfImage {
    height: 100%;
    background-color: rgba($primary, 0.85);
    opacity: 0.25;
    border-radius: 0px;
    display: flex;
    align-items: center;

    .ContentTypeIconLabel {
      flex-direction: column;
      width: 100%;
      margin: 0;
      > i {
        font-size: 40px;
        color: #fff;
      }
    }
    &.medium {
      .ContentTypeIconLabel {
        > i {
          font-size: 130px;
        }
      }
    }
    &.small {
      .ContentTypeIconLabel {
        min-width: 50px;
        padding: 10px 0;
        > i {
          font-size: 30px;
        }
      }
    }
    .ContentTypeIconLabel--label {
      color: #ccc;
      display: none;
    }

    article {
      overflow: visible;
    }
  }
}
</style>
