<template>
  <div>
    <div
      infinite-scroll-throttle-delay="100"
      infinite-scroll-immediate-check="true"
      infinite-scroll-disabled="endReached"
      :class="isGrid ? 'grid-3' : ''"
    >
      <slot />
      <!-- Contextual messages -->
      <div  v-if="allContents" class="post-list__footer">
        <h5>
          <span v-if="isLoading">
            <loader-label v-if="showLoader" :loading-message="loadingMessage" />
            <span v-else>Chargement ...</span>
          </span>

          <span
            v-else-if="emptyList"
            v-html="emptyListMessage"
            class="post-list__footer--empty-list"
          ></span>

          <span v-else-if="endReached">Vous êtes à la fin de la liste</span>
          <button v-else class="btn btn-secondary p-2" @click="loadMore">
            {{loadBtnText}}
          </button>
          &nbsp;
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderLabel from "@/components/Other/Emb_LoaderLabel"
export default {
  components: { LoaderLabel },
  props: {
    /**
     * When content is loading
     */
    isLoading: {
      type: Number
    },
    /**
     * is the list empty
     */
    emptyList: {
      type: Boolean,
      default: true
    },
    /**
     * end of the list
     */
    endReached: {
      type: Boolean,
      default: false
    },
    /**
     * grid class
     */
    isGrid: {
      type: Boolean,
      default: true
    },
    /**
     * Message when loading
     */
    loadingMessage: {
      type: String,
      default: "Chargement des contenus..."
    },
    /**
     * Message when loading
     */
    emptyListMessage: {
      type: String,
      default: "Aucun contenu pour le moment"
    },
    /**
     * Should display loader
     */
    showLoader: {
      type: Boolean,
      default: true,
      required: false
    },
    allContents: {
      type: Boolean,
      default: true
    },
    loadBtnText: {
      type: String,
      default: "Charger plus de contenu..."
    }
  },
  methods: {
    loadMore() {
      this.$emit("loadMore")
    }
  }
}
</script>
<style lang="scss">
#app_relatt_embed {
  .post-list__footer {
    min-height: 200px;
    width: 100% !important;
    h5 {
      text-align: center;
      margin: 60px 0;
      padding: 30px 0;
      // font-size: 1em;
    }
    .post-list__footer--empty-list {
      display: block;
      text-align: center;
    }
  }
}
</style>
