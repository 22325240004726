<template>
  <!-- Loading -->
  <div
    v-if="(folder_data_loading || loading_federated_space_data) && first_load"
  >
    <loader class="m-auto mt-5"></loader>
  </div>

  <!-- Result -->
  <div
    v-else
    :class="federatedSpaces.length > 0 ? 'container-fluid' : 'container'"
  >
    <div v-if="loading_federated_space_data" class="my-3">
      <span class="text-primary">Chargement des informations...</span>
    </div>

    <div id="federatedSpaces">
      <div v-if="listComponent == 'LIST'" class="mt-3">
        <div class="ml-0 p-0">
          <h5>Espaces associés ({{ federatedSpaces.length }})</h5>
        </div>
      </div>
      <div v-if="listComponent == 'LIST'" class="card-displayer">
        <component
          :is="
            spaceComponent == 'CARD'
              ? 'SpaceCard'
              : spaceComponent == 'LOGO_ONLY'
              ? 'SpaceLogoOnly'
              : 'SpaceTile'
          "
          v-for="federatedSpace in federatedSpaces"
          :key="federatedSpace.ttuid"
          :space="federatedSpace"
          :space-title-style="spaceTitleStyle"
          :space-description-style="spaceDescriptionStyle"
          :show-logo="!hideLogo"
          :more-space-fields="moreSpaceFields"
        />
      </div>
      <div v-else>
        <component
          :is="listComponent == 'TABS' ? 'SpaceTabs' : 'SpaceAccordeons'"
          :filter-ttuid="filterTtuid"
          :tag-ttuid="tagTtuid"
          :filter-tab-ttuid="filterTabTtuid"
          folder-slug="network"
          :space-slug="federatingSpace.slug"
          :space-title-style="spaceTitleStyle"
          :space-description-style="spaceDescriptionStyle"
          :space-component="spaceComponent"
          :more-space-fields="moreSpaceFields"
          :list-component="listComponent"
          :hideLogo="spaceComponent == 'LOGO_TILE' ? false : true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GET_SPACE_FEDERATED_SPACES_WITH_TAGS from "@/graphql/Space/Emb_getSpaceFederatedSpacesWithTags.graphql"
import GET_SPACE from "@/graphql/Space/Emb_getSpaceInstalledContentTypes.graphql"
import Loader from "@/components/Other/Emb_Loader"
import SpaceTile from "@/components/Space/SpaceTile"
import SpaceCard from "@/components/Space/SpaceCard"
import SpaceLogoOnly from "@/components/Space/SpaceLogoOnly"
import SpaceTabs from "@/components/Space/Emb_SpaceTabs"
import SpaceAccordeons from "@/components/Space/Emb_SpaceAccordeons"

/**
 * Show federated spaces
 */
export default {
  name: "SpaceNetwork",
  components: {
    Loader,
    SpaceTile,
    SpaceCard,
    SpaceLogoOnly,
    SpaceTabs,
    SpaceAccordeons
  },
  data() {
    return {
      /**
       * array of associate spaces
       */
      federatedSpaces: [],
      federatingSpace: {},
      loading_federated_space_data: 0,
      folder_data_loading: 0,
      first_load: true
    }
  },
  props: {
    folderSlug: {
      type: String
    },
    folderTtuid: {
      type: String
    },
    spaceSlug: {
      type: String
    },
    spaceTtuid: {
      type: String
    },
    spaceComponent: {
      type: String,
      default: "Card"
    },
    /**
     * Style of space title
     */
    spaceTitleStyle: {
      type: Object
    },
    /**
     * Style of space description
     */
    spaceDescriptionStyle: {
      type: Object
    },
    hideLogo: {
      type: Boolean,
      default: false
    },
    moreSpaceFields: {
      type: Array
    },
    listComponent: {
      type: String,
      default: "LIST"
    },
    filterTtuid: {
      type: String,
      default: ""
    },
    tagTtuid: {
      type: String,
      default: ""
    },
    filterTabTtuid: {
      type: String,
      default: ""
    },
    paginate: {
      type: Boolean,
      default: true
    }
  },
  apollo: {
    FederatedSpaces: {
      query: GET_SPACE_FEDERATED_SPACES_WITH_TAGS,
      loadingKey: "loading_federated_space_data",
      variables() {
        return {
          spaceSlug: this.spaceSlug,
          spaceTtuid: this.spaceTtuid,
          tags: []
        }
      },
      skip() {
        return !this.spaceSlug && !this.spaceTtuid
      },
      update(data) {
        this.federatedSpaces = data.GetSpaceFederatedSpacesWithTags
      }
    },
    getFederatingSpace: {
      query: GET_SPACE,
      variables() {
        return {
          slug: this.spaceSlug,
          ttuid: this.spaceTtuid
        }
      },
      update(data) {
        if (data.GetSpaceBy[0]) {
          this.federatingSpace = data.GetSpaceBy[0]
        }
      }
    }
  },

  computed: {
    content_loading() {
      return !!this.loading_federated_space_data
    }
  }
}
</script>
