<template>
  <div>
    <div style="padding-top: 20px;">
      <div class="c-accordion" v-for="tag in tags" :key="tag.ttuid">
        <div
          class="c-accordion-header row"
          @click="expendAccordion(tag.ttuid), loadSpaces(tag.ttuid)"
        >
          <div class="col-lg-11">
            <span>{{ tag.name }}</span>
          </div>
          <div class="col-lg-1 accordion-header-icon">
            <i
              :class="
                expanded != tag.ttuid ? 'icon-arrow-down' : 'icon-arrow-up'
              "
            />
          </div>
        </div>
        <div
          class="c-accordion__body"
          :class="{ 'is-expanded': expanded == tag.ttuid }"
        >
          <div v-if="posts_data_loading">
            <loader class="m-auto mt-5"></loader>
          </div>
          <div
            v-else
            class="card-displayer"
            :class="$mq"
            style="padding-top: 20px;"
          >
            <span v-if="!posts.length" class="empty_list">Aucun élément</span>
            <component
              :is="
                spaceComponent == 'CARD'
                  ? $mq !== 'mobile'
                    ? 'SpaceCard'
                    : 'SpaceTile'
                  : spaceComponent == 'LOGO_ONLY'
                  ? 'SpaceLogoOnly'
                  : 'SpaceTile'
              "
              v-for="post in posts"
              :key="post.sourceOriginal.publisher.ttuid"
              :space="post.sourceOriginal.publisher"
              :space-title-style="spaceTitleStyle"
              :space-description-style="spaceDescriptionStyle"
              :show-logo="
                !hideLogo || (spaceComponent == 'CARD' && $mq == 'mobile')
              "
              :more-space-fields="moreSpaceFields"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResponsiveImg from "@/components/Img/Emb_ResponsiveImg"
import GET_FILTER_BY_TTUID from "@/graphql/Folder/Emb_getFilter.graphql"
import GET_POST_FROM_CURRENT_FOLDER_WITH_CUSTOM_FILTERS from "@/graphql/Post/Emb_getPostsFromFolderWithFilters.graphql"
import SpaceTile from "@/components/Space/SpaceTile"
import SpaceCard from "@/components/Space/SpaceCard"
import SpaceLogoOnly from "@/components/Space/SpaceLogoOnly"
import Loader from "@/components/Other/Emb_Loader"

export default {
  name: "SpaceTabs",
  components: {
    ResponsiveImg,
    SpaceTile,
    Loader,
    SpaceCard,
    SpaceLogoOnly
  },
  props: {
    filterTtuid: {
      type: String,
      default: ""
    },
    folderSlug: {
      type: String,
      default: null
    },
    spaceSlug: {
      type: String,
      required: true
    },
    spaceComponent: {
      type: String,
      default: "Card"
    },
    /**
     * Style of space title
     */
    spaceTitleStyle: {
      type: Object
    },
    /**
     * Style of space description
     */
    spaceDescriptionStyle: {
      type: Object
    },
    hideLogo: {
      type: Boolean,
      default: false
    },
    moreSpaceFields: {
      type: Array
    },
    tagTtuid: {
      type: String,
      default: ""
    },
    filterTabTtuid: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activetab: 0,
      tags: [],
      posts: [],
      posts_data_loading: false,
      accordion: [],
      expanded: null
    }
  },
  apollo: {
    GetSpaceInstalledFilter: {
      query: GET_FILTER_BY_TTUID,
      variables() {
        return {
          ttuid: this.filterTtuid
        }
      },
      update(data) {
        if (data.GetSpaceInstalledFilter) {
          this.tags = data.GetSpaceInstalledFilter.filterVersion.tags
        }
      }
    },

    /* ------
     * Get Posts from current Folder
     * */
    getPostsFromFolder: {
      query: GET_POST_FROM_CURRENT_FOLDER_WITH_CUSTOM_FILTERS,
      fetchPolicy: "network-only",
      loadingKey: "posts_data_loading",
      variables() {
        return {
          input: {
            spaceSlug: this.spaceSlug,
            folderSlug: this.folderSlug,
            tags:
              this.tagTtuid && this.filterTabTtuid
                ? [
                    {
                      ttuid: this.tags[0].ttuid,
                      filter_ttuid: this.filterTtuid
                    },
                    { ttuid: this.tagTtuid, filter_ttuid: this.filterTabTtuid }
                  ]
                : [
                    {
                      ttuid: this.tags[0].ttuid,
                      filter_ttuid: this.filterTtuid
                    }
                  ],
            contentType: "ALL",
            offset: 0,
            first: 50,
            orderBy: "published_at"
          }
        }
      },
      update(data) {
        this.posts = []
        this.posts = data.getPostsFromFolderWithFilters.posts
      },
      skip() {
        //console.log(tags.length);
        return !this.tags.length
      }
    }
  },
  methods: {
    expendAccordion(tagTtuid) {
      if (this.expanded == tagTtuid) {
        this.expanded = null
      } else {
        this.expanded = tagTtuid
      }
    },
    async loadSpaces(tagTtuid) {
      this.posts_data_loading = true
      this.$apollo.queries.getPostsFromFolder.skip = false
      await this.$apollo.queries.getPostsFromFolder.refetch({
        input: {
          spaceSlug: this.spaceSlug,
          folderSlug: this.folderSlug,
          tags:
            this.tagTtuid && this.filterTabTtuid
              ? [
                  { ttuid: tagTtuid, filter_ttuid: this.filterTtuid },
                  { ttuid: this.tagTtuid, filter_ttuid: this.filterTabTtuid }
                ]
              : [{ ttuid: tagTtuid, filter_ttuid: this.filterTtuid }],
          contentType: "ALL",
          offset: 0,
          first: 50,
          orderBy: "published_at"
        }
      })
      this.$apollo.queries.getPostsFromFolder.skip = true
      this.posts_data_loading = false
    }
  }
}
</script>

<style lang="scss">
#app_relatt_embed {
  .c-accordion {
    width: 100%;
    padding-bottom: 3px;
    .c-accordion__body {
      margin-left: 20px;
      display: none;
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
      &.is-expanded {
        display: inline;
        max-height: 1700px;
        transition: max-height 1s ease-in-out;
        overflow: auto;
      }
    }
    .c-accordion-header {
      padding: 10px;
      background-color: #50e3c2;
      border-radius: 10px;
      width: 100%;
      .accordion-header-icon {
        text-align: end;
      }
    }

    .c-accordion-header:hover {
      cursor: pointer;
    }
  }

  .empty_list {
    font-family: inherit;
    line-height: 1.2;
    font-size: 18px;
    font-weight: 300;
    color: #adb5bd;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    display: block;
    text-align: left;
  }
}
</style>
