var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TileNotification"},[(
      _vm.$firstItemInContributorship &&
      _vm.$firstItemInContributorship.type !== 'RELATT'
    )?_c('div',{staticClass:"TileNotification__column"},[_c('a',{staticClass:"flex-row flex-center",attrs:{"href":(_vm.$relattUrl + "/person/" + (_vm.$firstItemInContributorship.slug)),"target":"_blank"}},[_c('responsive-img',{attrs:{"src":_vm.$firstItemInContributorship.image,"alt":_vm.$firstItemInContributorship.name,"absolutePath":false,"responsive_class":("avatar-xs " + (_vm.$firstItemInContributorship.image_class))}}),_vm._v(" "+_vm._s(_vm.$firstItemInContributorship.name)+" ")],1)]):_vm._e(),(
      _vm.$firstItemInContributorship &&
      _vm.$firstItemInContributorship.type !== 'RELATT'
    )?_c('div',{staticClass:"TileNotification__column"},[(_vm.contributorship.length === 2)?[(_vm.contributorship[0].slug !== _vm.contributorship[1].slug)?[_vm._v(" et "),_c('a',{staticClass:"flex-row flex-center",attrs:{"href":(_vm.$relattUrl + "/person/" + (_vm.contributorship[1].slug)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.contributorship[1].name)+" ")]),_vm._v(" ont ")]:[_vm._v(" a ")]]:_vm._e(),(_vm.contributorship.length > 2)?[_vm._v(" et "+_vm._s(_vm.contributorship.length - 1)+" auteurs ont ")]:_vm._e(),(_vm.contributorship.length === 1)?[_vm._v(" a")]:_vm._e(),_vm._v(" "+_vm._s(_vm.isRelatedPost ? "relaté" + _vm.ifPluralForm : "publié" + _vm.ifPluralForm)+" ceci ")],2):_vm._e(),(_vm.$firstItemInContributorship.type === 'RELATT')?_c('div',{staticClass:"TileNotification__column"},[_vm._v(" Ceci a été relaté ")]):_vm._e(),(!_vm.$firstItemInContributorship)?_c('div',{staticClass:"TileNotification__column"},[(_vm.isRelatedPost)?[_vm._v("relaté")]:[_vm._v("publié")]],2):_vm._e(),(_vm.folder)?_c('div',{staticClass:"TileNotification__column"},[_vm._v(" dans "),(
        _vm.showSpaceInLink ||
        !_vm.slugCurrentSpace ||
        _vm.slugCurrentSpace != _vm.folder.space.slug
      )?[_c('a',{staticClass:"flex-row flex-center",attrs:{"href":(_vm.$relattUrl + "/" + (_vm.folder.space.slug)),"target":"_blank"}},[_c('responsive-img',{attrs:{"src":_vm.folder.space.logo,"alt":_vm.folder.space.name,"absolutePath":false,"responsive_class":_vm.size === 'large' ? "avatar-sm space" : "avatar-xs space"}}),_vm._v(" "+_vm._s(_vm.folder.space.name)+" ")],1),_vm._v(" > ")]:_vm._e(),_c('a',{attrs:{"href":(_vm.$relattUrl + "/" + (_vm.folder.space.slug) + "/" + (_vm.folder.slug)),"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.folder.name)+" ")])],2):_vm._e(),(_vm.$firstItemInContributorship.type === 'RELATT')?_c('div',{staticClass:"TileNotification__column"},[_vm._v(" par "),_c('responsive-img',{attrs:{"src":_vm.$firstItemInContributorship.image,"alt":_vm.$firstItemInContributorship.name,"absolutePath":false,"responsive_class":"avatar-xs space"}}),_vm._v(" "+_vm._s(_vm.$firstItemInContributorship.name)+" "),_c('a',{staticClass:"flex-row flex-center",attrs:{"href":"#"}})],1):_vm._e(),(_vm.date)?_c('div',{staticClass:"TileNotification__column"},[_vm._v(" - "+_vm._s(_vm.formatDate(_vm.date))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }