<template>
  <div id="app_relatt_embed">
    <space
      :space-slug="spaceSlug"
      :space-ttuid="spaceTtuid"
      v-if="entityEmbeded == 'space'"
    ></space>
    <post :entity-id="entityId" v-if="entityEmbeded == 'post'"></post>
    <folder
      :folder-slug="folderSlug"
      :space-ttuid="spaceTtuid"
      :space-slug="spaceSlug"
      :folder-ttuid="folderTtuid"
      :featuredPost="featuredPost ? featuredPost : null"
      :space-component="spaceComponent"
      :nb-contents="nbContents"
      :all-contents="allContents"
      :folder-view="folderView"
      :more-space-fields="moreSpaceFields"
      :contentTypeFilters="contentTypeFiltersParsed"
      :paginate="paginate"
      :is-network="
        entityEmbeded == 'space_network' && listComponent == 'DROPDOWN'
      "
      v-if="
        entityEmbeded == 'folder' ||
          (entityEmbeded == 'space_network' && listComponent == 'DROPDOWN')
      "
    ></folder>
    <space-network
      :space-slug="spaceSlug"
      :space-ttuid="spaceTtuid"
      :folder-slug="folderSlug"
      :folder-ttuid="folderTtuid"
      :space-title-style="spaceNameStyleCSS"
      :space-description-style="spaceDecStyleCSS"
      :space-component="spaceComponent"
      :more-space-fields="moreSpaceFields"
      :filter-ttuid="filterTtuid"
      :paginate="paginate"
      :tag-ttuid="tagTtuid"
      :filter-tab-ttuid="filterTabTtuid"
      :list-component="listComponent"
      :hideLogo="spaceComponent == 'LOGO_TILE' ? false : true"
      v-if="entityEmbeded == 'space_network' && listComponent != 'DROPDOWN'"
    ></space-network>
  </div>
</template>

<script>
import Post from "@/components/Post/Emb_Post"
import Folder from "@/components/Folder/Emb_Folder"
import Space from "@/components/Space/Emb_Space"
import SpaceNetwork from "@/components/Space/Emb_SpaceNetwork"
import SpaceCard from "@/components/Space/SpaceCard"

export default {
  name: "App",
  components: {
    Post,
    Folder,
    Space,
    SpaceNetwork,
    SpaceCard
  },
  computed: {
    spaceDecStyleCSS() {
      let style = {}
      style.color = this.spaceDecsColor
      return style
    },
    spaceNameStyleCSS() {
      let style = {}
      style.color = this.spaceNameColor
      return style
    },
    moreSpaceFields() {
      if (this.moreFields) return this.moreFields.split(",")
      return []
    },
    contentTypeFiltersParsed(){
      try {
        let contentTypeFilters = JSON.parse(this.contentTypeFilters);
        if (Array.isArray(contentTypeFilters)) {
          return contentTypeFilters;
        }
        return [];
      } catch (error) {
        return [];
      }
    }
  },
  props: {
    entityEmbeded: {
      type: String,
      required: true
    },
    entityId: {
      type: String,
      default: null
    },
    folderSlug: {
      type: String,
      default: null
    },
    folderTtuid: {
      type: String,
      default: null
    },
    spaceSlug: {
      type: String,
      default: null
    },
    spaceTtuid: {
      type: String,
      default: null
    },
    featuredPost: {
      type: Boolean,
      default: null
    },
    spaceComponent: {
      type: String,
      default: "CARD"
    },
    listComponent: {
      type: String,
      default: "LIST"
    },
    spaceNameColor: {
      type: String,
      default: ""
    },
    spaceDecsColor: {
      type: String,
      default: ""
    },
    moreFields: {
      type: String,
      default: ""
    },
    filterTtuid: {
      type: String,
      default: ""
    },
    tagTtuid: {
      type: String,
      default: ""
    },
    filterTabTtuid: {
      type: String,
      default: ""
    },
    nbContents: {
      type: Number,
      default: 10
    },
    allContents: {
      type: Boolean,
      default: true
    },
    folderView: {
      type: String,
      default: "LIST"
    },
    paginate: {
      type: Boolean,
      default: true
    },
    contentTypeFilters: {
      type: String,
    }
  }
}
</script>
<style lang="scss">
#app_relatt_embed {
  margin: 0;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fff;
  border: none;
  i {
    font-family: relatt-embed-icomoon;
  }
}
</style>
