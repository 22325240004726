var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    LinkPreview: true,
    'flex-row': true,
    LinkPreview__video__content: _vm.videoUrl,
    small: _vm.isGrid
  }},[_c('a',{staticClass:"LinkPreview__details",attrs:{"href":_vm.data.link,"target":"_blank"}},[_c('div',{staticClass:"LinkPreview__details__source"},[(
          _vm.data.icon.includes('icon-file') ||
            _vm.data.icon === 'icon-globe' ||
            !_vm.data.icon
        )?_c('i',{staticClass:"LinkPreview__details__source--icon",class:_vm.data.icon ? _vm.data.icon : 'icon-file'}):_c('responsive-img',{staticClass:"LinkPreview__details__source--icon",attrs:{"src":_vm.data.icon,"size":"ico","entitySlugId":"websites"}}),_c('h5',{staticClass:"LinkPreview__details__source--name"},[_vm._v(" "+_vm._s(_vm.data.display_link)+" ")])],1),_c('h4',{staticClass:"LinkPreview__details__title"},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('p',[_vm._v(_vm._s(_vm.data.description))]),(_vm.preview && _vm.preview.attachmentHasMeta)?_c('p',[(!_vm.data.display_link)?_c('i',{staticClass:"icon",class:'icon-' + _vm.fileContentType.icon}):_vm._e(),(!_vm.data.display_link)?_c('span',[_vm._v(" "+_vm._s(_vm.fileContentType.description))]):_vm._e(),(!_vm.data.display_link)?_c('span',[_vm._v(" • ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.formatBytes(_vm.preview.attachmentMeta.size))+" ")]),(_vm.preview.attachmentMeta.pages_count)?_c('span',[_vm._v(" • ")]):_vm._e(),(_vm.preview.attachmentMeta.pages_count)?_c('span',[_vm._v(" "+_vm._s(_vm.preview.attachmentMeta.pages_count)+" pages ")]):_vm._e()]):_vm._e()]),_c('generic-media-display',{attrs:{"entitySlugId":_vm.data.entitySlugId,"visibility":_vm.data.visibility,"image":_vm.data.image,"image_fit_contain":_vm.data.image_fit_contain,"image_background":_vm.data.image_background,"icon":_vm.data.icon,"installedContentType":_vm.data.installedContentType,"link":_vm.data.link,"size":_vm.size,"isFile":_vm.data.hasFileAttachments}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }