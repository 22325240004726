<template>
  <div>
    <!-- Loading -->
    <div v-if="posts_data_loading">
      <loader class="m-auto mt-5"></loader>
    </div>

    <div v-else class="space-activity">
      <!--
        -- GENERAL FEED
      ---------------------------------->

      <div class="space-activity__activity">
        <div class="space-activity__section-title">
          <h5 class="section-title">Flux de contenu général</h5>
        </div>
        <!-- Quick post form -->

        <!-- Post list -->
        <post-list
          :posts="posts"
          :showNotification="true"
          :showIsAlsoRelatedBy="true"
          :currentFolderTtuid="null"
          :loading="posts_data_loading"
          :endReached="endFetchMorePosts"
          @onEndReach="getPostsFromSpace_fetchMore"
          @postListNeedToRefresh="refetchingPostsFromFolderQuery"
        ></post-list>
      </div>
    </div>
  </div>
</template>

<script>
import PostList from "@/components/Post/Emb_PostList"
import GET_SPACE_ACTIVITY_POSTS from "@/graphql/Space/Emb_getSpaceActivityPosts.graphql"
import Loader from "@/components/Other/Emb_Loader"

export default {
  name: "Space",
  props: {
    spaceSlug: {
      type: String,
    },
    spaceTtuid: {
      type: String,
    }
  },
  components: {
    PostList,
    Loader
  },
  data() {
    return {
      /*
       * Custom Apollo loading names
       * */
      posts_data_loading: 0,

      /*
       * Posts from folder
       * */
      posts: [],

      /*
       * Page to fetch
       * */
      pageToFetch: 1,

      /*
       * Nbr of posts to fetch per step
       * */
      pageSize: 10,

      /*
       * If no more posts to fetch
       * */
      infiniteEndReached: false
    }
  },
  computed: {
    /*
     * If no more posts to fetch
     * */
    endFetchMorePosts() {
      return this.infiniteEndReached
    }
  },
  methods: {
    /*
     * Reset pagination (When changes are happening on: folder, Sort by)
     * */
    resetPaginationData() {
      this.pageToFetch = 1
    },

    /*
     * To refetch list when changes should impact post result list
     * */
    refetchingPostsFromFolderQuery() {
      this.$apollo.queries.getSpaceActivityPosts.refetch()
    },
    /* ----------------------
     * Load more posts - fired by infinite scroll
     * */
    getPostsFromSpace_fetchMore() {
      this.pageToFetch++

      this.$apollo.queries.getSpaceActivityPosts.fetchMore({
        variables: {
          input: {
            spaceSlug: this.spaceSlug,
            spaceTtuid: this.spaceTtuid,
            first: this.pageSize,
            orderBy: "created_at_desc",
            offset: this.pageSize * (this.pageToFetch - 1)
          }
        },

        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newPosts = fetchMoreResult.getSpaceActivityPosts
          this.posts = [...this.posts, ...newPosts]

          /*
           * Tell user the end of the content list had been reached
           * */
          if (
            fetchMoreResult.getSpaceActivityPosts.length === 0 ||
            fetchMoreResult.getSpaceActivityPosts.length < 10
          )
            this.infiniteEndReached = true
        }
      })
    }
  },

  /* ----------------------
   * Apollo queries
   * */
  apollo: {
    /* ------
     * Get all Posts from current Space to build the home main feed
     * */
    getSpaceActivityPosts: {
      query: GET_SPACE_ACTIVITY_POSTS,
      fetchPolicy: "network-only",
      loadingKey: "posts_data_loading",
      variables() {
        return {
          input: {
            spaceSlug: this.spaceSlug,
            spaceTtuid: this.spaceTtuid,
            offset: 0,
            first: this.pageSize,
            orderBy: "created_at_desc"
          }
        }
      },
      update(data) {
        this.posts = data.getSpaceActivityPosts
      },
      error: error =>
        console.log(
          " ---->  ViewSpaceActivity > GET_SPACE_ACTIVITY_POSTS > ",
          error
        )
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/_variables";

#app_relatt_embed {
  /**
   * ViewSpaceActivity
   * Include:
   *  - a liste of featured post
   *  - a list of last post activity
   **/
  .space-activity {
    .space-activity__section-title {
      margin: 25px 020px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      .options-dropdown-menu {
        margin-left: 20px;
        padding-bottom: 3px;
      }
      > div {
        margin-left: auto;
      }
    }

    .space-activity__activity {
      .space-activity__section-title {
        padding-bottom: 8px;
      }
    }
  }
}
</style>
