<template>
  <a
    :href="`${$relattUrl}/${space.slug}`"
    target="_blank"
    class="card-identity space"
    :class="[{ 'gray-scale': grayScale }, cardSize]"
    :style="'border-left-color:' + space.color"
  >
    <space-avatar
      class="card-identity__avatar"
      size="xs"
      v-if="space.logo"
      :src="space.logo"
      :alt="space.name"
      :entitySlugId="space.ttuid"
      :responsive_class="'avatar-' + cardSize"
      :visibilitySpace="space.visibility"
    />
    <div class="detail">
      <h4 class="name">{{ space.name }}</h4>
      <p v-if="showSlug" class="slug">@{{ space.slug }}</p>
      <p v-else-if="showAbout" class="slug">
        <a :href="`${$relattUrl}/${space.slug}`">A propos</a>
      </p>
    </div>
  </a>
</template>

<script>
import SpaceAvatar from "@/components/Space/Emb_SpaceAvatar"
/**
 * @TODO : Make link optional and replace router-link component tag with div
 * Shows space data in a media object bootstrap format..
 */
export default {
  name: "UserSpaceElement",
  components: {
    SpaceAvatar
  },
  props: {
    /**
     * Current space/
     */
    space: {
      default: function() {
        return {
          name: "My personal space",
          slug: "my_personal_space"
        }
      }
    },
    /**
     * Whether component should be rendered in gray scale.
     */
    grayScale: {
      default: false
    },

    /**
     * Size
     * @type {String}
     */
    cardSize: { default: "md" },

    /**
     * if true disable link
     */
    noLink: { type: Boolean, default: false },
    /**
     * Option to show slug
     */
    showSlug: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Option to show about link
     */
    showAbout: {
      type: Boolean,
      default: false,
      required: false
    }
  }
}
</script>

<style lang="scss"></style>
