<template>
  <div>
    <div class="relatt_embed_tabs" :class="$mq">
      <div
        class="relatt_embed_tab"
        :class="{ active: index == activetab }"
        v-for="(tag, index) in tags"
        @click="loadSpaces(tag.ttuid), (activetab = index)"
        :key="tag.ttuid"
      >
        {{ tag.name }}
      </div>
    </div>
    <div v-if="posts_data_loading">
      <loader class="m-auto mt-5"></loader>
    </div>
    <div v-else class="relatt_embed_tab-content">
      <span v-if="!posts.length" class="empty_list">Aucun élément</span>
      <div v-else class="card-displayer" :class="$mq">
        <component
          :is="
            spaceComponent == 'CARD'
              ? $mq !== 'mobile'
                ? 'SpaceCard'
                : 'SpaceTile'
              : spaceComponent == 'LOGO_ONLY'
              ? 'SpaceLogoOnly'
              : 'SpaceTile'
          "
          v-for="post in posts"
          :key="post.sourceOriginal.publisher.ttuid"
          :space="post.sourceOriginal.publisher"
          :space-title-style="spaceTitleStyle"
          :space-description-style="spaceDescriptionStyle"
          :show-logo="
            !hideLogo || (spaceComponent == 'CARD' && $mq == 'mobile')
          "
          :more-space-fields="moreSpaceFields"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ResponsiveImg from "@/components/Img/Emb_ResponsiveImg"
import GET_FILTER_BY_TTUID from "@/graphql/Folder/Emb_getFilter.graphql"
import GET_POST_FROM_CURRENT_FOLDER_WITH_CUSTOM_FILTERS from "@/graphql/Post/Emb_getPostsFromFolderWithFilters.graphql"
import SpaceTile from "@/components/Space/SpaceTile"
import SpaceCard from "@/components/Space/SpaceCard"
import SpaceLogoOnly from "@/components/Space/SpaceLogoOnly"
import Loader from "@/components/Other/Emb_Loader"

export default {
  name: "SpaceTabs",
  components: {
    ResponsiveImg,
    SpaceTile,
    Loader,
    SpaceCard,
    SpaceLogoOnly
  },
  props: {
    filterTtuid: {
      type: String,
      default: ""
    },
    folderSlug: {
      type: String,
      default: null
    },
    spaceSlug: {
      type: String,
      required: true
    },
    spaceComponent: {
      type: String,
      default: "Card"
    },
    /**
     * Style of space title
     */
    spaceTitleStyle: {
      type: Object
    },
    /**
     * Style of space description
     */
    spaceDescriptionStyle: {
      type: Object
    },
    hideLogo: {
      type: Boolean,
      default: false
    },
    moreSpaceFields: {
      type: Array
    },
    tagTtuid: {
      type: String,
      default: ""
    },
    filterTabTtuid: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activetab: 0,
      tags: [],
      posts: [],
      posts_data_loading: true
    }
  },
  apollo: {
    GetFilterByttuid: {
      query: GET_FILTER_BY_TTUID,
      variables() {
        return {
          ttuid: this.filterTtuid
        }
      },
      update(data) {
        if (data.GetSpaceInstalledFilter) {
          this.tags = data.GetSpaceInstalledFilter.filterVersion.tags
        }
      }
    },

    /* ------
     * Get Posts from current Folder
     * */
    getPostsFromFolder: {
      query: GET_POST_FROM_CURRENT_FOLDER_WITH_CUSTOM_FILTERS,
      fetchPolicy: "network-only",
      loadingKey: "posts_data_loading",
      variables() {
        return {
          input: {
            spaceSlug: this.spaceSlug,
            folderSlug: this.folderSlug,
            tags:
              this.tagTtuid && this.filterTabTtuid
                ? [
                    {
                      ttuid: this.tags[0].ttuid,
                      filter_ttuid: this.filterTtuid
                    },
                    { ttuid: this.tagTtuid, filter_ttuid: this.filterTabTtuid }
                  ]
                : [
                    {
                      ttuid: this.tags[0].ttuid,
                      filter_ttuid: this.filterTtuid
                    }
                  ],
            contentType: "ALL",
            offset: 0,
            first: 50,
            orderBy: "published_at"
          }
        }
      },
      update(data) {
        this.posts = []
        this.posts = data.getPostsFromFolderWithFilters.posts
        this.posts_data_loading = false
      },
      skip() {
        //console.log(tags.length);
        return !this.tags.length
      }
    }
  },
  methods: {
    async loadSpaces(tagTtuid) {
      this.posts_data_loading = true
      this.$apollo.queries.getPostsFromFolder.skip = false
      await this.$apollo.queries.getPostsFromFolder.refetch({
        input: {
          spaceSlug: this.spaceSlug,
          folderSlug: this.folderSlug,
          tags:
            this.tagTtuid && this.filterTabTtuid
              ? [
                  { ttuid: tagTtuid, filter_ttuid: this.filterTtuid },
                  { ttuid: this.tagTtuid, filter_ttuid: this.filterTabTtuid }
                ]
              : [{ ttuid: tagTtuid, filter_ttuid: this.filterTtuid }],
          contentType: "ALL",
          offset: 0,
          first: 50,
          orderBy: "published_at"
        }
      })
      this.$apollo.queries.getPostsFromFolder.skip = true
      this.posts_data_loading = false
    }
  }
}
</script>

<style lang="scss">
#app_relatt_embed {
  .relatt_embed_tabs {
    padding-top: 10px;
    line-height: 2.1;
    border-bottom: 1px solid #50e3c2;

    .relatt_embed_tab {
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      line-height: 1.1;
      display: inline-block;
    }

    .relatt_embed_tab:hover {
      cursor: pointer;
      background-color: whitesmoke;
      border-radius: 5px 5px 0px 0px;
    }

    .active {
      background-color: #50e3c2;
      border-radius: 5px 5px 0px 0px;
      border-bottom: 2px solid #50e3c2;
    }
  }

  .relatt_embed_tab-content {
    padding-top: 10px;
  }
}
</style>
