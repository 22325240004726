<template>
  <div class="ContentTypeIconLabel" :class="[size, variant]">
    <i :class="'icon-' + ct.icon"></i>
    <div class="ContentTypeIconLabel--label">{{ ct.title }}</div>
    <slot name="after" />
  </div>
</template>

<script>
export default {
  name: "ContentTypeIconLabel",
  props: {
    contentType: {
      required: true
    },
    size: {
      type: String,
      default: "default",
      required: false
    }
  },
  computed: {
    ct() {
      return this.contentType
        ? this.contentType
        : {
            icon: "loader",
            title: "Modèle de contenu",
            content_type_key: "CONTENT_TYPE_TEMPLATE"
          };
    },
    variant() {
      return "content-type-" + this.contentType.content_type_key.toLowerCase();
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/_variables";

#app_relatt_embed {
  /**
        *  Content type icon & text
        * - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .ContentTypeIconLabel {
    color: $primary;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 15px;
    margin-bottom: 12px;

    > div {
      padding: 3px 0 0 3px;
    }
    .ContentTypeIconLabel--label {
      text-transform: uppercase;
      font-size: 14px;
    }

    > i {
      margin-right: 3px;
      font-size:22px;
      color: var(--ct-color);
    }
    &.large {
      font-size:20px;
      > i {
        font-size:27px;
      }
    }
    /**
   * Content types colors variants
   */
    &.content-type-link {
      color: $content-type-link;
      > i {
        color: $content-type-link;
      }
    }
    &.content-type-publication {
      color: $content-type-publication;
      > i {
        color: $content-type-publication;
      }
    }
    &.content-type-event {
      color: $content-type-event;
      > i {
        color: $content-type-event;
      }
    }
    &.content-type-file {
      color: $content-type-file;
      > i {
        color: $content-type-file;
      }
    }
    &.content-type-documentary_reference {
      color: $content-type-documentary-reference;
      > i {
        color: $content-type-documentary-reference;
      }
    }
  }

  /**
 * Left hover color
 */
  .content-type-link {
    &:hover {
      border-left-color: $content-type-link;
    }
  }
  .content-type-publication {
    &:hover {
      border-left-color: $content-type-publication;
    }
  }
  .content-type-event {
    &:hover {
      border-left-color: $content-type-event;
    }
  }
  .content-type-file {
    &:hover {
      border-left-color: $content-type-file;
    }
  }
  .content-type-documentary_reference {
    &:hover {
      border-left-color: $content-type-documentary-reference;
    }
  }

  // On QuickPostForm, the left color alwayse colored
  .QuickPostForm {
    &.content-type-link {
      border-left-color: $content-type-link;
    }
    &.content-type-publication {
      border-left-color: $content-type-publication;
    }
    &.content-type-file {
      border-left-color: $content-type-file;
    }
    &.content-type-documentary_reference {
      border-left-color: $content-type-documentary-reference;
    }
  }
  // Special type to replace image with content type label
  .contentTypeInsteadOfImage {
    height: 100%;
    background-color: rgba($primary, 0.85);
    opacity: 0.25;
    border-radius: 0px;
    display: flex;
    align-items: center;

    .ContentTypeIconLabel {
      flex-direction: column;
      width: 100%;
      margin: 0;
      > i {
        font-size: 40px;
        color: #fff;
      }
    }
    &.medium {
      .ContentTypeIconLabel {
        > i {
          font-size: 130px;
        }
      }
    }
    &.small {
      .ContentTypeIconLabel {
        min-width:50px;
        padding: 10px 0;
        > i {
          font-size: 30px;
        }
      }
    }
    .ContentTypeIconLabel--label {
      color: #ccc;
      display: none;
    }
  }
}
</style>
