var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('generic-pagination',{class:_vm.$mq,attrs:{"endReached":_vm.endReached,"is-loading":_vm.loading,"empty-list":!_vm.posts.length,"is-grid":_vm.isGrid,"all-contents":_vm.allContents,"loadBtnText":_vm.isNetwork ? 'Charger plus' : 'Charger plus de contenu...'},on:{"loadMore":_vm.$_loadMorePosts}},[(_vm.posts && !_vm.isNetwork)?_vm._l((_vm.posts),function(post){return _c('post-tile',{key:post.ttuid,attrs:{"postTtuid":_vm.shouldSinglePostFetch ? post.ttuid : null,"post":_vm.shouldSinglePostFetch ? null : post,"currentFolderTtuid":_vm.currentFolderTtuid,"isFeaturedInCurrentFolder":_vm.currentFolderFeaturedPostsTtuid.includes(post.ttuid),"homeTtuid":_vm.homeTtuid,"isFeaturedInHomeFolder":_vm.homeFeaturedPostsTtuid.includes(post.ttuid),"size":_vm.isGrid ? 'small' : _vm.size,"isGrid":_vm.isGrid,"minimalSocialButtonVariant":_vm.isGrid,"showNotification":_vm.showNotification && !_vm.isGrid,"showIsAlsoRelatedBy":_vm.showIsAlsoRelatedBy,"variant":_vm.folderView == 'GRID' ? 'imageTop' : 'imageRight'},on:{"refreshList":_vm.refreshPostList}})}):_vm._e(),(_vm.posts && _vm.isNetwork)?[_c('div',{class:_vm.spaceComponent == 'CARD' || _vm.spaceComponent == 'LOGO_ONLY'
          ? ("card-displayer " + _vm.$mq)
          : ''},_vm._l((_vm.posts),function(post){return _c(_vm.spaceComponent == 'CARD'
            ? _vm.$mq !== 'mobile'
              ? 'SpaceCard'
              : 'SpaceTile'
            : _vm.spaceComponent == 'LOGO_ONLY'
            ? 'SpaceLogoOnly'
            : 'SpaceTile',{key:post.sourceOriginal
            ? post.sourceOriginal.publisher.ttuid
            : post.publisher.ttuid,tag:"component",attrs:{"moreSpaceFields":_vm.moreSpaceFields,"space":post.sourceOriginal
            ? post.sourceOriginal.publisher
            : post.publisher,"show-logo":true && _vm.spaceComponent != 'SAMPLE_TILE'}})}),1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }