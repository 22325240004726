/**
 * Strips tags from the give the text and perform a truncation from {start} to {end} and concatenate
 * its with
 * @param {string} str String to work on.
 * @param {number} end Where to stop truncation
 * @param {string} ending text appended to the current str after truncation.
 * @param {boolean} strip_tags whether tags should be stripped or not.
 * @return {String}
 */

export const textTruncate = (str, end, ending, strip_tags = false) => {
  if (strip_tags) {
    str = str.replace(/(<([^>]+)>)/gi, "")
  }
  if (end == null) {
    end = 100
  }
  if (ending == null) {
    ending = "..."
  }
  if (str.length > end) {
    return str.substring(0, end - ending.length) + ending
  } else {
    return str
  }
}

/**
 * Clean an URL to make it more readable {url}
 * @param {string} url String with the url to clean.
 * @return {String}
 */
export const urlCleaner = url => {
  return url.replace(/(http|https)(:\/\/)/, "").replace(/\/$/, "")
}

/**
 * formulate url to image store
 */
 export const getImageURL = ({
  imageName,
  size,
  webP = false,
  entityFolderSlug,
  visibility = "PUBLIC",
}) => {
  if (size == "o") webP = false
  let nameWithoutFormat = imageName.includes(".")
    ? imageName.slice(0, imageName.lastIndexOf("."))
    : imageName

  //Remove starting slash to avoid double slash in the output path.
  nameWithoutFormat = /^\//.test(nameWithoutFormat)
    ? nameWithoutFormat.slice(1)
    : nameWithoutFormat

  // image compression format
  let format = imageName.includes(".") ? imageName.split(".").pop() : "png"
  format = format.includes("?") ? format.split("?")[0] : format
  let params = imageName.includes("?") ? imageName.split("?")[1] : ""

  if (entityFolderSlug === "bots") size = ""
  else if (size === "p" || size === "placeholder") {
    format = "jpeg"
    size = "p"
  } else if (
    format === "ico" ||
    entityFolderSlug === "websites" ||
    entityFolderSlug === "linkDataTmp"
  ) {
    size = ""
  } else if (webP && format != "gif") {
    format = "webp"
  } else if (["bmp", "jpg", "svg", "JPG"].includes(format)) {
    format = "jpeg"
  } else if (format === "blob" && size != "o") {
    format = "png"
  } else if (format === "gif") {
    size = ""
  }

  format = "." + format

  // image size variant
  let imageSized = size
    ? nameWithoutFormat + "_" + size + format
    : nameWithoutFormat + format
  let urlPrefix =
    entityFolderSlug === "links" || visibility === "PUBLIC"
      ? "images"
      : "private"
  return `/${urlPrefix}/${entityFolderSlug}/${imageSized}${
    params ? "?" + params : ""
  }`
}

export const filenameTruncate = (filename, length) => {
  let extensionLength = filename.split(".").pop().length

  if (filename.length <= length + 6 + extensionLength) return filename

  let fileNameSuffixStart = filename.length - 4 - extensionLength
  let truncatedFileName = textTruncate(filename, length, null, false)
  return (
    truncatedFileName + filename.substring(fileNameSuffixStart, filename.length)
  )
}

export const getPath = ({
  src_temp,
  src_init,
  entity_slug_id,
  size,
  visibility,
  webpIsSupported
}) => {
  let src = src_temp ? src_temp : src_init;
  let placeholder = ""
  let hasPlaceholder

  if (new RegExp("^static/").test(src)) {
    return `/${src}`
  }

  if (new RegExp("^/static/").test(src)) {
    return `${src}`
  }

  if (!new RegExp("^data|http://|https://").test(src_init)) {
    if (entity_slug_id) {
      src = getImageURL({
        imageName: src_temp,
        size: size,
        webP: webpIsSupported,
        entityFolderSlug: entity_slug_id,
        visibility: visibility
      })

      placeholder =
        entity_slug_id === "websites" || entity_slug_id === "linkDataTmp"
          ? ""
          : getImageURL({
              imageName: src_temp,
              size: "placeholder",
              webP: false,
              entityFolderSlug: entity_slug_id,
              visibility: visibility
            })

      if (
        entity_slug_id === "websites" ||
        entity_slug_id === "bots" ||
        entity_slug_id === "linkDataTmp"
      )
        [
          src.slice(0, src.lastIndexOf("/") + 1),
          src_temp.includes(".")
            ? src_temp.slice(0, src_init.lastIndexOf("."))
            : src_temp,
          `_p.jpeg`
        ].join("")

      if (placeholder) hasPlaceholder = true
    }
  }

  return {
    src,
    loading: placeholder,
    hasPlaceholder
  }
}
