<template>
  <div class="responsive_image_container" :class="responsive_class">
    <div class="responsive_image" v-lazy:background-image="path"></div>
  </div>
</template>

<script>
import { getPath } from "@/helpers/app"
/**
 * SEO friendly responsive image component.
 */
export default {
  name: "ImageResponsive",
  props: {
    /**
     * Image source attribute
     */
    src: {
      type: String,
      required: true
    },
    /**
     * Define if we're in presence of private content (PRIVATE or PUBLIC)
     */
    visibility: {
      type: String,
      required: false,
      default: "PUBLIC"
    },
    /**
     * Image alternative text
     */
    alt: {},
    /**
     * Provides width and height for the image container through different breakpoints.
     */
    responsive_class: {},
    /**
     * Display background-size in `contain` mode instead of cover.
     */
    contain: {
      type: String,
      default: ""
    },
    size: {
      default: "S",
      type: String
    },
    /**
     * Tile variant (imageRight, imageLeft or imageTop)
     * */
    variant: {
      type: String,
      default: "imageRight",
      required: false
    },
    entitySlugId: {
      type: String
    },
    error: {},

    /**
     * If parent compenent need to handle error on image
     */
    handleError: {
      default: false
    },

    background: {
      type: String,
      default: "white"
    },
    displayImg: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function
    }
  },
  data() {
    return {
      text: "",
      hasPlaceholder: false,
      dark_background_color: false,
      src_temp: this.src
    }
  },
  methods: {
    showPostContentTypeImage() {
      this.postContentTypeImage = true
    },
    handleClick() {
      if (this.onClick) {
        this.onClick()
      }
    }
  },
  computed: {
    absolutePath() {
      return new RegExp("^data|http://|https://").test(this.src)
    },
    /**
     * Format src based on its value.
     */
    path() {
      if (!this.absolutePath) {
        let res = getPath({
          src_temp: this.src_temp,
          src_init: this.src,
          entity_slug_id: this.entitySlugId,
          size: this.size,
          visibility: this.visibility,
          webpIsSupported: this.$webpIsSupported
        })

        if (res.hasPlaceholder) this.hasPlaceholder = true

        return {
          src: res.src ? `${process.env.VUE_APP_ASSETS_URL}${res.src}` : "",
          loading: res.loading
            ? `${process.env.VUE_APP_ASSETS_URL}${res.loading}`
            : "",
          error: this.error
        }
      } else {
        return `${this.src}`
      }
    },

    isContain() {
      return this.contain === "Contain"
    },

    isAuto() {
      //Sometimes null values are being passed as prop, here we restore defult value
      let size = this.size ? this.size.toUpperCase() : "S"

      return this.contain === size
    }
  },
  watch: {
    src: function() {
      this.src_temp = this.src
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/_variables";

#app_relatt_embed {
  /* Responsive image container */
  .responsive_image_container {
    position: relative;
    overflow: hidden;
    // background-color: $gray-100;
    img {
      display: none;
    }
    .responsive_image {
      background: 50% 50% no-repeat;
      background-size: cover;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
  /**
  * Image
  */
  img[lazy="loading"] {
  }
  img[lazy="error"] {
  }
  img[lazy="loaded"] {
  }
  /**
  * Background-image
  */
  .responsive_image[lazy="loading"] {
    opacity: 0.4;
  }
  .responsive_image[lazy="error"] {
    background-size: contain;
  }
  .responsive_image[lazy="loaded"] {
  }
}
</style>
