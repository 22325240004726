<template>
  <div
    :class="{
      LinkPreview: true,
      'flex-row': true,
      LinkPreview__video__content: videoUrl,
      small: isGrid
    }"
  >
    <a :href="data.link" target="_blank" class="LinkPreview__details">
      <div class="LinkPreview__details__source">
        <i
          v-if="
            data.icon.includes('icon-file') ||
              data.icon === 'icon-globe' ||
              !data.icon
          "
          :class="data.icon ? data.icon : 'icon-file'"
          class="LinkPreview__details__source--icon"
        ></i>
        <!-- Favicon -->
        <responsive-img
          class="LinkPreview__details__source--icon"
          v-else
          :src="data.icon"
          size="ico"
          entitySlugId="websites"
        ></responsive-img>

        <h5 class="LinkPreview__details__source--name">
          {{ data.display_link }}
        </h5>
      </div>
      <h4 class="LinkPreview__details__title">
        {{ data.title }}
      </h4>
      <p>{{ data.description }}</p>
      <p v-if="preview && preview.attachmentHasMeta">
        <i
          v-if="!data.display_link"
          class="icon"
          :class="'icon-' + fileContentType.icon"
        />
        <span v-if="!data.display_link">
          {{ fileContentType.description }}</span
        >
        <span v-if="!data.display_link"> • </span>
        <span> {{ formatBytes(preview.attachmentMeta.size) }} </span>
        <span v-if="preview.attachmentMeta.pages_count"> • </span>
        <span v-if="preview.attachmentMeta.pages_count">
          {{ preview.attachmentMeta.pages_count }} pages
        </span>
      </p>
    </a>

    <!-- MediaDisplay -->
    <generic-media-display
      :entitySlugId="data.entitySlugId"
      :visibility="data.visibility"
      :image="data.image"
      :image_fit_contain="data.image_fit_contain"
      :image_background="data.image_background"
      :icon="data.icon"
      :installedContentType="data.installedContentType"
      :link="data.link"
      :size="size"
      :isFile="data.hasFileAttachments"
    />
  </div>
</template>

<script>
import ResponsiveImg from "@/components/Img/Emb_ResponsiveImg"
import GenericMediaDisplay from "@/components/Other/Emb_GenericMediaDisplay"
import formatBytes from "@/helpers/app/formatBytes"
import { describeAcceptedFile } from "@/helpers/app/acceptedFileDecriptor"
import { urlCleaner } from "@/helpers/app"
/**
 * Represents single item post list.
 */
export default {
  name: "LinkPreview",
  components: {
    ResponsiveImg,
    GenericMediaDisplay
  },
  data() {
    return {
      data: {
        title: "",
        link: "",
        image: "",
        icon: "",
        description: "",
        color: "",
        contentTypeIcon: "",
        display_link: "",
        image_cached_ext: "",
        image_fit_contain: "",
        image_background: "",
        installedContentType: {},
        hasFileAttachments: false
      },
      videoUrl: false,
      embedUrl: ""
    }
  },
  props: {
    /**
     * Preview object to render a link from.
     */
    preview: {
      type: Object,
      required: false,
      default() {
        return {}
      }
    },
    /**
     * Post object to render a link from.
     */
    post: {},
    /**
     * Individual properties for custom preview purpose.
     */
    icon: {},
    link: {},
    display_link: {},
    title: {},
    description: {},
    image: {},
    publisherTtuid: {},
    /**
     * Size variants
     * */
    size: { default: "normal" },
    isGrid: { type: Boolean, default: false },
    isAttachment: { type: Boolean, default: false }
  },
  created() {
    this._processPreviewData()
  },
  methods: {
    formatBytes(bytes) {
      if (this.preview.attachmentHasMeta) return formatBytes(bytes)
      else return null
    },
    _processPreviewData() {
      /**
       * Set preview data - Take in priority individuals properties - else `this.preview` one
       */
      this.data.link = this.link
        ? this.link
        : this.preview
        ? this.preview.link
        : null

      this.data.display_link = this.display_link
        ? this.display_link
        : this.preview
        ? this.preview.display_link
        : this.data.link
      this.data.display_link = urlCleaner(this.data.display_link)

      this.data.title = this.title
        ? this.title
        : this.preview
        ? this.preview.title
        : null
      this.data.icon = this.icon
        ? this.icon
        : this.preview
        ? this.preview.icon
        : this.isLink
        ? "icon-globe"
        : "icon-file"

      this.data.description = this.description
        ? this.description
        : this.preview
        ? this.preview.description
        : null

      this.data.image = this.image
        ? this.image
        : this.preview
        ? this.preview.image
        : null

      this.data.image_cached_ext = this.image_cached_ext
        ? this.image_cached_ext
        : this.preview
        ? this.preview.image_cached_ext
        : null

      this.data.color = this.color
        ? this.color
        : this.preview
        ? this.preview.color
        : "#f4f4f4"

      this.data.image_fit_contain = this.image_fit_contain
        ? this.image_fit_contain
        : this.preview
        ? this.preview.image_fit_contain
        : ""

      this.data.image_background = this.image_background
        ? this.image_background
        : this.preview
        ? this.preview.image_background
        : ""

      this.data.entitySlugId = this.entitySlugId
        ? this.entitySlugId
        : this.preview
        ? this.preview.entitySlugId
        : ""

      this.data.visibility =
        this.preview && this.preview.visibility
          ? this.preview.visibility
          : this.visibility

      this.data.has_augmented_version =
        (this.preview && this.preview.has_augmented_version) || false

      this.data.hasAugmentedTitle =
        (this.preview && this.preview.hasAugmentedTitle) || false

      this.data.hasFileAttachments =
        (this.preview && this.preview.hasFileAttachments) || false

      if (this.preview && this.preview.installedContentType) {
        this.data.installedContentType = this.preview.installedContentType
        if (
          this.data.installedContentType.content_type_key == "FILE" ||
          this.isAttachment ||
          (this.preview && this.preview.attachmentHasMeta)
        ) {
          this.data.link = `${process.env.VUE_APP_ASSETS_URL}${this.data.link}`
        }
      }

      if (!this.data.image) {
        this.setContentTypeIcon()
      }
    },
    setContentTypeIcon() {
      if (
        this.isLink &&
        ["html", "htm", "php", "asp"].includes(this.data.link.split(".").pop())
      ) {
        this.data.contentTypeIcon = `icon-external-link`
      } else if (this.data.link) {
        let file = this.data.link.replace(/^.*[\\\/]/, "")
        let fileDecribed = describeAcceptedFile(file)

        if (fileDecribed)
          this.data.contentTypeIcon = `icon-${fileDecribed.icon}`

        if (
          !fileDecribed &&
          (this.data.link.startsWith("http://") ||
            this.data.link.startsWith("https://"))
        )
          this.data.contentTypeIcon = `icon-external-link`
      }
    },
    /**
     * Cheking if the given url is for video content or not
     */
    parseVideo(url) {
      let regEx = /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com)|dailymotion.com)\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/

      if (url.match(regEx)) this.videoUrl = true

      //Build the embeded url based on diferent platform
      if (RegExp.$3.indexOf("youtu") > -1) {
        this.embedUrl = "https://www.youtube.com/embed/" + RegExp.$6
      } else if (RegExp.$3.indexOf("vimeo") > -1) {
        this.embedUrl = "https://player.vimeo.com/video/" + RegExp.$6
      } else if (RegExp.$3.indexOf("dailymotion") > -1) {
        this.embedUrl = "https://www.dailymotion.com/embed/video/" + RegExp.$6
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/_variables";

#app_relatt_embed {
  /**
        *  Post link preview
        * - - - - - - - - - - - - - - - - - - - - - - - - - - */

  .LinkPreview {
    background: #fcfcfc;
    border: 1px solid #ececec;
    padding: 0;
    border-radius: 1.6rem;
    width: 100%;
    margin: 1rem 0;
    overflow: hidden;
    position: relative;

    .LinkPreview__video__content {
      height: 20rem;
    }

    .LinkPreview__novideo__content {
      height: 16rem;
    }

    /* Image */
    .LinkPreview__image {
      width: 25%;
      position: absolute;
      right: 0;
      height: 100%;
    }

    /* Contents */
    .LinkPreview__details {
      width: 75%;
      padding: 1.3rem 0 1.3rem 1.6rem;

      .LinkPreview__details__source {
        display: flex;
        align-items: center;

        /* Icon */
        .LinkPreview__details__source--icon {
          color: $gray-500;
          width: 1.6rem;
          height: 1.6rem;
          margin-right: 0.7rem;
        }
        /* Link */
        .LinkPreview__details__source--name {
          color: $gray-600;
          font-weight: 400;
          font-size: 1.6rem;
          width: 90%;
          margin: 0;
          overflow: hidden;
          white-space: nowrap;
          position: relative;
        }
      }
      /* Main title */
      .LinkPreview__details__title {
        font-size: 3rem;
        color: $gray-800;
        font-weight: 500;
        position: relative;
        margin: 0.7rem 0;
        line-height: 1;
      }
      /* Excerpt */
      p {
        color: $gray-600;
        font-size: 1.4rem;
      }
    }

    &.x-small,
    &.has_augmented_version {
      min-height: 6rem;

      /* Image */
      .LinkPreview__image {
        min-height: 6rem;
      }

      /* Contents */
      .LinkPreview__details {
        max-height: 12rem;
        padding: 0.6rem 0 0.4rem 0.4rem;

        /* Title */
        .LinkPreview__details__title {
          font-size: 2.1rem;
        }
        .LinkPreview__details__source {
          /* Icon */
          .LinkPreview__details__source--icon {
            width: 1rem;
            height: 1rem;
            font-size: 1rem;
            margin-right: 0.4rem;
          }
          /* Link */
          .LinkPreview__details__source--name {
            font-size: 1.3rem;
          }
        }

        /* Excerpt */
        p {
        }
      }
    }
    &.has_augmented_version {
      height: 100%;
      min-height: 9rem;
    }

    &.ImageLeft {
      display: flex;
      flex-direction: row-reverse;
      .MediaDisplay {
        width: 10%;
        .LinkPreview__image {
          left: 0;
          width: 10%;
        }
      }
    }

    &.small {
      &.ImageLeft {
        .MediaDisplay {
          width: 20%;
          .LinkPreview__image {
            width: 20%;
          }
        }
      }
      margin: 0.5rem 0;
      min-height: 3.5rem;
      /* Image */
      .LinkPreview__image {
        min-height: 3.5rem;
      }

      /* Contents */
      .LinkPreview__details {
        width: 80%;
        max-height: 12rem;
        padding: 0.6rem 0 0.4rem 0.6rem;

        /* Title */
        .LinkPreview__details__title {
          font-size: 1.5rem;
        }

        .LinkPreview__details__source {
          /* Icon */
          display: none;
          .LinkPreview__details__source--icon {
            width: 1rem;
            height: 1rem;
            font-size: 1.3rem;
            margin-right: 0.4rem;
          }
          /* Link */
          .LinkPreview__details__source--name {
            font-size: 1.3rem;
          }
        }
        p {
          margin: 0;
          font-size: 1.1rem;
        }
      }
      &.has_augmented_version {
        .LinkPreview__details__title {
          font-size: 1.5rem;
        }
      }
    }
  }
  /** ----------
  * Sizes variants
  * */

  /**
 * RESPONSIVE
 * ---------------------------------------------------------------------------
 */

  /**
  * Tablets & Mobile
  * - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .tablet,
  .mobile {
    .LinkPreview {
    }
  }

  /**
  * Mobile
  * - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .mobile {
    .LinkPreview {
      .LinkPreview__details {
        width: 70%;

        .LinkPreview__details__source {
          .LinkPreview__details__source--name {
            font-size: 1rem;
            text-overflow: ellipsis;
          }
        }

        .LinkPreview__details__title {
          font-size: 2.5rem;
        }
      }

      .LinkPreview__image {
        width: 29%;
      }
    }
  }
}
</style>
