/**
 * order an array of filters
 * @param {Array} filters an array of filters to order
 */
export const orderFolderFilters = (filters) => {
  return filters.sort((a, b) => {
    if (a.InstalledFilter && b.InstalledFilter) {
      return a.InstalledFilter.order - b.InstalledFilter.order;
    } else {
      return a.order - b.order;
    }
  });
};