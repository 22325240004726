import GET_FOLDER from "@/graphql/Folder/Emb_getFolder.graphql"
import Vue from "vue"
import GET_POST_FROM_CURRENT_FOLDER_WITH_CUSTOM_FILTERS from "@/graphql/Post/Emb_getPostsFromFolderWithFilters.graphql"
import GET_FOLDER_POST_COUNT from "@/graphql/Folder/Emb_getFolderPostCount.graphql"
import { orderFolderFilters } from "@/helpers/folder"
// ----------------------

export const FolderBaseMixin = {
  props: {
    folderSlug: {
      type: String
    },
    folderTtuid: {
      type: String
    },
    spaceSlug: {
      type: String
    },
    spaceTtuid: {
      type: String
    }
  },
  data() {
    return {
      countPost: 0,
      /*
       * To get apollo's Folder response
       * */
      Folder: {},

      /*
       * To get current folder's Featured posts ttuid
       * */
      currentFolderFeaturedPostsTtuid: [],

      /*
       * Custom Apollo loading for Folder query
       * */
      folder_data_loading: 0,

      /*
       * Posts from folder
       * */
      posts: [],
      posts_data_loading: 0,
      installedContentTypes: [],

      /*
       * Page to fetch
       * */
      pageToFetch: 1,

      /*
       * Default nbr of posts to fetch per step
       * */
      defaultPageSize: 10,

      /*
       * On Page time to fetch only post posted before this thime
       * */
      onPageTime: new Date().toISOString(),

      /*
       * If no more posts to fetch
       * */
      isInfiniteEndReached: false,

      /* ----------------------
       * Sort By
       * */

      /*
       * Current Sort by state
       * */
      sortBy: "published_at",

      /*
       * Text label
       * */
      sortByTextLabel: {
        published_at: "Plus récents",
        title: "Ordre alphabétique des titres",
        created_at: "Ordre d'ajout au dossier"
      },

      /*
       * Dropdown options
       * */
      sortByDropDownOptions: [
        {
          title: "Plus récents",
          action: () => this.handleSortBy("published_at")
        },

        {
          title: "Ordre alphabétique des titres",
          action: () => this.handleSortBy("title")
        },
        {
          title: "Ordre d'ajout au dossier",
          action: () => this.handleSortBy("created_at")
        }
      ],

      /*
       * Current Content type filter
       * */
      contentType: {
        title: "Tous",
        content_type_key: "ALL"
      },

      /*
       * Selected tags from filter
       * */
      selectedTags: {},

      /*
       * 3-dots options for folders
       * */
      optionsForFolders: [
        {
          title: "Paramètres du dossier",
          action: this.openFolderOptionsModal,
          showIf: () =>
            this.$can(
              "EDIT_FOLDERS",
              this.makeSpace({ slug: this.$route.params.slug_space })
            )
        },
        {
          title: "Relater",
          action: this.handleFolderRelate
        },
        {
          title: "Intégrer à un site",
          action: this.openFolderOptionsModal,
          class: "disabled"
        }
      ],
      FolderSpace: null,

      /*
       * Related spaces
       * */
      relatedSpace: { label: "Espace associé", key: "ALL" }
    }
  },
  apollo: {
    /* ------
     * Get current Folder datas (including featured posts ID)
     * */

    getFolder: {
      query: GET_FOLDER,
      loadingKey: "folder_data_loading",
      variables() {
        return {
          spaceSlug: this.spaceSlug,
          folderSlug: this.folderSlug,
          folderTtuid: this.folderTtuid
        }
      },
      skip: false,
      update(data) {
        if (data.getFolder) {
          this.sortBy = data.getFolder.options__default_sort
          this.Folder = data.getFolder

          if (this.Folder.installedFiltersConnection) {
            /* Order filters */
            this.Folder.installedFilters = orderFolderFilters(
              this.Folder.installedFiltersConnection.installedFilters
            )
          }
        }
      },
      error: error =>
        console.log(" ---->  ViewSpaceFolder > GET_FOLDER > ", error)
    },

    /* ------
     * Get current search number of posts
     * */
    getFolderPostCount: {
      query: GET_FOLDER_POST_COUNT,
      variables() {
        return {
          spaceSlug: this.FolderSpace.slug,
          folderSlug: this.Folder.slug,
          contentType: this.queryContentType,
          tags: this.flatingSelectedTagForApolloQuery,
          contentTypeFilters: this.contentTypeFilters?.length ? this.contentTypeFilters: [],
          status: "PUBLISHED_IN"
        }
      },
      skip() {
        return this.folder_data_loading || !this.Folder || !this.FolderSpace
      },
      update(data) {
        if (data.getFolderPostCount) {
          this.countPost = data.getFolderPostCount
        }
      },
      fetchPolicy: "network-only",
      error: error =>
        console.log(" ---->  ViewSpaceFolder > GET_FOLDER_POST_COUNT > ")
    },

    /* ------
     * Get Posts from current Folder
     * */
    getPostsFromFolder: {
      query: GET_POST_FROM_CURRENT_FOLDER_WITH_CUSTOM_FILTERS,
      fetchPolicy: "network-only",
      loadingKey: "posts_data_loading",
      variables() {
        return {
          input: {
            spaceSlug: this.FolderSpace.slug,
            folderSlug: this.Folder.slug,
            contentType: this.queryContentType,
            tags: this.flatingSelectedTagForApolloQuery,
            contentTypeFilters: this.contentTypeFilters?.length ? this.contentTypeFilters: [],
            offset: 0,
            first: this.paginate ? this.pageSize : 0,
            orderBy: this.sortBy
          },
          onPageTime: this.onPageTime,
          publisherMainAddress: true
        }
      },
      update(data) {
        this.isInfiniteEndReached = false
        this.resetPaginationData()
        if (data.getPostsFromFolderWithFilters) {
          this.posts = data.getPostsFromFolderWithFilters.posts
          this.Folder.postsCount = data.getPostsFromFolderWithFilters.total
        } else {
          this.posts = []
          this.Folder.postsCount = 0
        }
      },
      skip() {
        return this.folder_data_loading || !this.Folder || !this.FolderSpace
      },
      error: error =>
        console.log(
          " ---->  ViewSpaceFolder > GET_POST_FROM_CURRENT_FOLDER_WITH_CUSTOM_FILTERS > ",
          error
        )
    }
  },

  methods: {
    /*
     * Reset pagination (When changes are happening on: folder, Sort by)
     * */
    resetPaginationData() {
      this.pageToFetch = 1
      this.posts = []
    },

    /* ------------------
     * Sort By
     * */

    handleSortBy(sortBy) {
      this.pageToFetch = 1

      // Will automatically trigger Apollo query
      this.sortBy = sortBy
    },

    /* ------------------
     * Filter By -> Content Type
     * */
    contentTypesSelectOptions() {
      let contentTypes = this.installedContentTypes.filter(
        item => !["DISCUSSION"].includes(item.content_type_key)
      )
      return [{ title: "Tous", content_type_key: "ALL" }, ...contentTypes]
    },
    contentTypeLabel(option) {
      return option.title
    },

    /* ------------------
     * Filter By -> Custom Filters
     * */

    /*
     * Cancel the given tag from filtering the list
     * */
    cancelFilteringTag(filter_id, tag_id) {
      if (tag_id === undefined) {
        Vue.set(this, "contentType", {
          title: "Type de contenu",
          content_type_key: "ALL"
        })
      } else
        this.selectedTags[filter_id] = this.selectedTags[filter_id].filter(
          item => item.ttuid !== tag_id
        )
    },

    /* ------------------
     * Folder's Parameters Modal
     * */

    /*
     * Open Parameter Modal
     * */
    openFolderOptionsModal() {
      this.showModal({
        componentName: "ModalFolderOptions",
        props: {
          folderId: this.Folder._id,
          refetchingQuery: this.refetchingFolderQuery
        }
      })
    },
    /*
     * Refetch after Parameter option update
     * */
    refetchingFolderQuery() {
      if (this.$apollo.queries.Folder) {
        this.$apollo.queries.Folder.refetch()
      }
    },

    /* ------------------
     * Relate modal
     * */

    handleFolderRelate() {
      this.showModal({
        componentName: "ModalRelattContent",
        props: {
          slugSpaceRelatted: this.spaceSlug,
          slugFolderRelatted: this.folderSlug,
          defaultSourceTags: {},
          isSingleItem: true,
          itemType: "folder",
          itemID: this.Folder.ttuid,
          item: this.Folder
        }
      })
    },
    /*
     * Refetch to see post in result list
     * */
    refetchingPostsFromFolderQuery(params) {
      /**
       * If a post is created refesh the onPageTime to see the new post
       */
      if (params?.postCreated) this.onPageTime = new Date().toISOString()

      this.$apollo.queries.getPostsFromFolder.refetch()
      this.$apollo.queries.getFolderPostCount.refetch()
    }
  },

  computed: {
    // ----------------------
    // Filters By

    // Arrange custom fitlers object sorted by filter ID
    customFilters() {
      if (!this.Folder || !this.Folder.installedFilters) return []
      return this.Folder.installedFilters.map(item => {
        return item.InstalledFilter
      })
      //return []
    },

    /*
     * Nbr of posts to fetch per step
     * */
    pageSize() {
      if (!this.allContents) {
        return this.nbContents
      }
      return !this.Folder && this.Folder.options__grid_layout === "grid"
        ? 9
        : this.defaultPageSize
    },

    // Create a flat list of all selected tags
    flatingSelectedTag() {
      let tags = []
      Object.keys(this.selectedTags).forEach(key => {
        tags = [
          ...tags,
          ...this.selectedTags[key].map(tag => {
            return {
              ...tag,
              filter_id: key
            }
          })
        ]
      })

      // Add `content type` in selected tags list
      if (this.contentType.content_type_key !== "ALL") {
        tags = [
          ...tags,
          {
            name: this.contentType.title,
            filter_id: this.contentType.content_type_key
          }
        ]
      }

      return tags
    },
    queryContentType() {
      if (!this.Folder) {
        return this.contentType.content_type_key
      }

      return this.Folder.options__displayed_content_type === "ALL" ||
        !this.Folder.options__displayed_content_type
        ? this.contentType.content_type_key
        : this.Folder.options__displayed_content_type
    },
    // Create a flat list of all selected tags for query
    flatingSelectedTagForApolloQuery() {
      let tags = []
      this.posts = []

      Object.keys(this.selectedTags).forEach(key => {
        this.selectedTags[key].forEach(tag => {
          tags.push({
            ttuid: tag.ttuid,
            filter_ttuid: key
          })
        })
      })
      return tags
    },

    // ----------------------
    // Sub-folders

    // Sub-folders tree (upcoming feature)
    foldersTree() {
      let f = this.currentSpace.folders.filter(
        item => item.id === this.Folder._id
      )
      if (f.length > 0) return f[0].children
      else return []
    }
  },
  watch: {
    $route() {
      if (this.$apollo.queries.Folder) {
        this.posts = []
      }
    }
  }
}
