<template>
  <div :class="'post-signature ' + size">
    <slot name="3dots" />

    <publisher-logo
      :publisher="post.publisher"
      :size="size === 'default' ? 'large' : size"
    />
    <!-- v-if="publisher"-->
    <div class="post-signature__info">
      <div class="post-signature__wrapper">
        <publisher-name-folder
          :post="post"
          :hide-folder="hideFolder"
          :size="size"
        />

        <!-- Date & Creator -->
        <div class="post-signature__info--date">
          <!--Format based on the formatDateWithRelativeTemporal method exposed by the moment mixin-->
          {{ formatDateWithRelativeTemporal(this.post.published_at) }}
          <span v-if="post.read_time"
            >&nbsp;- {{ Math.ceil(post.read_time) }} min&nbsp;</span
          >
          <!--Show the creator (contributor or bot) -->
          <span v-if="post.authorship.length === 1">
            <template v-if="post.authorship.length">
              <template v-if="size !== 'sm'">&nbsp;-</template> par&nbsp;
              <avatar-name :avatar-name-list="post.authorship" />
            </template>
          </span>
          <!--Show more contributors if there is more than one author-->
          <span v-else-if="post.authorship.length > 1"
            >&nbsp;- par {{ post.authorship.length }} auteurs</span
          >
          <!--display post free tags.-->
          <tag-list v-if="!hideTags" :tags="free_tags" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MomentSupportMixin from "@/components/Mixins/Emb_MomentSupportMixin"
import AvatarName from "@/components/Author/Emb_AvatarName"
import PublisherNameFolder from "@/components/Author/Emb_PublisherNameFolder"
import TagList from "@/components/Tags/Emb_TagList"
import PublisherLogo from "@/components/Author/Emb_PublisherLogo"

/**
 * Post item meta data such as author details and
 * space details and other post's information.
 */
export default {
  name: "PublisherWithPostDetail",
  mixins: [MomentSupportMixin],
  components: {
    AvatarName,
    PublisherNameFolder,
    PublisherLogo,
    TagList
  },
  props: {
    /**
     * The current post where the signature is displayed
     * */
    post: {},
    /**
     * Size for signature
     * */
    size: {
      type: String,
      default: "default",
      required: false
    },
    /**
     * Option to hide folder
     * */
    hideFolder: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Option to hide tags
     * */
    hideTags: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    /**
     * Get post free tags (tag that doesn't have a filter.)
     * */
    free_tags() {
      if (this.post.tags)
        return this.post.tags.filter(item => !item.installedFilter)

      return []
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/sass/_variables";
#app_relatt_embed {
  .post-signature {
    margin-bottom: 10px;
    display: flex;
    /**
     * 3-dots - if any
     * */
    .options-dropdown-menu {
      float: right;
      margin: 5px 13px;
    }

    .space-logo {
      width: 27.5px;
      height: 27.5px;
      margin-right: 5px;
    }

    .post-signature__info {
      font-size: 14px;
      color: $gray-600;
      padding: 4px 0 0 0px;
      margin-left: 7px;

      .post-signature__wrapper {
        display: inline;

        .post-signature__info--date {
          line-height: 30px;
          display: flex;
          margin-right: 5px;

          span {
            display: flex;
          }
        }
      }
    }

    /** ----------
     * Sizes variants
     * */

    // &.small {
    //   .post-signature__info--second-line {
    //     display: inline-flex;
    //     line-height: 1;
    //     &:before {
    //       content: ' - ';
    //       margin: 0 4px 0 0;
    //     }
    //   }
    //
    //   .post-signature__info {
    //     .TagList__tags {
    //       display: none;
    //     }
    //     .post-signature__wrapper {
    //       .post-signature__info--date {
    //         line-height: 1;
    //       }
    //     }
    //   }
    // }

    &.small,
    &.x-small {
      padding: 5px 0;
      display: flex;
      align-items: flex-start;

      .post-signature__info {
        padding: 0;
        color: $gray-500;
        margin-left: 7px;

        .post-signature__wrapper {
          .post-signature__info--date {
            line-height: 1;
            display: flex;
            margin-right: 5px;

            span {
              display: flex;
            }
          }
        }

        .TagList__tags {
          display: none;
        }

        .post-signature__info--second-line {
          line-height: 1;
          font-size: 12px;

          &::before {
            content: "\00a0-\00a0";
          }
          span {
            display: none;
          }
        }
      }
    }
  }

  /**
  * RESPONSIVE
  * ------------------------------------------
  */

  /**
  * Mobile
  * - - - - - - - - - - - - - - - - - - - - - -
  */
  .mobile {
    .post-signature {
      .post-signature__info {
        display: flex;
        align-items: center;
        padding: 0;
        margin-left: 7px;

        .post-signature__wrapper {
          .post-signature__info--date {
            line-height: 10px;
            margin-top: 2px;
            font-size: 10px;
            display: flex;
            margin-right: 5px;

            span {
              display: flex;
            }
          }
        }
      }
    }
  }
}
</style>
