<template>
  <div class="PostAttachmentList">
    <div v-if="visibleAttachments.length && showList">
      <h4 v-if="!condensed" class="PostAttachmentList--Title">
        {{ visibleAttachments.length > 1 ? "Pièces jointes" : "Pièce jointe" }}
      </h4>
      <link-preview
        v-for="attachment in visibleAttachments"
        :key="attachment.ttuid"
        :preview="attachment.preview"
        :imageLeft="true"
        :isGrid="isGrid"
        :isAttachment="true"
        size="small"
      />
    </div>
  </div>
</template>

<script>
import LinkPreview from "@/components/Other/Emb_LinkPreview"
export default {
  name: "PostAttachmentList",
  components: {
    LinkPreview
  },
  props: {
    attachments: {
      type: Array
    },

    /**
     * Publisher space from which we'll retrieve space visibility and slug
     * needed by image responsive component
     */
    currentSpace: {
      type: Object
    },

    condensed: {
      type: Boolean,
      default: false
    },

    showFiles: {
      type: Boolean,
      default: true
    },
    showImages: {
      type: Boolean,
      default: true
    },
    hiddenList: {
      type: Boolean,
      default: false
    },
    isGrid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showList: !this.hiddenList
    }
  },
  computed: {
    visibleAttachments() {
      return this.attachments.filter(attachment => {
        if (this.showFiles && attachment.type == "FILE") return true
        if (this.showImages && attachment.type == "IMAGE") return true
        return false
      })
    }
  },
  methods: {
    onToggleButtonClick() {
      this.showList = !this.showList
    }
  }
}
</script>
<style lang="scss">
.PostAttachmentList {
  position: relative;
}
</style>
