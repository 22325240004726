var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Space-Title-Container"},[_c('a',{attrs:{"href":(_vm.$relattUrl + "/" + (_vm.space.slug) + "/about"),"target":"_blank"}},[_c('div',{staticClass:"Space-Title"},[(_vm.showLogo)?_c('responsive-img',{staticClass:"Space-Title-Logo",attrs:{"src":_vm.space.logo,"alt":_vm.space.name,"entitySlugId":_vm.space.ttuid}}):_vm._e(),_c('div',{staticClass:"Space-Title-Details",class:_vm.$mq},[_c('p',{staticClass:"Space-Title-Title"},[_vm._v(_vm._s(_vm.space.name))]),(_vm.space.short_description)?_c('p',{staticClass:"Space-Title-Description",style:(_vm.spaceDescriptionStyle)},[_vm._v(" "+_vm._s(_vm.space.short_description)+" ")]):_vm._e(),_c('div',{staticClass:"Space-Tile-Additonal-Detail"},[(_vm.moreSpaceFields.includes('Site web') && _vm.additionalDetails.website)?_c('span',[_c('b',[_vm._v("Site:")]),_vm._v(" "),_c('a',{attrs:{"href":_vm.additionalDetails.website}},[_vm._v(_vm._s(_vm.additionalDetails.websitePreview))])]):_vm._e(),(
              _vm.moreSpaceFields.includes('E-mail') && _vm.additionalDetails.email
            )?_c('span',[_c('b',[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.additionalDetails.email)+" ")]):_vm._e(),(
              _vm.moreSpaceFields.includes('E-mail') && _vm.additionalDetails.email
            )?_c('span',[_c('b',[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.additionalDetails.email)+" ")]):_vm._e(),(
              _vm.moreSpaceFields.includes('Téléphone') &&
                _vm.additionalDetails.phone_number
            )?_c('span',[_c('b',[_vm._v("Téléphone:")]),_vm._v(" "+_vm._s(_vm.additionalDetails.phone_number)+" ")]):_vm._e(),(
              _vm.moreSpaceFields.includes('Adresse') && _vm.additionalDetails.address
            )?_c('span',[_c('b',[_vm._v("Adresse:")]),_vm._v(" "+_vm._s(_vm.additionalDetails.address)+" ")]):_vm._e(),(
              _vm.moreSpaceFields.includes('Horaires') &&
                _vm.additionalDetails.schedule
            )?_c('span',[_c('b',[_vm._v("Horaires:")]),_vm._v(" "+_vm._s(_vm.additionalDetails.schedule)+" ")]):_vm._e()])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }