<template>
  <div class="SpaceAvatar" :class="size">
    <responsive-img
      :src="src"
      :alt="alt"
      :entitySlugId="entitySlugId"
      :class="responsive_class"
      :visibility="visibilitySpace"
      class="logo"
    />
    <div v-if="visibilitySpace != 'PUBLIC'" class="icon_container">
      <i class="icon-lock" />
    </div>
  </div>
</template>

<script>
import ResponsiveImg from "@/components/Img/Emb_ResponsiveImg"
/**
 * Generic component that render author(s) from a post
 */
export default {
  name: "SpaceAvatar",
  components: {
    ResponsiveImg,
  },
  props: {
    src: {},
    alt: {},
    entitySlugId: {},
    visibilitySpace: {
      type: String,
      default: "PUBLIC",
    },
    responsive_class: { },
    size: {
      type: String,
      default: "md",
    },
  },
}
</script>
<style lang="scss">
@import "@/assets/sass/_variables";
#app_relatt_embed {
  .SpaceAvatar {
    display: flex;
    position: relative;

    // Global properties for Icon Container
    .icon_container {
      position: absolute;
      background-color: #e0e1e6;
      border-radius: 50%;
      text-align: center;
      border: 2px solid white;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    // Global properties for Icon
    .icon-lock {
      position: relative;
      color: $gray-700;
      transition: all 0.5s ease;
    }

    &.md {
      .icon_container {
        bottom: 3px;
        right: 3px;
        width: 23px;
        height: 23px;
        padding: 0 0 1px 0;
        .icon-lock {
          font-size: 15px;
        }
      }
    }

    &.xs {
      .icon_container {
        bottom: 6px;
        right: 6px;
        width: 22px;
        height: 22px;
        padding: 0 0 1px 0;
        .icon-lock {
          font-size: 15px;
        }
      }
    }

    &.sm {
      .icon_container {
        bottom: 3px;
        right: 3px;
        width: 13px;
        height: 13px;
        .icon-lock {
          font-size: 10px;
        }
      }
    }

    .logo {
      background-color: white;
    }
  }

  /**
 * RESPONSIVE
 * ---------------------------------------------------------------------------
 */

  /**
  * Mobile
  * - - - - - - - - - - - - - - - - - - - - - - - - - - */
  .mobile {
    .SpaceAvatar {
      &.md {
        .icon_container {
          width: 15px;
          height: 15px;
          .icon-lock {
            font-size: 10px;
          }
        }
      }
    }
  }
}
</style>
