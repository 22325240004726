import { urlCleaner } from "@/helpers/app"
import getMarkersFromPosts from "../../../../shared/helpers/getMarkersFromPosts"
import unFlatPostTags from "@/helpers/post/unFlatPostTags"
import turnAttachmentToPreview from "@/helpers/post/turnAttachmentToPreview"

const processPost = (postDoor) => {
  let {
    ttuid,
    title,
    slug,
    image,
    image_background,
    excerpt,
    body,
    tags,
    published_at,
    created_at,
    read_time,
    likesCount,
    favoritesCount,
    commentsCount,
    relatesCount,
    installedContentType,
    folder,
    customFieldContents,
    customFieldContentsCount,
    isAlsoRelatedByCount,
    hasCurrentUserFavorite,
    hasCurrentUserLiked,
    hasCurrentUserReported,
    status,
  } = postDoor

  let attachments = postDoor.attachments
  if (postDoor.sourceOriginal && postDoor.sourceOriginal.attachments.length) {
    attachments = postDoor.sourceOriginal.attachments
  }

  let tileData = {
    ttuid,
    title,
    slug,
    image,
    image_background,
    excerpt,
    body,
    tags,
    published_at,
    created_at,
    read_time,
    likesCount,
    favoritesCount,
    commentsCount,
    relatesCount,
    installedContentType: { ...installedContentType },
    folder,
    isAlsoRelatedByCount,
    isAugmentedPost: false,
    isAugmentationNeeded: false,
    isDisabled: status == "DISABLED",
    hasCurrentUserFavorite,
    hasCurrentUserLiked,
    hasCurrentUserReported,
    originalFolder: {},
    customFieldContents: [],
    customFieldContentsCount: 0,
    customFieldContentsKeyValue: {},
    augmentedCustomFieldContents: [],
    augmentedCustomFieldContentsCount: 0,
    hasUnFetchedNonFeaturedAugmentedCustomFields: false,
    hasUnFetchedNonFeaturedCustomFields: false,
    augmentedCustomFieldContentsKeyValue: {},
    contributorship: [],
    authorship: [],
    attachments,
    attachmentsCount: attachments.length,
    visibleAttachmentsCount: attachments.filter((item) => !item.deleted).length,
  }

  /**
   * Retrieve originalFolder & Slug
   * */
  if (!(postDoor.sourceOriginal ? postDoor.sourceOriginal.folder : folder))
    return null

  tileData.originalFolder = postDoor.sourceOriginal
    ? postDoor.sourceOriginal.folder
    : folder

  tileData.slug = postDoor.sourceOriginal ? postDoor.sourceOriginal.slug : slug
  tileData.doorPostSlug = slug

  /**
   * Check if it's a related post
   * */
  tileData.isRelatedPost = !!(
    postDoor.sourceOriginal ||
    postDoor.sourceSpace ||
    postDoor.sourceFolder ||
    postDoor.sourceUser
  )
  tileData.isRelatedNode = !!(
    postDoor.sourceSpace ||
    postDoor.sourceFolder ||
    postDoor.sourceUser
  )

  /**
   * Tell the nature of the content
   */
  let contentTypeKey = installedContentType
    ? installedContentType.content_type_key
    : null

  let isLink = !!postDoor.sourceLink

  tileData.isLink = isLink

  if (postDoor.sourceOriginal) attachments = postDoor.sourceOriginal.attachments

  let hasFileAttachments =
    contentTypeKey === "FILE" || (attachments && !!attachments.length)

  tileData.isFile = hasFileAttachments

  let isFile = contentTypeKey === "FILE"
  let hasLinkContentType = contentTypeKey === "LINK"
  let isPublication = contentTypeKey === "PUBLICATION"
  let isAddress = contentTypeKey === "ADDRESS"

  /**
   * A post is said to be augmented when it meets the following requirements:
   * - Has no sourceAugmentation
   * - Is not of a neutral content type like (file, publication or link)
   */
  tileData.isAugmentedPost = !(
    isFile ||
    isPublication ||
    hasLinkContentType ||
    tileData.isRelatedPost
  )

  /**
   * Check if post has augmented image
   */
  tileData.hasAugmentedImage = image != "" && (tileData.isRelatedPost || isLink)
  /** ----------------
   * 1) Contributorship
   * */
  if (
    !tileData.isRelatedPost &&
    tileData.originalFolder.space.slug != postDoor.publisher.slug
  ) {
    /**
     * When a space (the publisher) post in another space
     * Or when no bot and no authors
     * */
    publisher = postDoor.publisher
    tileData.contributorship.push({
      type: "SPACE",
      name: publisher.name,
      slug: publisher.slug,
      route_var_name: "slug_space",
      route_name: "slug_space",
      image: publisher.logo ? publisher.logo : "/static/svg/avatar.svg",
      image_class: "space",
      ttuid: publisher.ttuid,
      entitySlugId: publisher.ttuid,
      image_background: publisher.logo_background,
    })
  } else if (postDoor.createdFromBot) {
    /**
     * Bot
     * */

    let bot = postDoor.createdFromBot

    tileData.contributorship.push({
      type: bot.type, // RELATT | RSS
      name: bot.type === "RSS" ? bot.hostname : bot.name,
      slug: bot.type === "RSS" ? `${bot.display_url}` : "",
      route_var_name: "slug_bot",
      route_name: "bot",
      image: bot.avatar ? bot.avatar : "static/svg/domain.svg",
      image_class: "bot",
      entitySlugId: bot.type === "RSS" ? "websites" : "bots",
    })
  } else if (postDoor.contributors.length) {
    /**
     * Contributors
     * */
    postDoor.contributors.forEach((contributor) => {
      tileData.contributorship.push({
        type: "USER",
        name: contributor.first_name + " " + contributor.last_name,
        slug: contributor.slug,
        route_var_name: "slug_person",
        route_name: "person-slug_person",
        image: contributor.avatar
          ? contributor.avatar
          : "/static/svg/avatar.svg",
        image_class: "person",
        entitySlugId: contributor.slug,
        image_background: contributor.avatar_background,
      })
    })
  }

  /**
   * authorship's processing
   */
  let postAutors =
    postDoor.sourceOriginal && postDoor.sourceOriginal.authors
      ? postDoor.sourceOriginal.authors
      : postDoor.authors

  postAutors.forEach((author) => {
    tileData.authorship.push({
      type: "USER",
      name: author.first_name + " " + author.last_name,
      slug: author.slug,
      route_var_name: "slug_person",
      route_name: "person-slug_person",
      image: author.avatar ? author.avatar : "/static/svg/avatar.svg",
      image_class: "person",
      image_background: author.avatar_background,
    })
  })

  /** ----------------
   * 2) Post - Get right information for the `Post`
   * */

  /**
   * Related post
   * */
  if (postDoor.sourceFolder) tileData.sourceFolder = postDoor.sourceFolder
  if (postDoor.sourceSpace) tileData.sourceSpace = postDoor.sourceSpace
  if (postDoor.sourceUser) tileData.sourceUser = postDoor.sourceUser

  /**
   * Process light augmentation fields when related `posts`
   * */
  if (postDoor.sourceOriginal) {
    /**
     * Keep augmented basics fields
     * */
    tileData.augmentation = {
      title,
      slug,
      image,
      image_background,
      excerpt,
      tags,
      published_at,
    }
    tileData.sourceItem = {}
    /**
     * If sourceOriginal is available - it's a native `Post`, else a `Link`
     * Parse and get all available basics fields from source
     * */
    Object.keys(tileData.augmentation).forEach((key) => {
      /* Store SourceItem values */
      if (postDoor.sourceOriginal[key])
        tileData.sourceItem[key] = postDoor.sourceOriginal[key]
      /* Apply SourceItem values to empty DoorPost properties */
      if (tileData.augmentation[key] === "")
        tileData[key] = postDoor.sourceOriginal[key]
    })

    /**
     * Take installedContentType from sourceOriginal
     * Augmented post installed content should be the source original one
     * */
    if (
      !installedContentType ||
      hasLinkContentType ||
      postDoor.sourceAugmentation
    ) {
      tileData.installedContentType = {
        ...postDoor.sourceOriginal.installedContentType,
      }
    }
  } else {
    tileData.publisher = postDoor.publisher
  }

  /**
   * Process customs fields
   * */
  let mainCustomFieldContents = customFieldContents
  let mainCustomFieldContentsCount = customFieldContentsCount

  if (postDoor.sourceOriginal && postDoor.sourceOriginal.customFieldContents) {
    mainCustomFieldContents = postDoor.sourceOriginal.customFieldContents
    mainCustomFieldContentsCount =
      postDoor.sourceOriginal.customFieldContentsCount
  }

  tileData.customFieldContents = [...mainCustomFieldContents]
  tileData.customFieldContentsCount = mainCustomFieldContentsCount
  tileData.hasUnFetchedNonFeaturedCustomFields =
    tileData.customFieldContentsCount > tileData.customFieldContents.length

  if (mainCustomFieldContents.length)
    mainCustomFieldContents.forEach((item) => {
      tileData.customFieldContentsKeyValue[item.name] =
        item[`value_${item.type_hint}`]
    })

  if (postDoor.sourceAugmentation) {
    /**
     * In case of `real augmentation`
     * */
    let augmentedCustomFieldContents =
      postDoor.sourceAugmentation.customFieldContents
    let augmentedCustomFieldContentsCount =
      postDoor.sourceAugmentation.customFieldContentsCount

    if (
      postDoor.sourceAugmentation &&
      postDoor.sourceAugmentation.installedContentType
    ) {
      tileData.augmentedCustomFieldContents = [...augmentedCustomFieldContents]
      tileData.augmentedCustomFieldContentsCount =
        augmentedCustomFieldContentsCount
      tileData.hasUnFetchedNonFeaturedAugmentedCustomFields =
        tileData.augmentedCustomFieldContentsCount >
        tileData.augmentedCustomFieldContents.length

      if (augmentedCustomFieldContents.length)
        augmentedCustomFieldContents.forEach((item) => {
          tileData.augmentedCustomFieldContentsKeyValue[item.name] =
            item[`value_${item.type_hint}`]
        })

      tileData.installedContentType = {
        ...postDoor.sourceAugmentation.installedContentType,
      }
    }
  }

  /**
   * We perform augmentation only when the current post is:
   * - Not a related post, because we don't want to augment them
   * - Not already augmented
   * - Is of neutral content type like, file, link or publication
   */

  tileData.isAugmentationNeeded = !!(
    !tileData.isRelatedPost &&
    (hasFileAttachments || isLink || isPublication) &&
    !tileData.isAugmentedPost
  )

  /**
   * Publisher & Authors
   * */
  let { publisher, contributors, createdFromBot } = postDoor.sourceOriginal
    ? postDoor.sourceOriginal
    : postDoor

  /**
   * Address exception for image
   * */
  if (
    !tileData.image &&
    tileData.installedContentType &&
    tileData.installedContentType.content_type_key === "ADDRESS" &&
    tileData.customFieldContentsKeyValue.town &&
    tileData.customFieldContentsKeyValue.zip_code
  ) {
    let coordinates = getMarkersFromPosts([postDoor], {
      returnType: "points",
    })

    tileData.image = `https://api.mapbox.com/styles/v1/mapbox/light-v10/static/pin-s+${publisher.color.replace(
      "#",
      ""
    )}(${coordinates[0]})/${
      coordinates[0]
    },14.00,0.00,0.00/400x400@2x?access_token=${
      process.env.VUE_APP_MAP_BOX_ACCESS_TOKEN
    }`
  }

  /**
   * Address exception for excerpt
   * */
  if (isAddress && !tileData.excerpt) {
    tileData.excerpt = publisher.short_description
  }

  /**
   * Define image entitySlugId and visibility
   * */
  let entitySlugId = tileData.hasAugmentedImage
    ? tileData.folder.space.ttuid
    : tileData.originalFolder.space.ttuid

  tileData.imageEntitySlugId = entitySlugId

  let storageVisibility = tileData.hasAugmentedImage
    ? tileData.folder.space.visibility
    : tileData.originalFolder.space.visibility
  tileData.imageStorageVisibility = storageVisibility
  /**
   * Prepare previews for:
   * Links, Folder, Space, User or Files
   * */

  tileData.preview = null
  let previewImage = ""
  let previewTitle = ""
  let hasAugmentedTitle = true

  if (
    isLink ||
    hasFileAttachments ||
    postDoor.sourceFolder ||
    postDoor.sourceSpace ||
    postDoor.sourceUser
  ) {
    /**
     * Init preview
     * */
    let icon = ""
    let link = ""
    let displayLink = ""
    let color = "#f4f4f4"
    previewTitle = tileData.sourceItem ? tileData.sourceItem.title : title
    previewImage = tileData.sourceItem?.image
      ? tileData.sourceItem.image
      : image
    let previewImageBackground = tileData.sourceItem?.image_background
      ? tileData.sourceItem.image_background
      : image_background

    let image_cached_ext = ""
    let image_fit_contain = ""
    let description = postDoor.sourceItem
      ? postDoor.sourceItem.excerpt
      : excerpt

    /**
     * Links exception
     * */
    if (isLink) {
      tileData.fromWebsite = postDoor.sourceLink.fromWebsite

      icon = tileData.fromWebsite.favicon ?? "icon-globe"
      link = tileData.fromWebsite.hostname + postDoor.sourceLink.pathName.value
      displayLink = urlCleaner(`${link}`)
      previewTitle = postDoor.sourceLink.title
      description = postDoor.sourceLink.excerpt
      /**
       * Image
       */
      let shouldUseCachedImage =
        !previewImage && postDoor.sourceLink.image_cached_ext
      image_cached_ext = shouldUseCachedImage
        ? postDoor.sourceLink.image_cached_ext
        : ""

      image_fit_contain = shouldUseCachedImage
        ? postDoor.sourceLink.image_fit_contain
        : ""
      let previewImageFistTest = shouldUseCachedImage
        ? `${postDoor.sourceLink.ttuid}.${postDoor.sourceLink.image_cached_ext}`
        : postDoor.sourceLink.image

      previewImageBackground =
        previewImageBackground || postDoor.sourceLink.image_background
      previewImage = previewImage || previewImageFistTest

      entitySlugId = shouldUseCachedImage ? "links" : entitySlugId
      storageVisibility = shouldUseCachedImage ? "PUBLIC" : storageVisibility

      tileData.image = previewImage

      if (tileData.isAugmentationNeeded) {
        let linkContentTypeData = getLinkContentTypeData(
          tileData.fromWebsite.hostname
        )

        if (linkContentTypeData) {
          tileData.installedContentType.icon = linkContentTypeData.icon
          tileData.installedContentType.color = linkContentTypeData.color
          tileData.installedContentType.title = linkContentTypeData.title
        }
      }
    }

    /**
     * We use featured attachment image when there is no user defined image.
     */
    if (!previewImage && attachments.length > 0) {
      let attachment = attachments[0]
      previewImage = attachment.preview_path
    }
    /**
     * Replace description of space with excerpt if there is one
     */
    if (postDoor.sourceSpace) {
      tileData.excerpt = tileData.excerpt
        ? tileData.excerpt
        : publisher.short_description || ""
    }

    /**
     * Give to previewTitle the title value if empty
     * to fix LinkPreview in case of external PDF
     * */
    if (!previewTitle.trim()) previewTitle = tileData.title

    /**
     * Replace all line break Check if there's an augmented title (to display design variant)
     * */
    if (isLink) {
      hasAugmentedTitle = tileData.title !== previewTitle
    }

    /**
     * Check if there's an augmented title (to display design variant)
     * */
    if (isLink || !hasFileAttachments) {
      tileData.preview = {
        icon,
        link,
        display_link: displayLink,
        title: previewTitle,
        description,
        image: previewImage,
        entitySlugId,
        visibility: storageVisibility,
        image_background: previewImageBackground,
        color,
        hasAugmentedTitle,
        hasFileAttachments,
        image_cached_ext,
        image_fit_contain,
        installedContentType: tileData.installedContentType,
        isPDf: link.toLocaleLowerCase().split(".").pop() == "pdf",
      }
    }
  }

  tileData = { publisher, contributors, createdFromBot, ...tileData }

  /**
   * Process tags
   * */
  let { free_tags, filters_tags } = unFlatPostTags(tags)
  tileData.filters_tags = filters_tags
  tileData.free_tags = free_tags

  tileData.attachments = tileData.attachments.map((attachment) => {
    return {
      deleted: false,
      ...attachment,
      preview: {
        ...turnAttachmentToPreview(attachment, tileData, hasAugmentedTitle),
      },
    }
  })

  if (tileData.attachmentsCount >= 1 && !isLink) {
    const firstAvailableAttachment = tileData.attachments.find(
      (item) => !item.deleted
    )

    tileData.preview = firstAvailableAttachment
      ? firstAvailableAttachment.preview
      : null

    if (firstAvailableAttachment) {
      tileData.preview.hasAugmentedTitle =
        tileData.title != firstAvailableAttachment.name
    }
  }

  /*tileData.attachments = tileData.attachments.filter((attachment) => {
    if (tileData.attachmentsCount > 1) return true
    return attachment.preview_path != previewImage
  })*/

  postDoor.tileData = { ...tileData }

  return { ...tileData }
}

function getLinkContentTypeData(hostname) {
  if (hostname == "https://youtu.be") {
    return { icon: "youtube", color: "#FF0000", title: "Lien youtube" }
  }

  if (hostname == "https://twitter.com") {
    return { icon: "twitter", color: "#1DA1F2", title: "Lien twitter" }
  }

  if (hostname == "https://web.facebook.com") {
    return { icon: "facebook", color: "#4267B2", title: "Lien facebook" }
  }

  if (hostname == "https://instagram.com") {
    return { icon: "instagram", color: "#833AB4", title: "Lien instagram" }
  }

  if (hostname == "https://linkedin.com") {
    return { icon: "linkedin", color: "#2867b2", title: "Lien linkedin" }
  }

  if (hostname == "https://dailymotion.com") {
    return { icon: "youtube", color: "#0D0D0D", title: "Lien dailymotion" }
  }

  return null
}

export default processPost
