import { describeAcceptedFile } from "../../../../shared/helpers/acceptedFileDecriptor"

export default function turnAttachmentToPreview(
  attachment,
  tileData,
  hasAugmentedTitle = false
) {
  let attachmentPath = attachment.path ? attachment.path : ""
  if (!attachmentPath && attachment.input_file) {
    attachmentPath = attachment.input_file.name
  }

  let icon = "icon-" + describeAcceptedFile(attachmentPath).icon
  let displayLink = describeAcceptedFile(attachmentPath).description

  let previewTitle = attachmentPath
  previewTitle = previewTitle.slice(
    previewTitle.lastIndexOf("/") + 1,
    previewTitle.length
  )

  const contentType = tileData.installedContentType
    ? tileData.installedContentType
    : tileData.contentType

  let previewImage = attachment.image
    ? attachment.image
    : attachment.preview_path

  let link = "/" + attachmentPath
  let image_fit_contain = "Contain"
  let previewImageBackground = "dark"
  let description = ""
  let color = contentType.color
  let entitySlugId = ""

  if (attachment.ttuid) {
    entitySlugId = tileData.hasAugmentedImage
      ? tileData.folder.space.ttuid
      : tileData.originalFolder.space.ttuid
  } else {
    entitySlugId = tileData.folder.space.ttuid
  }

  return {
    id: attachment.ttuid ? attachment.ttuid : null,
    ttuid: attachment.ttuid ? attachment.ttuid : null,
    icon,
    link,
    color,
    entitySlugId,
    display_link: displayLink,
    title: previewTitle,
    description,
    image: previewImage,
    visibility: tileData.imageStorageVisibility,
    image_background: previewImageBackground,
    hasAugmentedTitle,
    hasFileAttachments: true,
    image_cached_ext: null,
    image_fit_contain,
    installedContentType: contentType,
    attachmentHasMeta: true,
    attachmentMeta: {
      name: attachment.name,
      size: attachment.size,
      pages_count: attachment.pages_count,
    },
    isPDf: link.toLocaleLowerCase().split(".").pop() == "pdf",
  }
}
