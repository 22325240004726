<template>
  <div class="article">
    <!-- Loading -->
    <div v-if="folder_data_loading">
      <loader class="m-auto mt-5"></loader>
    </div>
    <div v-if="!folder_data_loading" id="space_folder">
      <div>
        <div class="content">
          <div>
            <div md="12" class="mt-0">
              <div v-if="allContents">
                <div id="folder_header" class="flex-row flex-space-between">
                  <!-- Title & bread crumb -->
                  <div class="space-folder__title">
                    <div>
                      <h1>{{ Folder ? Folder.name : "" }}</h1>
                    </div>
                  </div>
                  <!-- Filters -->
                  <div
                    class="folder_filters_container d-flex align-items-center mt-2 mt-md-5"
                    :class="$mq"
                    id="filter_bloc"
                  >
                    <!-- <span class="search_wrapper">
                    <i class="icon-loupe"></i>
                  </span>-->

                    <!-- Custom filters > dropdown -->
                    <v-select
                      class="v-select--folder-filter mr-2 mb-1"
                      v-for="customFilter in customFilters"
                      :key="customFilter.ttuid"
                      :class="{
                        active:
                          selectedTags[customFilter.ttuid] &&
                          selectedTags[customFilter.ttuid].length
                      }"
                      v-model="selectedTags[customFilter.ttuid]"
                      :options="customFilter.filterVersion.tags"
                      label="name"
                      :inputId="'_' + customFilter.ttuid"
                      :clearable="true"
                      :multiple="true"
                      :placeholder="customFilter.title"
                    >
                      <template name="search" #search="{attributes, events}">
                        <input
                          class="vs__search"
                          v-bind="{
                            ...attributes,
                            placeholder: customFilter.title
                          }"
                          v-on="events"
                        />
                      </template>
                    </v-select>

                    <!-- Related spaces > dropdown -->
                    <v-select
                      class="v-select--folder-filter mr-2"
                      v-model="relatedSpace"
                      label="label"
                      :clearable="false"
                      placeholder="Espace associé"
                      v-if="false"
                    ></v-select>

                    <!-- Number of items -->
                    <p class="contents-number" :class="$mq">
                      {{
                        isNetwork
                          ? `${countPost} espaces`
                          : `${countPost} contenus`
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <!--
              -- FEATURED POSTS
            ---------------------------------->

              <div
                v-if="
                  currentFolderFeaturedPostsTtuid.length > 0 &&
                    flatingSelectedTag.length === 0 &&
                    this.contentType.content_type_key === 'ALL'
                "
                class="folder__featured-posts"
              >
                <div class="mt-7 pb-7"></div>
              </div>

              <!--
              -- POSTS > RESULT LIST
            ------------------------------------>

              <!-- Header with filters
            -------->
              <div class="folder_filters">
                <div md="9">
                  <!-- Selected tags + Sort options -->
                  <folder-selected-tags
                    :selectedTags="flatingSelectedTag"
                    :cancelFilteringTag="cancelFilteringTag"
                  ></folder-selected-tags>
                </div>

                <!-- RELATER button -->
                <div md="3" class="text-right"></div>
              </div>

              <div v-if="Folder.options__displayed_content_type === 'all'">
                <div>
                  <!-- Quick post form -->
                </div>
              </div>

              <!-- Content area
            -------->
              <div>
                <div>
                  <!-- Post list -->

                  <post-list
                    :posts="!featuredPost ? posts : getFolderFeaturedPosts"
                    :showIsAlsoRelatedBy="true"
                    :showNotification="true"
                    :currentFolderTtuid="Folder.ttuid"
                    :currentFolderFeaturedPostsTtuid="folderFeaturedPostsTtuid"
                    :homeFeaturedPostsTtuid="getHomeFeaturedPostsTtuid"
                    :loading="posts_data_loading"
                    :endReached="isInfiniteEndReached"
                    :isGrid="
                      folderView === 'GRID' || folderView === 'GRID_COMPRESSED'
                        ? true
                        : false
                    "
                    :folder-view="folderView"
                    @onEndReach="getPostsFromFolder_fetchMore"
                    @postListNeedToRefresh="refetchingPostsFromFolderQuery"
                    :space-component="spaceComponent"
                    :moreSpaceFields="moreSpaceFields"
                    :is-network="isNetwork"
                    :all-contents="allContents && paginate"
                  ></post-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FolderBaseMixin } from "@/components/Mixins/Emb_FolderBaseMixin"
import GET_SPACE_CONTENT_TYPES from "@/graphql/Space/Emb_getSpaceInstalledContentTypes.graphql"
import PostList from "@/components/Post/Emb_PostList"
import Loader from "@/components/Other/Emb_Loader"
import FolderSelectedTags from "@/components/Folder/Emb_FolderSelectedTags"

export default {
  name: "Folder",
  mixins: [FolderBaseMixin],
  components: {
    PostList,
    Loader,
    FolderSelectedTags
  },
  props: {
    folderSlug: {
      type: String
    },
    folderTtuid: {
      type: String
    },
    spaceSlug: {
      type: String
    },
    spaceTtuid: {
      type: String
    },
    featuredPost: {
      type: Boolean,
      default: null
    },
    isNetwork: {
      type: Boolean,
      default: false
    },
    moreSpaceFields: {
      type: Array
    },
    spaceComponent: {
      type: String,
      default: "CARD"
    },
    nbContents: {
      type: Number,
      default: 10
    },
    allContents: {
      type: Boolean,
      default: true
    },
    folderView: {
      type: String,
      default: "LIST"
    },
    paginate: {
      type: Boolean,
      default: true
    },
    contentTypeFilters: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      /*
       * Store current folder's Featured posts
       * */
      getFolderFeaturedPosts: [],
      folderFeaturedPostsTtuid: [],
      featured_posts_loading: 0,

      /*
       * Store current folder's Featured posts
       * */
      getHomeFeaturedPostsTtuid: [],
      home_featured_posts_loading: 0
    }
  },

  methods: {
    getPostsFromFolder_fetchMore() {
      this.pageToFetch++
      /*
       * Fetch more data and transform the original result
       * */
      this.$apollo.queries.getPostsFromFolder.fetchMore({
        /*
         * New variables
         * */
        variables: {
          input: {
            spaceSlug:  this.FolderSpace.slug,
            folderSlug: this.Folder.slug,
            contentType: this.queryContentType,
            offset: this.pageSize * (this.pageToFetch - 1),
            first: this.pageSize,
            orderBy: this.sortBy,
            tags: this.flatingSelectedTagForApolloQuery
          },
          onPageTime: this.onPageTime
        },

        /*
         * Transform the previous result with new data
         * */
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newPosts = fetchMoreResult.getPostsFromFolderWithFilters.posts
          this.posts = [...this.posts, ...newPosts]

          /*
           * Tell user the end of the content list had been reached
           * */
          this.isInfiniteEndReached =
            newPosts.length === 0 || newPosts.length < 10
        }
      })
    }
  },

  apollo: {
    /*
     * Get current space installed content types
     * */
    ContentTypes: {
      query: GET_SPACE_CONTENT_TYPES,
      variables() {
        return {
          slug: this.spaceSlug,
          ttuid: this.spaceTtuid
        }
      },
      update(data) {
        if (data.GetSpaceBy[0]) {
          this.installedContentTypes = data.GetSpaceBy[0].installedContentTypes
          this.FolderSpace = data.GetSpaceBy[0]
          return this.installedContentTypes
        } else {
          return []
        }
      }
    }
  }
}
</script>

<style lang="scss">
#app_relatt_embed {
  #folder_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .article {
    padding: 0em;
  }

  button,
  input[type="button"],
  input[type="submit"] {
    padding: 0px;
  }
}
</style>
