<template>
  <div class="CustomFieldContentsList">
    <dl class="CustomFieldContentsList--list">
      <custom-field-content-address
        v-if="addressCustomFieldsKeyValue"
        :fieldsKeyValue="addressCustomFieldsKeyValue"
        :displayAddressTitle="displayAddressTitle"
        :addressInOneLine="addressInOneLine"
      />
      <custom-field-content-item
        v-for="(customFieldContent, key) in orthersCustomFields"
        :key="key"
        :customFieldContent="customFieldContent"
        :displayEmptyFields="displayEmptyFields"
      />
    </dl>
  </div>
</template>

<script>
import MomentSupportMixin from "@/components/Mixins/Emb_MomentSupportMixin"
import CustomFieldContentItem from "@/components/CustomFieldContent/Emb_CustomFieldContentItem"
import CustomFieldContentAddress from "@/components/CustomFieldContent/Emb_CustomFieldContentAddress"

export default {
  name: "CustomFieldContentList",
  components: { CustomFieldContentAddress, CustomFieldContentItem },
  mixins: [MomentSupportMixin],
  props: {
    /* *
     * Raw data from API
     */
    customFieldContents: {
      type: Array,
      required: true
    },
    /* *
     * Should display empty content
     */
    displayEmptyFields: {
      type: Boolean,
      required: false,
      default: true
    },
    /* *
     * Should display address title
     */
    displayAddressTitle: {
      type: Boolean,
      required: false,
      default: true
    },
    /* *
     * Should display address in one single line
     */
    addressInOneLine: {
      type: Boolean,
      required: false,
      default: false
    },
    displaySpacialContent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    orthersCustomFields() {
      return this.customFieldContents.filter(item => {
        return (
          !this.isAddressField(item.customFieldDefinition.field_name) &&
          item.name != "show_countdown" &&
          item.type != "spatial"
        )
      })
    },
    addressCustomFieldsKeyValue() {
      let tabFields = {}
      this.customFieldContents.map(item => {
        if (this.isAddressField(item.name)) {
          tabFields[item.name] = item[`value_${item.type_hint}`]
        }
      })
      if (Object.keys(tabFields).length === 0) tabFields = null
      return tabFields
    },
    spatialCustomField() {
      if (!this.displaySpacialContent) return null
      let cfc = this.customFieldContents.find(
        item => item.type_hint == "spatial"
      )
      return cfc ? [cfc.value_spatial.y, cfc.value_spatial.x] : null
    }
  },
  methods: {
    isAddressField(machineName) {
      return !!(
        machineName === "address" ||
        machineName === "address_additional" ||
        machineName === "address2" ||
        machineName === "zip_code" ||
        machineName === "town" ||
        machineName === "country" ||
        machineName === "postal_houses" ||
        machineName === "case_postale"
      )
    },
    showItinerary() {
      let success = position => {
        let latitude = position.coords.latitude
        let longitude = position.coords.longitude

        window.open(
          `https://www.google.com/maps/dir/${latitude},${longitude}/${this.spatialCustomField[1]},${this.spatialCustomField[0]}`,
          "_blank"
        )
      }

      let error = err => {
        console.log(error)
      }

      navigator.geolocation.getCurrentPosition(success, error)
    }
  },
  data() {
    return {
      accessToken: process.env.VUE_APP_MAP_BOX_ACCESS_TOKEN,
      mapStyle: process.env.VUE_APP_MAP_BOX_CUSTOM_STYLES
    }
  }
}
</script>
<style lang="scss">
@import "@/assets/sass/_variables";
.CustomFieldContentsList {
  .CustomFieldContentsList--list {
    font-size: 1.6rem;
    line-height: 2rem;
    display: flex;
    flex-wrap: wrap;
    .CustomFieldItem,
    .CustomFieldsContentsAddress {
      dt {
        font-weight: 500;
        font-size: 2rem;
        padding-right: 0;
        margin: 0 0 0.7rem 0;
      }
      dd {
        margin-bottom: 2rem;
      }
      dd.has-textarea {
        white-space: pre;
      }
    }
  }
}

.CustomFieldContentsMap {
  height: 50rem;
  margin-bottom: 5rem;
}
/*
* PostPage variant
* */
.PostPageDefault {
  .CustomFieldContentsList {
    .CustomFieldContentsList--list {
      padding: 4rem 0;
      border-top: 1px solid $gray-300;

      .CustomFieldItem {
        width: 50%;
      }
      .CustomFieldsContentsAddress {
        width: 50%;
      }
    }
  }
}
/*
* PostTile variant
* */
.PostTile {
  .CustomFieldContentsList {
    .CustomFieldContentsList--list {
      .CustomFieldItem {
        width: 50%;
        &.fullWidth {
          width: 100%;
        }
      }
      .CustomFieldsContentsAddress {
        width: 100%;
      }
    }
  }
}
/**
 * RESPONSIVE
 * --------------------------------------------
 */

/**
  * Mobile
  * - - - - - - - - - - - - - - - - - - - - - - - - - - */
.mobile {
  .CustomFieldContentsList--list {
  }
}
</style>
