<template>
  <div class="Space-Title-Container">
    <a :href="`${$relattUrl}/${space.slug}/about`" target="_blank">
      <div class="Space-Title">
        <responsive-img
          class="Space-Title-Logo"
          style=""
          :src="space.logo"
          :alt="space.name"
          :entitySlugId="space.ttuid"
          v-if="showLogo"
        ></responsive-img>
        <div class="Space-Title-Details" :class="$mq">
          <p class="Space-Title-Title">{{ space.name }}</p>
          <p
            v-if="space.short_description"
            class="Space-Title-Description"
            :style="spaceDescriptionStyle"
          >
            {{ space.short_description }}
          </p>
          <div class="Space-Tile-Additonal-Detail">
            <span v-if="moreSpaceFields.includes('Site web') && additionalDetails.website">
              <b>Site:</b> <a :href="additionalDetails.website">{{ additionalDetails.websitePreview }}</a>
            </span>
            <span
              v-if="
                moreSpaceFields.includes('E-mail') && additionalDetails.email
              "
            >
              <b>Email:</b> {{ additionalDetails.email }}
            </span>
            <span
              v-if="
                moreSpaceFields.includes('E-mail') && additionalDetails.email
              "
            >
              <b>Email:</b> {{ additionalDetails.email }}
            </span>
            <span
              v-if="
                moreSpaceFields.includes('Téléphone') &&
                  additionalDetails.phone_number
              "
            >
              <b>Téléphone:</b> {{ additionalDetails.phone_number }}
            </span>
            <span
              v-if="
                moreSpaceFields.includes('Adresse') && additionalDetails.address
              "
            >
              <b>Adresse:</b> {{ additionalDetails.address }}
            </span>
            <span
              v-if="
                moreSpaceFields.includes('Horaires') &&
                  additionalDetails.schedule
              "
            >
              <b>Horaires:</b> {{ additionalDetails.schedule }}
            </span>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import ResponsiveImg from "@/components/Img/Emb_ResponsiveImg"
export default {
  name: "SpaceTile",
  components: {
    ResponsiveImg
  },
  props: {
    /**
     * Current space
     */
    space: {
      type: Object,
      required: false
    },
    /**
     * Style of space title
     */
    spaceTitleStyle: {
      type: Object,
      default: () => ({}),
      required: false
    },
    /**
     * Style of space description
     */
    spaceDescriptionStyle: {
      type: Object,
      required: false,
      default: () => ({})
    },
    /**
     * show logo
     */
    showLogo: {
      type: Boolean,
      default: true
    },
    moreSpaceFields: {
      type: Array
    }
  },
  computed: {
    // Define background style for the tile
    coverContainerStyle() {
      let style = {}

      let src
      if (this.space.cover) {
        let imageName = this.space.cover.slice(
          0,
          this.space.cover.lastIndexOf(".")
        )
        src = `${process.env.VUE_APP_ASSETS_URL}/images/${this.space.ttuid}/${imageName}_cover.webp`
        style.backgroundImage = "url(" + src + ")"
      } else {
        style.backgroundColor = this.space.color
          ? this.space.color
          : "rgb(80, 227, 194, 0.5)"
      }

      return style
    },

    additionalDetails() {
      let output = {}

      if (this.space.mainAddress) {
        let customFieldContents = {}

        this.space.mainAddress.customFieldContents.forEach(ctc => {
          customFieldContents[ctc.name] = ctc
        })

        output.town = customFieldContents.town ? customFieldContents.town.value_string : ""
        output.country = customFieldContents.country ? customFieldContents.country.value_string : ""
        output.address = customFieldContents.address ? customFieldContents.address.value_string : ""

        if (output.town) {
          output.address =
            (output.address ? output.address + ", " : "") + output.town
        }
        if (output.country) {
          output.address =
            (output.town ? output.address + ", " : "") + output.country
        }

        output.schedule = customFieldContents.schedule ? customFieldContents.schedule.value_string : ""
        output.email = customFieldContents.email ? customFieldContents.email.value_string : ""
        output.phone_number = customFieldContents.phone_number ? customFieldContents.phone_number.value_string : ""
        output.website = customFieldContents.website ? customFieldContents.website.value_string : ""
        output.websitePreview = output.website

        if(output.websitePreview){
          if (output.websitePreview.startsWith('https://') || output.websitePreview.startsWith('http://')){
            output.websitePreview = output.websitePreview.replace('http://', '').replace('https://', '')
          }

          if (output.websitePreview.endsWith('/')){
            output.websitePreview = output.websitePreview.slice(0, -1)
          }
        }
      }

      return output
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/_variables";
#app_relatt_embed {
  .Space-Title-Container {
    width: 100%;
    margin-bottom: 0;

    .Space-Title {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 7px;

      .Space-Title-Logo {
        margin-right: 10px;
        width: 75px;
        height: 75px;
        border: 1px solid white;
        background-color: white;
      }
      .Space-Title-Details {
        width: 80%;
        overflow: hidden;

        .Space-Title-Title {
          margin-bottom: 6px;
          padding: 0 10px;
          font-size: 20px;
          line-height: 1;
        }

        .Space-Title-Description {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 0;
          padding: 0 10px;
          overflow: hidden;
          line-height: 20px;
          max-height: 80px;
          -webkit-box-orient: vertical;
          display: block;
          display: -webkit-box;
          overflow: hidden !important;
          text-overflow: ellipsis;
          -webkit-line-clamp: 4;
        }

        .Space-Tile-Additonal-Detail {
          overflow: hidden;
          font-size: 14px;
          font-weight: 400;
          margin-left: 10px;
          span {
            padding-right: 10px;
          }
        }
      }

      .Space-Title-Details.mobile {
        width: calc(100% - 90px);
        overflow: hidden;
      }
    }
  }
}
</style>
