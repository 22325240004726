
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SpacePreview"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Space"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"slug"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"ttuid"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"color"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"logo"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"short_description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cover"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"visibility"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":113}};
    doc.loc.source = {"body":"fragment SpacePreview on Space {\n  slug\n  ttuid\n  color\n  name\n  logo\n  short_description\n  cover\n  visibility\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
