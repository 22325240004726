<template>
  <div class="CustomFieldItem CustomFieldsContentsAddress">
    <dt v-if="displayAddressTitle">
      <i class="icon-map-pin" />
      Adresse
    </dt>
    <!-- Orhers fields -->
    <dd>
      <template v-if="fieldsKeyValue.address">
        {{ fieldsKeyValue.address }}<span v-html="lineEnding"></span> </template
      ><template
        v-if="fieldsKeyValue.address_additional || fieldsKeyValue.address2"
      >
        {{ fieldsKeyValue.address_additional || fieldsKeyValue.address2
        }}<span v-html="lineEnding"></span>
      </template>
      <template
        v-if="fieldsKeyValue.postal_houses || fieldsKeyValue.case_postale"
      >
        {{ fieldsKeyValue.postal_houses || fieldsKeyValue.case_postale
        }}<span v-html="lineEnding"></span>
      </template>
      <template v-if="fieldsKeyValue.zip_code || fieldsKeyValue.town">
        {{ fieldsKeyValue.zip_code }} {{ fieldsKeyValue.town
        }}<span v-html="lineEnding"></span>
      </template>
      <template v-if="fieldsKeyValue.country">
        {{ fieldsKeyValue.country }}
      </template>
    </dd>
  </div>
</template>

<script>
export default {
  name: "CustomFieldContentAddress",
  props: {
    /**
     * Fields object to render
     * */
    fieldsKeyValue: {
      type: Object,
      required: true,
    },
    /* *
     * Should display address title
     */
    displayAddressTitle: {
      type: Boolean,
      required: false,
      default: true,
    },
    /* *
     * Should display address in one single line
     */
    addressInOneLine: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    lineEnding() {
      return this.addressInOneLine ? "," : "<br />"
    },
  },
}
</script>
<style lang="scss">
@import "@/assets/sass/_variables";
.CustomFieldsAddress {
}
</style>
