import Vue from "vue"
import App from "./Emb_App.vue"
import VueApollo from "vue-apollo"
import { ApolloClient } from "apollo-client"
import { createHttpLink } from "apollo-link-http"
import { InMemoryCache } from "apollo-cache-inmemory"
import { onError } from "apollo-link-error"
import { ApolloLink } from "apollo-link"
import vueCustomElement from "vue-custom-element"
import "document-register-element/build/document-register-element"
import "@/assets/sass/app.scss"
import VueLazyload from "vue-lazyload"
import vSelect from "vue-select"
import VueMq from "vue-mq"
import infiniteScroll from 'vue-infinite-scroll'

Vue.component("v-select", vSelect)
Vue.use(VueLazyload)
Vue.config.productionTip = false
Vue.use(VueApollo)
Vue.use(vueCustomElement)
Vue.use(VueMq, {
  breakpoints: {
    mobile: 765,
    tablet: 1024,
    tabletXXL: 1218, // This is a special breakpoint for tablet on landscape
    laptop: 1440,
    desktop: Infinity,
  },
})
Vue.use(infiniteScroll)

async function WebpIsSupported() {
  // If the browser doesn't has the method createImageBitmap, you can't display webp format
  if (!self.createImageBitmap) return false

  // Base64 representation of a white point image
  const webpData =
    "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA="

  // Retrieve the Image in Blob Format
  const blob = await fetch(webpData).then(r => r.blob())

  // If the createImageBitmap method succeeds, return true, otherwise false
  return createImageBitmap(blob).then(
    () => true,
    () => false
  )
}

let isSupportWebp = async () => {
  if (await WebpIsSupported()) {
    return true
  } else {
    return false
  }
}

Vue.prototype.$assetsUrl = `${process.env.VUE_APP_ASSETS_URL}`
Vue.prototype.$relattUrl = `${process.env.VUE_APP_RELATT_APP_URL}`
Vue.prototype.$webpIsSupported = isSupportWebp

const httpLink = createHttpLink({
  uri: `${process.env.VUE_APP_GRAPHQL_END_POINT}/graphql`
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log("graphQLErrors", graphQLErrors)
  }
  if (networkError) {
    console.log("networkError", networkError)
  }
})
const link = ApolloLink.from([errorLink, httpLink])

const cache = new InMemoryCache()

const client = new ApolloClient({
  link: link,
  cache
})

const apolloProvider = new VueApollo({
  defaultClient: client
})

App.apolloProvider = apolloProvider

Vue.customElement("relatt-embed", App)
