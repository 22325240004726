/**
 * Determine the description and icon of a file
 * @param {string} file String with the file name.
 * @return {Object}
 */
const describeAcceptedFile = file => {
  let ext = []

  ext["aac"] = { description: "Fichier audio AAC", icon: "audio.png" }
  ext["abw"] = { description: "Document AbiWord", icon: "audio.png" }
  ext["arc"] = {
    description: "Archive (contenant plusieurs fichiers)",
    icon: "file.png"
  }
  ext["avi"] = {
    description: "AVI : Audio Video Interleave",
    icon: "video.png"
  }
  ext["azw"] = {
    description: "eBook Amazon Kindle",
    icon: "file.png"
  }
  ext["bin"] = {
    description: "Donnée binaire",
    icon: "file.png"
  }
  ext["bz"] = { description: "Archive BZip", icon: "file.png" }
  ext["bz2"] = { description: "Archive BZip2", icon: "file.png" }
  ext["csh"] = { description: "Script C-Shell", icon: "file.png" }
  ext["css"] = {
    description: "Fichier Cascading Style Sheets (CSS)",
    icon: "file.png"
  }
  ext["csv"] = {
    description: "Fichier Comma-separated values (CSV)",
    icon: "file.png"
  }
  ext["doc"] = { description: "Microsoft Word", icon: "word.png" }
  ext["docx"] = { description: "Microsoft Word (OpenXML)", icon: "word.png" }
  ext["eot"] = { description: "police MS Embedded OpenType", icon: "file.png" }
  ext["epub"] = {
    description: "Fichier Electronic publication (EPUB)",
    icon: "file.png"
  }
  ext["gif"] = {
    description: "Fichier Graphics Interchange Format (GIF)",
    icon: "file.png"
  }
  ext["htm"] = {
    description: "Fichier HyperText Markup Language (HTML)",
    icon: "file.png"
  }
  ext["html"] = {
    description: "Fichier HyperText Markup Language (HTML)",
    icon: "file.png"
  }
  ext["ico"] = { description: "Icône", icon: "file.png" }
  ext["ics"] = { description: "Elément iCalendar", icon: "file.png" }
  ext["jar"] = { description: "Archive Java (JAR)", icon: "file.png" }
  ext["jpeg"] = { description: "Image (JPEG)", icon: "file.png" }
  ext["jpg"] = { description: "Image (JPEG)", icon: "file.png" }
  ext["js"] = { description: "JavaScript (ECMAScript)", icon: "file.png" }
  ext["json"] = { description: "Donnée au format JSON", icon: "file.png" }
  ext["mid"] = {
    description: "Fichier audio Musical Instrument Digital Interface (MIDI)",
    icon: "audio.png"
  }
  ext["midi"] = {
    description: "Fichier audio Musical Instrument Digital Interface (MIDI)",
    icon: "audio.png"
  }
  ext["mpeg"] = { description: "Vidéo MPEG", icon: "video.png" }
  ext["mpkg"] = { description: "Paquet Apple Installer", icon: "file.png" }
  ext["odp"] = { description: "Présentation OpenDocument", icon: "file.png" }
  ext["ods"] = {
    description: "Feuille de calcul OpenDocument",
    icon: "excel.png"
  }
  ext["odt"] = { description: "Document texte OpenDocument", icon: "file.png" }
  ext["oga"] = { description: "Fichier audio OGG", icon: "audio.png" }
  ext["ogv"] = { description: "Fichier vidéo OGG", icon: "file.png" }
  ext["ogx"] = { description: "OGG", icon: "file.png" }
  ext["otf"] = { description: "Police OpenType", icon: "file.png" }
  ext["png"] = { description: "Image (PNG)", icon: "file.png" }
  ext["pdf"] = {
    description: "PDF",
    icon: "File.png"
  }
  ext["ppt"] = {
    description: "Présentation Microsoft PowerPoint",
    icon: "file.png"
  }
  ext["pptx"] = {
    description: "Présentation Microsoft PowerPoint (OpenXML)",
    icon: "file.png"
  }
  ext["rar"] = { description: "Archive RAR", icon: "file.png" }
  ext["rtf"] = { description: "Rich Text Format (RTF)", icon: "file.png" }
  ext["sketch"] = {
    description: "Vector graphics file created by Sketch",
    icon: "file.png"
  }
  ext["sh"] = { description: "Script shell", icon: "file.png" }
  ext["skp"] = { description: "Sketch vector file", icon: "file.png" }
  ext["svg"] = {
    description: "Fichier Scalable Vector Graphics (SVG)",
    icon: "file.png"
  }
  ext["swf"] = {
    description: "Fichier Small web format (SWF) ou Adobe Flash",
    icon: "file.png"
  }
  ext["tar"] = {
    description: "Fichier d'archive Tape Archive (TAR)",
    icon: "file.png"
  }
  ext["tif"] = {
    description: "Image au format Tagged Image File Format (TIFF)",
    icon: "file.png"
  }
  ext["tiff"] = {
    description: "Image au format Tagged Image File Format (TIFF)",
    icon: "file.png"
  }
  ext["ts"] = { description: "Fichier Typescript", icon: "file.png" }
  ext["txt"] = { description: "Fichier Text", icon: "file.png" }
  ext["ttf"] = { description: "Police TrueType", icon: "file.png" }
  ext["vsd"] = { description: "Microsoft Visio", icon: "file.png" }
  ext["wav"] = { description: "Waveform Audio Format", icon: "audio.png" }
  ext["weba"] = { description: "Fichier audio WEBM", icon: "audio.png" }
  ext["webm"] = { description: "Fichier vidéo WEBM", icon: "video.png" }
  ext["webp"] = { description: "Image WEBP", icon: "file.png" }
  ext["woff"] = {
    description: "Police Web Open Font Format (WOFF)",
    icon: "file.png"
  }
  ext["woff2"] = {
    description: "Police Web Open Font Format (WOFF)",
    icon: "file.png"
  }
  ext["xhtml"] = { description: "XHTML", icon: "file.png" }
  ext["xls"] = { description: "Microsoft Excel", icon: "excel.png" }
  ext["xlsx"] = { description: "Microsoft Excel (OpenXML)", icon: "excel.png" }
  ext["xml"] = { description: "XML", icon: "file.png" }
  ext["xul"] = { description: "XUL", icon: "file.png" }
  ext["zip"] = { description: "Archive ZIP", icon: "file.png" }
  ext["3gp"] = { description: "Conteneur audio/vidéo 3GPP", icon: "file.png" }
  ext["3g2"] = { description: "Conteneur audio/vidéo 3GPP2", icon: "file.png" }
  ext["7z"] = { description: "Archive 7-zip", icon: "file.png" }

  return ext[file.split(".").pop()]
}

const describeContentType = contentType => {
  let type = []

  type["application/pdf"] = { description: "PDF File", icon: "file.png" }
  type["application/xml"] = { description: "XML", icon: "file.png" }
  type["application/json"] = { description: "JSON File", icon: "file.png" }
  type["application/vnd.mspowerpoint"] = {
    description: "Powerpoint File",
    icon: "file.png"
  }
  type["application/xhtml+xml"] = {
    description: "XHTML File",
    icon: "file.png"
  }
  type["application/zip"] = { description: "Zip file", icon: "file.png" }
  type["application/msword"] = { description: "Doc File", icon: "file.png" }
  type["application/vnd.ms-excel"] = {
    description: "Excel File",
    icon: "file.png"
  }
  type["application/x-7z-compressed"] = {
    description: "7z File",
    icon: "file.png"
  }
  type["text/plain"] = { description: "Text file", icon: "file.png" }
  type["text/css"] = { description: "Css File", icon: "file.png" }
  type["text/javascript"] = {
    description: "Javascript file",
    icon: "file.png"
  }
  type["image/gif"] = { description: "Image GIF", icon: "file.png" }
  type["image/png"] = { description: "Image Png", icon: "file.png" }
  type["image/jpeg"] = { description: "Jpeg", icon: "file.png" }
  type["image/bmp"] = { description: "bpm", icon: "file.png" }
  type["image/webp"] = { description: "image WEBPM", icon: "file.png" }
  type["audio/midi"] = { description: "AUDIO MIDI", icon: "file.png" }
  type["audio/mpeg"] = { description: "Audio Mpeg", icon: "file.png" }
  type["audio/webm"] = { description: "Audio webm", icon: "file.png" }
  type["audio/ogg"] = { description: "Audio ogg", icon: "file.png" }
  type["audio/wav"] = { description: "Audio wav", icon: "file.png" }
  type["video/webm"] = { description: "Video Webm", icon: "file.png" }
  type["video/ogg"] = { description: "Video Ogg", icon: "file.png" }

  return type[contentType]
}

exports.describeAcceptedFile = describeAcceptedFile
exports.describeContentType = describeContentType
