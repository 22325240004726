var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article"},[(_vm.folder_data_loading)?_c('div',[_c('loader',{staticClass:"m-auto mt-5"})],1):_vm._e(),(!_vm.folder_data_loading)?_c('div',{attrs:{"id":"space_folder"}},[_c('div',[_c('div',{staticClass:"content"},[_c('div',[_c('div',{staticClass:"mt-0",attrs:{"md":"12"}},[(_vm.allContents)?_c('div',[_c('div',{staticClass:"flex-row flex-space-between",attrs:{"id":"folder_header"}},[_c('div',{staticClass:"space-folder__title"},[_c('div',[_c('h1',[_vm._v(_vm._s(_vm.Folder ? _vm.Folder.name : ""))])])]),_c('div',{staticClass:"folder_filters_container d-flex align-items-center mt-2 mt-md-5",class:_vm.$mq,attrs:{"id":"filter_bloc"}},[_vm._l((_vm.customFilters),function(customFilter){return _c('v-select',{key:customFilter.ttuid,staticClass:"v-select--folder-filter mr-2 mb-1",class:{
                      active:
                        _vm.selectedTags[customFilter.ttuid] &&
                        _vm.selectedTags[customFilter.ttuid].length
                    },attrs:{"options":customFilter.filterVersion.tags,"label":"name","inputId":'_' + customFilter.ttuid,"clearable":true,"multiple":true,"placeholder":customFilter.title},scopedSlots:_vm._u([{key:"search",fn:function(ref){
                    var attributes = ref.attributes;
                    var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',Object.assign({}, attributes,
                          {placeholder: customFilter.title}),false),events))]}}],null,true),model:{value:(_vm.selectedTags[customFilter.ttuid]),callback:function ($$v) {_vm.$set(_vm.selectedTags, customFilter.ttuid, $$v)},expression:"selectedTags[customFilter.ttuid]"}})}),(false)?_c('v-select',{staticClass:"v-select--folder-filter mr-2",attrs:{"label":"label","clearable":false,"placeholder":"Espace associé"},model:{value:(_vm.relatedSpace),callback:function ($$v) {_vm.relatedSpace=$$v},expression:"relatedSpace"}}):_vm._e(),_c('p',{staticClass:"contents-number",class:_vm.$mq},[_vm._v(" "+_vm._s(_vm.isNetwork ? (_vm.countPost + " espaces") : (_vm.countPost + " contenus"))+" ")])],2)])]):_vm._e(),(
                _vm.currentFolderFeaturedPostsTtuid.length > 0 &&
                  _vm.flatingSelectedTag.length === 0 &&
                  this.contentType.content_type_key === 'ALL'
              )?_c('div',{staticClass:"folder__featured-posts"},[_c('div',{staticClass:"mt-7 pb-7"})]):_vm._e(),_c('div',{staticClass:"folder_filters"},[_c('div',{attrs:{"md":"9"}},[_c('folder-selected-tags',{attrs:{"selectedTags":_vm.flatingSelectedTag,"cancelFilteringTag":_vm.cancelFilteringTag}})],1),_c('div',{staticClass:"text-right",attrs:{"md":"3"}})]),(_vm.Folder.options__displayed_content_type === 'all')?_c('div',[_c('div')]):_vm._e(),_c('div',[_c('div',[_c('post-list',{attrs:{"posts":!_vm.featuredPost ? _vm.posts : _vm.getFolderFeaturedPosts,"showIsAlsoRelatedBy":true,"showNotification":true,"currentFolderTtuid":_vm.Folder.ttuid,"currentFolderFeaturedPostsTtuid":_vm.folderFeaturedPostsTtuid,"homeFeaturedPostsTtuid":_vm.getHomeFeaturedPostsTtuid,"loading":_vm.posts_data_loading,"endReached":_vm.isInfiniteEndReached,"isGrid":_vm.folderView === 'GRID' || _vm.folderView === 'GRID_COMPRESSED'
                      ? true
                      : false,"folder-view":_vm.folderView,"space-component":_vm.spaceComponent,"moreSpaceFields":_vm.moreSpaceFields,"is-network":_vm.isNetwork,"all-contents":_vm.allContents && _vm.paginate},on:{"onEndReach":_vm.getPostsFromFolder_fetchMore,"postListNeedToRefresh":_vm.refetchingPostsFromFolderQuery}})],1)])])])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }