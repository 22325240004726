import moment from "moment";
require('moment/locale/fr');
/**
 * Expose moment method in components
 * */
const MomentSupportMixin = {
  methods: {
    /**
     * Helps function to access moment.js in template level.
     * @return {moment.Moment}
     */
    moment: function (date) {
      return moment(date);
    },
    /**
     * get the passed minutes
     * @param {*} date
     */
    minDiff(date) {
      moment.locale('fr')
      let now = new moment()
      let inDate = new moment(date)
      return now.diff(inDate, 'minutes') 
    },

    /**
     * Write the date depend on its value with relative temporal indication
     */
    formatDateWithRelativeTemporal(date) {
      moment.locale('fr')
      let now = new moment()
      let postDate = new moment(date)

      // Show the year if it's different
      if (postDate.year() != now.year()) {
        return postDate.format('D MMM YYYY')
      }
      // Less than 7 days -> show a "il y a X jour" or "il y a 2 heures"
      else if (now.diff(postDate, 'day') < 7) {
        return postDate.fromNow()
      }
      // For all others dates
      else {
        return postDate.format('D MMM')
      }
    },
    formatDateTimeWithRelativeTemporal(date) {
      moment.locale('fr')
      let now = new moment()
      let _date = new moment(date)
      // Show the year if it's different
      if (now.get('year') != _date.get('year')) {
        return _date.format('D MMM YYYY')
      }
      // For all others dates
      else {
        return _date.format('MMM D') + ' - ' + _date.format('HH:mm')
      }
    },

    /**
     * Write the date depend on its value
     */
    formatDate(date) {
      moment.locale("fr");
      let now = new moment();
      let postDate = new moment(date);
      // Show the year if it's different
      if (now.diff(postDate, "year") > 0) {
        return postDate.format("D MMM YYYY");
      }
      // Less than 7 days -> show a "il y a X jour" or "il y a 2 heures"
      else if (now.diff(postDate, "day") < 7) {
        return postDate.fromNow();
      }
      // For all others dates
      else {
        return postDate.format("D MMM");
      }
    }
  }
};

export default MomentSupportMixin;