<template>
  <div>
    <post-tile
      v-if="!post_data_loading && postData"
      :postTtuid="postData.ttuid"
      :post="postData"
    ></post-tile>
  </div>
</template>

<script>
import GET_POST from "@/graphql/Post/Emb_getPost.graphql"
import PostTile from "@/components/Post/Emb_PostTile"

export default {
  name: "Post",
  components: {
    PostTile
  },
  props: {
    entityId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      postData: {},
      post_data_loading: 0
    }
  },
  apollo: {
    post: {
      query: GET_POST,
      loadingKey: "post_data_loading",
      variables() {
        return {
          ttuid: this.entityId
        }
      },
      update(data) {
        this.postData = data.Post[0]
      }
    }
  }
}
</script>
